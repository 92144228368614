import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import GLOBAL_CONFIG from '../../config/config';
import appGoogleTracking from '../../modules/googletracking';  
import {timeStamp, isGlobusMicrosite, isWeltmeisterPromotion, isTicketsPromotion, isBuzzerPromotion, isTrikotPromotion, isDoppeltPromotion,isq2Promotion, isReihePromotion } from '../../modules/helpers'; 
import { connect } from "react-redux";
import Q4PopUp from "../../containers/Campaign/Weltmeister/Q4PopUp";
import TicketsPromoPopUp from "../../containers/Campaign/TicketsPromo/TicketsPromoPopUp";
import TrikotPromoPopUp from "../../containers/Campaign/Trikot/TrikotPromoPopUp";
import Q4PopUpV1 from "../../containers/Campaign/Weltmeister/Q4PopUpV1";
import PopUp from "../../containers/Campaign/Doppelt/PopUp";
import _LOCAL from "../../Models/LocalStorageKeys";
/* 
    NavLink is use as internal link for Routing : working as SPA - no page load
    Google tagging plan for NavLink : appGoogleTracking.processEventCTANavLink
*/

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      isMicroGlobus: false,
      modal: false,
    };

    this.resize = this.resize.bind(this);
    this.IsBannerMP = this.IsBannerMP.bind(this);
    this.isWeltmeisterPromotion = isWeltmeisterPromotion();
    this.isTicketsPromotion = isTicketsPromotion();
    this.isq2Promotion = isq2Promotion();
    this.isBuzzerPromotion = isBuzzerPromotion();
    this.isTrikotPromotion = isTrikotPromotion();
    this.isDoppeltPromotion = isDoppeltPromotion();
    this.modalTracking = this.modalTracking.bind(this);
    this.IsTrikotTag = this.IsTrikotTag.bind(this);
  }

  toggleModal() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  modalTracking(event) {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));

    if(this.props.isTrikotPromotion) {
      this.IsTrikotTag();
    } else {
      appGoogleTracking.processEventCTANavLink(event);
    }

  }

  resize() {
    // set isMobile to true if the device is a mobile and false otherwise
    this.setState({ isMobile: window.innerWidth <= 768 });
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    this.resize();
  }

  render() {
    const _LOCATION = window.location,
      _CAMPAING = window.PROMOTION;

      // _CAMPAING= "end"
    let { desktopImage, mobileImage, isMicroGlobus } = this.BannerRender(
        _LOCATION,
        _CAMPAING,
        this.props
      ),
      bannerButton = this.BannerButtonRender(
        _LOCATION,
        _CAMPAING,
        isMicroGlobus
      );

    let whiteBackgroundOnMobile = this.GetBackgroundColor(
      _LOCATION,
      _CAMPAING,
      this.props
    );
    let imageContainerClass = this.GetImageContainerClass(_LOCATION, _CAMPAING);
    let headingBeforeBannerRender = this.HeadingBeforeBannerRender(
      _LOCATION,
      _CAMPAING
    );
    let parentClass = isMicroGlobus ? "micro-globus" : null;

    return this.IsBannerShown(_LOCATION, _CAMPAING) ? (
      <div className={parentClass}>
        {headingBeforeBannerRender}
        <div className={whiteBackgroundOnMobile}>
          <div className={imageContainerClass}>
            <div className="image__img">
              {bannerButton}

              <picture>
                <source srcSet={desktopImage} media="(min-width: 1024px)" />
                <source srcSet={mobileImage} media="(min-width: 481px)" />
                <source srcSet={mobileImage} media="(min-width: 200px)" />
                <img
                  className="banner__image"
                  src={mobileImage}
                  alt="Milka Christmas"
                />
              </picture>
              {this.DisplayReweText(_LOCATION)}
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }

  IsTrikotTag() {
    const pathname = window.location.pathname.toLowerCase().replace(/[\\/\\\\]+$/,'');
    const baseNameTrikot = "/trikot";

      if(pathname === "" || pathname === baseNameTrikot) {
        appGoogleTracking.dataLayerPush({
          dataEvent: "trikot_begin_participation_top",
          dataCategory: "Start Page",
          dataAction: "Click on Join Now",
          dataLabel: "GAME_SP_OTHER",
        });
      }

      if(pathname === GLOBAL_CONFIG.Route.product.toLowerCase() || 
        pathname === baseNameTrikot + GLOBAL_CONFIG.Route.product.toLowerCase()) {
        appGoogleTracking.dataLayerPush({
          dataEvent: "trikot_begin_participation",
          dataCategory: "Product Page",
          dataAction: "Click on Join Now",
          dataLabel: "GAME_PROD_OTHER",
        });
      }

      if(pathname === GLOBAL_CONFIG.Route.prize.toLowerCase() || 
        pathname === baseNameTrikot + GLOBAL_CONFIG.Route.prize.toLowerCase()) {
        appGoogleTracking.dataLayerPush({
          dataEvent: "trikot_begin_participation",
          dataCategory: "Prize Page",
          dataAction: "Click on Join Now",
          dataLabel: "GAME_PRIZE_OTHER",
        });
      }
    
  }

  DisplayReweText(location) {
    const pathname = location.pathname.toLowerCase();
    if (
      pathname !== GLOBAL_CONFIG.Route.buzzer.holdingRewe.toLowerCase() &&
      pathname !== GLOBAL_CONFIG.Route.buzzer.rewe.toLowerCase() &&
      pathname !== GLOBAL_CONFIG.Route.buzzer.endRewe.toLowerCase()
    ) {
      return null;
    }
    return null;
  }

  GetImageContainerClass(location, campaignStatus) {
    const pathname = location.pathname.toLowerCase();
    let imageContainerClass = "image__container";
    if (
      pathname === GLOBAL_CONFIG.Route.terms.toLowerCase()
    ) {
      imageContainerClass += " terms";
    }
    return imageContainerClass;
  }

  /* Hide Banner on pages */
  IsBannerShown(location, campaignStatus) {
    const { isBannerShown } = this.props.bannerState;

    const pathname = location.pathname.toLowerCase();
    return (
      isBannerShown &&
      pathname !== GLOBAL_CONFIG.Route.sommer.terms.toLowerCase() &&
      pathname !== "/sommer/teilnahmebedingungen" &&
      pathname !== GLOBAL_CONFIG.Route.footballGame.toLowerCase() &&
      pathname !== "/fu%c3%9fballspiel" &&
      pathname !== GLOBAL_CONFIG.Route.participation.toLowerCase() &&
      pathname !== GLOBAL_CONFIG.Route.buzzer.participation.toLowerCase() &&
      pathname !== GLOBAL_CONFIG.Route.selfie.confirmation.toLowerCase() &&
      pathname !== GLOBAL_CONFIG.Route.faq.toLowerCase() &&
      pathname !== GLOBAL_CONFIG.Route.buzzer.game.toLowerCase() &&
      pathname !== GLOBAL_CONFIG.Route.buzzer.randomDraw.toLowerCase() &&/* 
      pathname !== GLOBAL_CONFIG.Route.buzzer.confirmation.toLowerCase() && */
      pathname !==
        GLOBAL_CONFIG.Route.globusMicroSite.confirmation.toLowerCase() &&
      pathname !==
        GLOBAL_CONFIG.Route.globusMicroSite.participation.toLowerCase() &&
      pathname !== GLOBAL_CONFIG.Route.globusMicroSite.faq.toLowerCase() &&
      pathname !== GLOBAL_CONFIG.Route.ProductCodeVerification.toLowerCase() &&
      pathname !== GLOBAL_CONFIG.Route.NoProductCode.toLowerCase() &&
      pathname !== GLOBAL_CONFIG.Route.noParticipation.toLowerCase() &&
      pathname !== GLOBAL_CONFIG.Route.pennyMicrosite.faq.toLowerCase() &&
      pathname !== GLOBAL_CONFIG.Route.pennyMicrosite.terms.toLowerCase() &&
      pathname !== GLOBAL_CONFIG.Route.buzzer.faq.toLowerCase() &&
      pathname !== GLOBAL_CONFIG.Route.changePassword.toLowerCase() &&
      !this.IsBannerMP(location, campaignStatus) &&
      !this.IsFancenterSC(location)
    );
  }

  IsFancenterSC(location) {
    const pathname = location.pathname.toLowerCase();
    return (
      pathname ===
        GLOBAL_CONFIG.Route.fanCenter.tickets.teamSelection.toLowerCase() ||
      pathname ===
        GLOBAL_CONFIG.Route.fanCenter.tickets.userDetails.toLowerCase() ||
      pathname ===
        GLOBAL_CONFIG.Route.fanCenter.tickets.favoriteClub.toLowerCase() ||
      pathname ===
        GLOBAL_CONFIG.Route.fanCenter.tickets.confirmation.toLowerCase() ||
      pathname ===
        GLOBAL_CONFIG.Route.fanCenter.tickets.companion.userDetails.toLowerCase() ||
      pathname ===
        GLOBAL_CONFIG.Route.fanCenter.tickets.companion.confirmation.toLowerCase() ||
      pathname ===
        GLOBAL_CONFIG.Route.fanCenter.tickets.faq.faq.toLowerCase() ||
        pathname ===
          GLOBAL_CONFIG.Route.fanCenter.tickets.faq.faqjersey.toLowerCase() ||
      pathname ===
        GLOBAL_CONFIG.Route.fanCenter.jersey.teamSelection.toLowerCase() ||
      pathname ===
        GLOBAL_CONFIG.Route.fanCenter.jersey.sizeSelection.toLowerCase() ||
      pathname ===
        GLOBAL_CONFIG.Route.fanCenter.userDetails.jersey.toLowerCase() ||
      pathname ===
        GLOBAL_CONFIG.Route.fanCenter.confirmation.jersey.toLowerCase() ||
      pathname ===
        GLOBAL_CONFIG.Route.fanCenter.main.toLowerCase() ||
      pathname === `${GLOBAL_CONFIG.Route.fanCenter.main.toLowerCase()}/`
    );
  }

  IsBannerMP(location, campaignStatus) {
    const pathname = location.pathname.toLowerCase();
    const baseName = "/90min";
    const baseNameWeltmeister = "/weltmeister";
    const baseNameTickets = "/tickets";
    const baseNameBuzzer = "/buzzer";
    const baseNameTrikot = "/trikot";
    const baseNameDoppelt = "/doppeltgewinnen";
    const baseNameSommer = "/sommer";
    if (this.props.is90minPromotion) {
      if (campaignStatus === "main") {
        return (
          pathname === GLOBAL_CONFIG.Route.home.toLowerCase() ||
          pathname === GLOBAL_CONFIG.Route.product.toLowerCase() ||
          pathname === GLOBAL_CONFIG.Route.prize.toLowerCase() ||
          pathname === GLOBAL_CONFIG.Route.terms.toLowerCase() ||
          pathname === baseName.toLowerCase() ||
          pathname === baseName + GLOBAL_CONFIG.Route.home.toLowerCase() ||
          pathname === baseName + GLOBAL_CONFIG.Route.product.toLowerCase() ||
          pathname === baseName + GLOBAL_CONFIG.Route.prize.toLowerCase() ||
          pathname ===
            baseName + GLOBAL_CONFIG.Route.participation.toLowerCase() ||
          pathname === baseName + GLOBAL_CONFIG.Route.faq.toLowerCase() ||
          pathname ===
            baseName + GLOBAL_CONFIG.Route.buzzer.confirmation.toLowerCase() ||
          pathname === baseName + GLOBAL_CONFIG.Route.terms.toLowerCase() ||
          pathname ===
            baseName +
              GLOBAL_CONFIG.Route.ProductCodeVerification.toLowerCase() ||
          pathname ===
            baseName + GLOBAL_CONFIG.Route.NoProductCode.toLowerCase() ||
          pathname ===
            baseName + GLOBAL_CONFIG.Route.noParticipation.toLowerCase()
        );
      } else if (campaignStatus === "holding") {
        return (
          pathname === GLOBAL_CONFIG.Route.terms.toLowerCase() ||
          pathname === baseName + GLOBAL_CONFIG.Route.terms.toLowerCase()
              || pathname === GLOBAL_CONFIG.Route.fanCenter.tickets.teamSelection.toLowerCase()
            || pathname === GLOBAL_CONFIG.Route.fanCenter.tickets.userDetails.toLowerCase()
            || pathname === GLOBAL_CONFIG.Route.fanCenter.tickets.favoriteClub.toLowerCase()
            || pathname === GLOBAL_CONFIG.Route.fanCenter.tickets.confirmation.toLowerCase()
            || pathname === GLOBAL_CONFIG.Route.fanCenter.tickets.companion.userDetails.toLowerCase()
            || pathname === GLOBAL_CONFIG.Route.fanCenter.tickets.companion.confirmation.toLowerCase()
            || pathname === GLOBAL_CONFIG.Route.fanCenter.jersey.teamSelection.toLowerCase()
            || pathname === GLOBAL_CONFIG.Route.fanCenter.jersey.sizeSelection.toLowerCase()
            || pathname === GLOBAL_CONFIG.Route.fanCenter.userDetails.jersey.toLowerCase()
            || pathname === GLOBAL_CONFIG.Route.fanCenter.confirmation.jersey.toLowerCase()
            || pathname === GLOBAL_CONFIG.Route.fanCenter.tickets.faq.faq.toLowerCase()
            || pathname === GLOBAL_CONFIG.Route.fanCenter.jersey.errorJersey.toLowerCase()
            || pathname === GLOBAL_CONFIG.Route.fanCenter.ticket.errorTickets.toLowerCase()
        );
      }
    } else if (this.props.isWeltmeisterPromotion) {
      if (campaignStatus === "main") {
        return (
          pathname === GLOBAL_CONFIG.Route.faq.toLowerCase() ||
          pathname === GLOBAL_CONFIG.Route.participation.toLowerCase() ||
          pathname === GLOBAL_CONFIG.Route.buzzer.selection.toLowerCase() ||
          pathname ===
            baseNameWeltmeister + GLOBAL_CONFIG.Route.faq.toLowerCase() ||
          pathname ===
            baseNameWeltmeister +
              GLOBAL_CONFIG.Route.participation.toLowerCase() ||
          pathname ===
            baseNameWeltmeister +
              GLOBAL_CONFIG.Route.buzzer.selection.toLowerCase() ||
          pathname ===
            baseNameWeltmeister +
              GLOBAL_CONFIG.Route.buzzer.confirmation.toLowerCase()
        );
      } else if (campaignStatus === "holding") {
        return (
          pathname === GLOBAL_CONFIG.Route.terms.toLowerCase() ||
          pathname === baseName + GLOBAL_CONFIG.Route.terms.toLowerCase()
        );
      }
    } else if (this.props.isTicketsPromotion) {
      if (campaignStatus === "main") {
        return (
          pathname === GLOBAL_CONFIG.Route.faq.toLowerCase() ||
          pathname === GLOBAL_CONFIG.Route.participation.toLowerCase() ||
          pathname === GLOBAL_CONFIG.Route.buzzer.selection.toLowerCase() ||
          pathname ===
            baseNameTickets + GLOBAL_CONFIG.Route.faq.toLowerCase() ||
          pathname ===
            baseNameTickets + GLOBAL_CONFIG.Route.participation.toLowerCase() ||
          pathname ===
            baseNameTickets +
              GLOBAL_CONFIG.Route.buzzer.selection.toLowerCase()/*  ||
          pathname ===
            baseNameTickets +
              GLOBAL_CONFIG.Route.buzzer.confirmation.toLowerCase() */
        );
      } else if (campaignStatus === "holding") {
        return (
          pathname === GLOBAL_CONFIG.Route.terms.toLowerCase() ||
          pathname === baseName + GLOBAL_CONFIG.Route.terms.toLowerCase()
        );
      }
    } else if (this.props.isBuzzerPromotion) {
      if (campaignStatus === "main") {
        //const baseNameBuzzer = "/buzzer";
        const { gameIsLive } = this.props.gameState;
        if(gameIsLive === true) {
          return (
            pathname === baseNameBuzzer + GLOBAL_CONFIG.Route.buzzer.closing.toLowerCase() ||
            pathname === GLOBAL_CONFIG.Route.buzzer.closing.toLowerCase()
          )
        }
        return (
          pathname === baseNameBuzzer + GLOBAL_CONFIG.Route.buzzer.participation.toLowerCase() ||
          pathname === baseNameBuzzer + GLOBAL_CONFIG.Route.buzzer.game.toLowerCase() ||
          pathname === baseNameBuzzer + GLOBAL_CONFIG.Route.buzzer.randomDraw.toLowerCase()
        );
      }
    } else if (this.props.isTrikotPromotion) {
      if (campaignStatus === "main") {
          return (
              pathname === GLOBAL_CONFIG.Route.faq.toLowerCase() ||
              pathname === GLOBAL_CONFIG.Route.participation.toLowerCase() ||
              pathname === GLOBAL_CONFIG.Route.buzzer.selection.toLowerCase() ||
              pathname === GLOBAL_CONFIG.Route.buzzer.confirmation.toLowerCase() ||
              pathname === GLOBAL_CONFIG.Route.gamification.toLowerCase() ||
              pathname === baseNameTrikot + GLOBAL_CONFIG.Route.faq.toLowerCase() ||
              pathname === baseNameTrikot + GLOBAL_CONFIG.Route.participation.toLowerCase() ||
              pathname === baseNameTrikot + GLOBAL_CONFIG.Route.buzzer.selection.toLowerCase() ||
              pathname === baseNameTrikot + GLOBAL_CONFIG.Route.buzzer.confirmation.toLowerCase() ||
              pathname === baseNameTrikot + GLOBAL_CONFIG.Route.gamification.toLowerCase()
          );
      } else if (campaignStatus === "holding") {
          return (
              pathname === GLOBAL_CONFIG.Route.terms.toLowerCase() ||
              pathname === baseName + GLOBAL_CONFIG.Route.terms.toLowerCase()
          );
      }
    } else if (this.props.isDoppeltgewinnenPromotion) {
      if (campaignStatus === "main") {
          return (
              pathname === GLOBAL_CONFIG.Route.faq.toLowerCase() ||
              pathname === GLOBAL_CONFIG.Route.participation.toLowerCase() ||
              pathname === GLOBAL_CONFIG.Route.buzzer.selection.toLowerCase() ||
              pathname === GLOBAL_CONFIG.Route.buzzer.confirmation.toLowerCase() ||
              pathname === GLOBAL_CONFIG.Route.gamification.toLowerCase() ||
              pathname === baseNameDoppelt + GLOBAL_CONFIG.Route.faq.toLowerCase() ||
              pathname === baseNameDoppelt + GLOBAL_CONFIG.Route.participation.toLowerCase() ||
              pathname === baseNameDoppelt + GLOBAL_CONFIG.Route.buzzer.selection.toLowerCase() ||
              pathname === baseNameDoppelt + GLOBAL_CONFIG.Route.buzzer.confirmation.toLowerCase() ||
              pathname === baseNameDoppelt + GLOBAL_CONFIG.Route.gamification.toLowerCase()
          );
      } else if (campaignStatus === "holding") {
          return (
              pathname === GLOBAL_CONFIG.Route.terms.toLowerCase() ||
              pathname === baseName + GLOBAL_CONFIG.Route.terms.toLowerCase()
          );
      }
    }else if (this.props.isq2Promotion) {
      if (campaignStatus === "main") {
        return (
          pathname === GLOBAL_CONFIG.Route.faq.toLowerCase() ||
          pathname === GLOBAL_CONFIG.Route.participation.toLowerCase() ||
          pathname === GLOBAL_CONFIG.Route.buzzer.selection.toLowerCase() ||
          pathname === baseNameSommer + GLOBAL_CONFIG.Route.faq.toLowerCase() ||
          pathname === baseNameSommer + GLOBAL_CONFIG.Route.participation.toLowerCase() ||
          pathname === baseNameSommer + GLOBAL_CONFIG.Route.buzzer.selection.toLowerCase()/*  ||
          pathname ===
            baseNameTickets +
              GLOBAL_CONFIG.Route.buzzer.confirmation.toLowerCase() */
        );
      } else if (campaignStatus === "holding") {
        return (
          pathname === GLOBAL_CONFIG.Route.terms.toLowerCase() ||
          pathname === baseName + GLOBAL_CONFIG.Route.terms.toLowerCase()
        );
      }
    }
    return false;
  }

  ////toav no update here
  GetBackgroundColor(location, campaignStatus, props) {
    let rep = "banner__container";
    let repWhite = "banner__container banner__container--white";
    let repRed = "banner__container banner__container--red";
    let pathnameLower = location.pathname.toLowerCase();
    if (campaignStatus === "main" || campaignStatus === "holding") {
      if (
        pathnameLower === GLOBAL_CONFIG.Route.home.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.prize.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.faq.toLowerCase()
      ) {
        return repWhite;
      } else if (
        pathnameLower ===
          GLOBAL_CONFIG.Route.buzzer.holdingPenny.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.penny.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.endPenny.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.buzzer.holdingEdeka.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.edeka.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.endEdeka.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.buzzer.holdingRewe.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.rewe.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.endRewe.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.buzzer.holdingnetto.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.netto.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.endnetto.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.buzzer.holdingkaufland.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.kaufland.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.endkaufland.toLowerCase()
      ) {
        return repRed;
      }
    }

    if (campaignStatus === "holding") {
      return repWhite;
    }

    if (campaignStatus === "end") {
      if (pathnameLower === GLOBAL_CONFIG.Route.end.toLowerCase()) {
        return repWhite;
      }
    }
    return rep;
  }

  GetMicroGlobusBanner(pathnameLower, props, campaignStatus) {
    const _CAMPAIGN = "Supercup";
    let mobileImage = null,
      desktopImage = null,
      isMicroGlobus = true;

    if (
      pathnameLower === GLOBAL_CONFIG.Route.globusMicroSite.end.toLowerCase() ||
      (isGlobusMicrosite() &&
        campaignStatus === "end" &&
        (pathnameLower === GLOBAL_CONFIG.Route.home.toLowerCase() ||
          pathnameLower === ""))
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.micro-globus.end.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.micro-globus.end.desktop`);
    } else if (
      pathnameLower ===
        GLOBAL_CONFIG.Route.globusMicroSite.main.toLowerCase() ||
      (isGlobusMicrosite() &&
        (pathnameLower === GLOBAL_CONFIG.Route.home.toLowerCase() ||
          pathnameLower === ""))
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.micro-globus.main.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.micro-globus.main.desktop`);
    }

    if (mobileImage && desktopImage) {
      return {
        mobileImage,
        desktopImage,
        isMicroGlobus,
      };
    }
    return null;
  }

  getCampaign() {
    if (this.props.is90minPromotion) {
      return "90min";
    } else if (this.props.isWeltmeisterPromotion) {
      return "Weltmeister";
    } else if (this.props.isTicketsPromotion) {
      return "TicketsPromotion";
    } else if (this.props.isTrikotPromotion) {
      return "TrikotPromotion";
    } else if (this.props.isDoppeltgewinnenPromotion) {
      return "Doppeltgewinnen";
    } else if (this.props.isq2Promotion) {
      return "Sommer";
    }
    return "Buzzer";
  }

  getWeltmeisterDomain() {
    let hostName = window.location.hostname,
      isProd = hostName.toLowerCase().indexOf("fcmilka") > -1 ? true : false;
    const baseDomaine = isProd
      ? "https://weltmeister.fcmilka.de"
      : "/weltmeister";
    return baseDomaine;
  }

  BannerRender(location, campaignStatus, props) {
    //const _CAMPAIGN = props.is90minPromotion ? "90min" : "Buzzer" ;
    const _CAMPAIGN = this.getCampaign();
    let pathnameLower = location.pathname.toLowerCase(),
      mobileImage = null,
      desktopImage = null;

    pathnameLower = pathnameLower.replace(/[\/\\]+$/, "");
    const { gameIsLive } = this.props.gameState;
    const microGlobusBanner = this.GetMicroGlobusBanner(
      pathnameLower,
      props,
      campaignStatus
    );

    if (microGlobusBanner) {
      return microGlobusBanner;
    }

    if (campaignStatus === "end") {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.closing.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.closing.desktop`);

      if (
        pathnameLower === GLOBAL_CONFIG.Route.fanCenter.main.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.giftChoice.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.codeEntry.user.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.fancenterTerms.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.codeEntry.companion.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.ticket.teamSelection.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.userDetails.user.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.userDetails.companion.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.confirmation.user.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.tickets.forMeOrFriend.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.confirmation.companion.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.tickets.confirmationFriend.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.FanCenter.main.mobile`);
        desktopImage = props.t(`Banners.FanCenter.main.desktop`);
      } else if (

      /* Jersey */
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.jersey.teamSelection.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.jersey.sizeSelection.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.userDetails.jersey.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.FanCenter.jersey.main.mobile`);
        desktopImage = props.t(`Banners.FanCenter.jersey.main.desktop`);
      } else if (
        pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.confirmation.jersey.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.FanCenter.jersey.confirmation.mobile`);
        desktopImage = props.t(`Banners.FanCenter.jersey.confirmation.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.netto.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.netto.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.netto.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.endnetto.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.netto.end.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.netto.end.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingnetto.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.netto.holding.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.netto.holding.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.kaufland.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.kaufland.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.kaufland.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.endkaufland.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.kaufland.end.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.kaufland.end.desktop`);
      } else if (
        pathnameLower ===
        GLOBAL_CONFIG.Route.buzzer.holdingkaufland.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.kaufland.holding.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.kaufland.holding.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.edeka.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.edeka.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.edeka.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.endEdeka.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.edeka.end.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.edeka.end.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingEdeka.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.edeka.holding.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.edeka.holding.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.rewe.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.rewe.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.rewe.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.endRewe.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.rewe.end.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.rewe.end.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingRewe.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.rewe.holding.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.rewe.holding.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingGlobus.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.globus.holding.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.globus.holding.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.globusTerms.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.globus.holding.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.globus.holding.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.penny.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.penny.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.penny.desktop`);
      } else if (
        pathnameLower ===
        GLOBAL_CONFIG.Route.pennyMicrosite.participation.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.penny-gondelkopf.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.penny-gondelkopf.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.pennyMicrosite.main.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.penny-gondelkopf.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.penny-gondelkopf.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingPenny.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.penny.holding.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.penny.holding.desktop`);
      } else if (
        pathnameLower ===
        GLOBAL_CONFIG.Route.pennyMicrosite.holding.toLowerCase()
      ) {
        mobileImage = props.t(
          `Banners.${_CAMPAIGN}.penny-gondelkopf.holding.mobile`
        );
        desktopImage = props.t(
          `Banners.${_CAMPAIGN}.penny-gondelkopf.holding.desktop`
        );
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.globus.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.globus.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.globus.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.endPenny.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.penny.end.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.penny.end.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.pennyMicrosite.end.toLowerCase()
      ) {
        mobileImage = props.t(
          `Banners.${_CAMPAIGN}.penny-gondelkopf.end.mobile`
        );
        desktopImage = props.t(
          `Banners.${_CAMPAIGN}.penny-gondelkopf.end.desktop`
        );
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.endGlobus.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.globus.end.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.globus.end.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.fairplay.toLowerCase() ||
        pathnameLower ===
          "/" + _CAMPAIGN + GLOBAL_CONFIG.Route.fairplay.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.fairplay.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.fairplay.desktop`);
      } else if ( pathnameLower === "/sommer") {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.end.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.end.desktop`);
      }

      return {
        mobileImage,
        desktopImage,
      };
    }

    if (
      campaignStatus === "holding" &&
      mobileImage == null &&
      desktopImage == null
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.holding.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.holding.desktop`);

      if (
        pathnameLower === GLOBAL_CONFIG.Route.fanCenter.main.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.giftChoice.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.codeEntry.user.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.fancenterTerms.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.codeEntry.companion.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.ticket.teamSelection.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.userDetails.user.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.userDetails.companion.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.confirmation.user.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.tickets.forMeOrFriend.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.confirmation.companion.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.tickets.confirmationFriend.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.FanCenter.main.mobile`);
        desktopImage = props.t(`Banners.FanCenter.main.desktop`);
      } else if (

      /* Jersey */
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.jersey.teamSelection.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.jersey.sizeSelection.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.userDetails.jersey.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.FanCenter.jersey.main.mobile`);
        desktopImage = props.t(`Banners.FanCenter.jersey.main.desktop`);
      } else if (
        pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.confirmation.jersey.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.FanCenter.jersey.confirmation.mobile`);
        desktopImage = props.t(`Banners.FanCenter.jersey.confirmation.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.netto.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.netto.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.netto.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.endnetto.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.netto.end.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.netto.end.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingnetto.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.netto.holding.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.netto.holding.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.kaufland.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.kaufland.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.kaufland.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.endkaufland.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.kaufland.end.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.kaufland.end.desktop`);
      } else if (
        pathnameLower ===
        GLOBAL_CONFIG.Route.buzzer.holdingkaufland.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.kaufland.holding.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.kaufland.holding.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.edeka.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.edeka.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.edeka.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.endEdeka.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.edeka.end.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.edeka.end.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingEdeka.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.edeka.holding.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.edeka.holding.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.rewe.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.rewe.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.rewe.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.endRewe.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.rewe.end.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.rewe.end.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingRewe.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.rewe.holding.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.rewe.holding.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingGlobus.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.globus.holding.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.globus.holding.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.globusTerms.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.globus.holding.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.globus.holding.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.penny.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.penny.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.penny.desktop`);
      } else if (
        pathnameLower ===
        GLOBAL_CONFIG.Route.pennyMicrosite.participation.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.penny-gondelkopf.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.penny-gondelkopf.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.pennyMicrosite.main.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.penny-gondelkopf.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.penny-gondelkopf.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingPenny.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.penny.holding.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.penny.holding.desktop`);
      } else if (
        pathnameLower ===
        GLOBAL_CONFIG.Route.pennyMicrosite.holding.toLowerCase()
      ) {
        mobileImage = props.t(
          `Banners.${_CAMPAIGN}.penny-gondelkopf.holding.mobile`
        );
        desktopImage = props.t(
          `Banners.${_CAMPAIGN}.penny-gondelkopf.holding.desktop`
        );
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.globus.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.globus.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.globus.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.endPenny.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.penny.end.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.penny.end.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.pennyMicrosite.end.toLowerCase()
      ) {
        mobileImage = props.t(
          `Banners.${_CAMPAIGN}.penny-gondelkopf.end.mobile`
        );
        desktopImage = props.t(
          `Banners.${_CAMPAIGN}.penny-gondelkopf.end.desktop`
        );
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.endGlobus.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.globus.end.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.globus.end.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.fairplay.toLowerCase() ||
        pathnameLower ===
          "/" + (_CAMPAIGN + GLOBAL_CONFIG.Route.fairplay).toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.fairplay.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.fairplay.desktop`);
      }else if (
        pathnameLower ===
        `/tickets${GLOBAL_CONFIG.Route.fairplay.toLowerCase()}`
      ) {
        mobileImage = props.t(`Banners.TicketsPromotion.fairplay.mobile`);
        desktopImage = props.t(`Banners.TicketsPromotion.fairplay.desktop`);
      }

      return {
        mobileImage,
        desktopImage,
      };
    }

    /*Q3 Extended Promo */
    if (
      campaignStatus === "extended" &&
      mobileImage == null &&
      desktopImage == null
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.extended.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.extended.desktop`);

      if (
        pathnameLower === GLOBAL_CONFIG.Route.fanCenter.main.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.giftChoice.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.codeEntry.user.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.fancenterTerms.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.codeEntry.companion.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.ticket.teamSelection.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.userDetails.user.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.userDetails.companion.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.confirmation.user.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.tickets.forMeOrFriend.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.confirmation.companion.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.tickets.confirmationFriend.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.FanCenter.main.mobile`);
        desktopImage = props.t(`Banners.FanCenter.main.desktop`);
      } else if (
      /* Jersey */
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.jersey.teamSelection.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.jersey.sizeSelection.toLowerCase() ||
        pathnameLower ===
          GLOBAL_CONFIG.Route.fanCenter.userDetails.jersey.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.FanCenter.jersey.main.mobile`);
        desktopImage = props.t(`Banners.FanCenter.jersey.main.desktop`);
      } else if (
        pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.confirmation.jersey.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.FanCenter.jersey.confirmation.mobile`);
        desktopImage = props.t(`Banners.FanCenter.jersey.confirmation.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.netto.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.netto.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.netto.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.endnetto.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.netto.end.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.netto.end.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingnetto.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.netto.holding.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.netto.holding.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.kaufland.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.kaufland.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.kaufland.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.endkaufland.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.kaufland.end.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.kaufland.end.desktop`);
      } else if (
        pathnameLower ===
        GLOBAL_CONFIG.Route.buzzer.holdingkaufland.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.kaufland.holding.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.kaufland.holding.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.edeka.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.edeka.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.edeka.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.endEdeka.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.edeka.end.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.edeka.end.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingEdeka.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.edeka.holding.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.edeka.holding.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.rewe.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.rewe.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.rewe.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.endRewe.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.rewe.end.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.rewe.end.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingRewe.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.rewe.holding.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.rewe.holding.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingGlobus.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.globus.holding.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.globus.holding.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.globusTerms.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.globus.holding.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.globus.holding.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.penny.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.penny.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.penny.desktop`);
      } else if (
        pathnameLower ===
        GLOBAL_CONFIG.Route.pennyMicrosite.participation.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.penny-gondelkopf.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.penny-gondelkopf.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.pennyMicrosite.main.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.penny-gondelkopf.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.penny-gondelkopf.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingPenny.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.penny.holding.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.penny.holding.desktop`);
      } else if (
        pathnameLower ===
        GLOBAL_CONFIG.Route.pennyMicrosite.holding.toLowerCase()
      ) {
        mobileImage = props.t(
          `Banners.${_CAMPAIGN}.penny-gondelkopf.holding.mobile`
        );
        desktopImage = props.t(
          `Banners.${_CAMPAIGN}.penny-gondelkopf.holding.desktop`
        );
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.globus.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.globus.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.globus.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.endPenny.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.penny.end.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.penny.end.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.pennyMicrosite.end.toLowerCase()
      ) {
        mobileImage = props.t(
          `Banners.${_CAMPAIGN}.penny-gondelkopf.end.mobile`
        );
        desktopImage = props.t(
          `Banners.${_CAMPAIGN}.penny-gondelkopf.end.desktop`
        );
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.endGlobus.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.globus.end.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.globus.end.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.fairplay.toLowerCase() ||
        pathnameLower ===
          "/" + (_CAMPAIGN + GLOBAL_CONFIG.Route.fairplay).toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.fairplay.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.fairplay.desktop`);
      }

      return {
        mobileImage,
        desktopImage,
      };
    }

    if (isReihePromotion() && this.props.campaignStatus === "end") {

      mobileImage = '/resources/imagesBundesliga/banner/reihe/Header-mobile-end.png';
      desktopImage = '/resources/imagesBundesliga/banner/reihe/Header-desktop-end.png';
      return {
        mobileImage,
        desktopImage,
      };

    }

    if (isReihePromotion() && this.props.campaignStatus === "holding") {

      mobileImage = '/resources/imagesBundesliga/banner/reihe/Header-mobile.png';
      desktopImage = '/resources/imagesBundesliga/banner/reihe/Header-desktop.png';
      return {
        mobileImage,
        desktopImage,
      };

    }

    if(isReihePromotion() && this.props.campaignStatus==='main'){
      mobileImage = '/resources/imagesBundesliga/banner/reihe/Header-mobile-main.png';
      desktopImage = '/resources/imagesBundesliga/banner/reihe/Header-desktop-main1.png';

      if (pathnameLower.includes(GLOBAL_CONFIG.Route.reihe.faq) || pathnameLower.includes(GLOBAL_CONFIG.Route.reihe.resetPassword)) {
        desktopImage = '/resources/imagesBundesliga/banner/reihe/Header-desktop-main.png';
        mobileImage = '/resources/imagesBundesliga/banner/reihe/Header-mobile.png';

      }
      if (pathnameLower.includes(GLOBAL_CONFIG.Route.reihe.confirmation)){
        desktopImage = '/resources/imagesBundesliga/banner/reihe/Header-desktop-confirm.png';
        mobileImage = '/resources/imagesBundesliga/banner/reihe/Header-mobile.png';
      }

      return {
        mobileImage,
        desktopImage,
      };
    }

    if(this.props.isTicketsPromotion && campaignStatus === "main") {
      const baseNameTickets = "/tickets";
      mobileImage = props.t(`Banners.${_CAMPAIGN}.main.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.main.desktop`);
      if(pathnameLower === GLOBAL_CONFIG.Route.buzzer.prize.toLowerCase() ||
      pathnameLower ===  baseNameTickets + GLOBAL_CONFIG.Route.buzzer.prize.toLowerCase()) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.prize.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.prize.desktop`);
      }
      if(pathnameLower === GLOBAL_CONFIG.Route.buzzer.confirmation.toLowerCase() ||
      pathnameLower ===  baseNameTickets + GLOBAL_CONFIG.Route.buzzer.confirmation.toLowerCase()) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.confirmation.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.confirmation.desktop`);
      }
      if(pathnameLower === "/end" ||
      pathnameLower ===  baseNameTickets + "/end") {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.closing.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.closing.desktop`);
      }
      return {
        mobileImage,
        desktopImage,
      };
    }
 
    /* Buzzer Game When Game is off */  
    if(this.props.isBuzzerPromotion && campaignStatus === "main" && gameIsLive === false) {
      const baseNameBuzzer = "/buzzer";
      if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.main.toLowerCase() ||
        pathnameLower ===  baseNameBuzzer ||
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.product.toLowerCase() ||
        pathnameLower ===  baseNameBuzzer + GLOBAL_CONFIG.Route.buzzer.product.toLowerCase()
      ) {
        mobileImage = props.t(`Banners.${_CAMPAIGN}.game-off.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.game-off.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.prize.toLowerCase() ||
        pathnameLower ===  baseNameBuzzer + GLOBAL_CONFIG.Route.buzzer.prize.toLowerCase()
      ){
        mobileImage = props.t(`Banners.${_CAMPAIGN}.game-off.prize.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.game-off.prize.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.holding.toLowerCase() ||
        pathnameLower ===  baseNameBuzzer + GLOBAL_CONFIG.Route.buzzer.holding.toLowerCase()
      ){
        mobileImage = props.t(`Banners.${_CAMPAIGN}.holding.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.holding.desktop`);
      } else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.closing.toLowerCase() ||
        pathnameLower ===  baseNameBuzzer + GLOBAL_CONFIG.Route.buzzer.closing.toLowerCase()
      ){
        mobileImage = props.t(`Banners.${_CAMPAIGN}.game-off.closing.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.game-off.closing.desktop`);
      }
      return {
        mobileImage,
        desktopImage,
      };
    }
    if(this.props.isBuzzerPromotion && campaignStatus === "end") {
          const baseNameBuzzer = "/buzzer";
          if (
            pathnameLower === GLOBAL_CONFIG.Route.buzzer.end.toLowerCase() ||
            pathnameLower ===  baseNameBuzzer
          ) {
            mobileImage = props.t(`Banners.${_CAMPAIGN}.game-off-end.mobile`);
            desktopImage = props.t(`Banners.${_CAMPAIGN}.game-off-end.desktop`);
          } 
          return {
        mobileImage,
        desktopImage,
      };

    }
 
    if (
      pathnameLower == "/account" ||
      pathnameLower === "/fancenter" ||
      pathnameLower === "/codeentry" ||
      pathnameLower === "/dein-gewinn"
    ) {
      desktopImage =
        "/resources/imagesBundesliga/fanCenter/fancenter-banner.png";
      mobileImage =
        "/resources/imagesBundesliga/fanCenter/fancenter-banner-sm.png";
    } else if (
      pathnameLower === GLOBAL_CONFIG.Route.register.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.loginDetail.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.accountInformation.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.address.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.resetPassword.toLowerCase()
    ) {
      mobileImage = "/resources/imagesBundesliga/fcAccount/register_bg.png";
      desktopImage = "/resources/imagesBundesliga/fcAccount/register_bg.png";
    } else if (
    /**
     * Q3 Routes
     */
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.holding.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.intro.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.holding.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.holding.desktop`);
    } else if (
      pathnameLower === "" ||
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.main.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.game.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.main.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.main.desktop`);
    }  else if (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.holding.toLowerCase()
      ){
        mobileImage = props.t(`Banners.${_CAMPAIGN}.holding.mobile`);
        desktopImage = props.t(`Banners.${_CAMPAIGN}.holding.desktop`);
      } else if (
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.netto.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.netto.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.netto.desktop`);
    } else if (
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.endnetto.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.netto.end.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.netto.end.desktop`);
    } else if (
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingnetto.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.netto.holding.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.netto.holding.desktop`);
    } else if (
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.kaufland.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.kaufland.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.kaufland.desktop`);
    } else if (
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.endkaufland.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.kaufland.end.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.kaufland.end.desktop`);
    } else if (
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingkaufland.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.kaufland.holding.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.kaufland.holding.desktop`);
    } else if (
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.edeka.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.edeka.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.edeka.desktop`);
    } else if (
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.endEdeka.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.edeka.end.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.edeka.end.desktop`);
    } else if (
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingEdeka.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.edeka.holding.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.edeka.holding.desktop`);
    } else if (
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.rewe.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.rewe.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.rewe.desktop`);
    } else if (
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.endRewe.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.rewe.end.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.rewe.end.desktop`);
    } else if (
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingRewe.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.rewe.holding.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.rewe.holding.desktop`);
    } else if (
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingGlobus.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.globus.holding.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.globus.holding.desktop`);
    } else if (
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.globusTerms.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.globus.holding.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.globus.holding.desktop`);
    } else if (
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.penny.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.penny.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.penny.desktop`);
    } else if (
      pathnameLower ===
      GLOBAL_CONFIG.Route.pennyMicrosite.participation.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.penny-gondelkopf.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.penny-gondelkopf.desktop`);
    } else if (
      pathnameLower === GLOBAL_CONFIG.Route.pennyMicrosite.main.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.penny-gondelkopf.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.penny-gondelkopf.desktop`);
    } else if (
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingPenny.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.penny.holding.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.penny.holding.desktop`);
    } else if (
      pathnameLower === GLOBAL_CONFIG.Route.pennyMicrosite.holding.toLowerCase()
    ) {
      mobileImage = props.t(
        `Banners.${_CAMPAIGN}.penny-gondelkopf.holding.mobile`
      );
      desktopImage = props.t(
        `Banners.${_CAMPAIGN}.penny-gondelkopf.holding.desktop`
      );
    } else if (
      pathnameLower ===
      GLOBAL_CONFIG.Route.pennyMicrosite.confirmation.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.penny-gondelkopf.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.penny-gondelkopf.desktop`);
    } else if (
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.globus.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.globus.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.globus.desktop`);
    } else if (
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.endPenny.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.penny.end.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.penny.end.desktop`);
    } else if (
      pathnameLower === GLOBAL_CONFIG.Route.pennyMicrosite.end.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.penny-gondelkopf.end.mobile`);
      desktopImage = props.t(
        `Banners.${_CAMPAIGN}.penny-gondelkopf.end.desktop`
      );
    } else if (
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.endGlobus.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.globus.end.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.globus.end.desktop`);
    } else if (
      pathnameLower === GLOBAL_CONFIG.Route.fairplay.toLowerCase() ||
      pathnameLower ===
        "/" + (_CAMPAIGN + GLOBAL_CONFIG.Route.fairplay).toLowerCase()
    ) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.fairplay.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.fairplay.desktop`);
    } else if (

    /* Jersey */
      pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.jersey.teamSelection.toLowerCase() ||
      pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.jersey.sizeSelection.toLowerCase() ||
      pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.userDetails.jersey.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.FanCenter.jersey.teamSelection.mobile`);
      desktopImage = props.t(`Banners.FanCenter.jersey.teamSelection.desktop`);
    } else if (
      pathnameLower ===
      GLOBAL_CONFIG.Route.fanCenter.confirmation.jersey.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.FanCenter.jersey.confirmation.mobile`);
      desktopImage = props.t(`Banners.FanCenter.jersey.confirmation.desktop`);
    } else if (
      pathnameLower === GLOBAL_CONFIG.Route.fanCenter.main.toLowerCase() ||
      pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.giftChoice.toLowerCase() ||
      pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.codeEntry.user.toLowerCase() ||
      pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.codeEntry.companion.toLowerCase() ||
      pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.userDetails.companion.toLowerCase() ||
      pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.confirmation.companion.toLowerCase() ||
      pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.tickets.forMeOrFriend.toLowerCase() ||
      pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.tickets.confirmationFriend.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.fancenterTerms.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.FanCenter.main.mobile`);
      desktopImage = props.t(`Banners.FanCenter.main.desktop`);
    } else if (
      pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.ticket.teamSelection.toLowerCase() ||
      pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.userDetails.user.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.FanCenter.ticket.teamSelection.mobile`);
      desktopImage = props.t(`Banners.FanCenter.ticket.teamSelection.desktop`);
    } else if (
      pathnameLower ===
      GLOBAL_CONFIG.Route.fanCenter.confirmation.user.toLowerCase()
    ) {
      mobileImage = props.t(`Banners.FanCenter.confirmation.user.mobile`);
      desktopImage = props.t(`Banners.FanCenter.confirmation.user.desktop`);
    } else if (
      pathnameLower ===
      `/tickets${GLOBAL_CONFIG.Route.fairplay.toLowerCase()}`
    ) {
      mobileImage = props.t(`Banners.TicketsPromotion.fairplay.mobile`);
      desktopImage = props.t(`Banners.TicketsPromotion.fairplay.desktop`);
    } else if(
      (pathnameLower === "/doppeltgewinnen/end") || pathnameLower === "/end") {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.closing.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.closing.desktop`);
    } else if (pathnameLower === "/sommer/danke"){
      mobileImage = props.t(`Banners.${_CAMPAIGN}.main.thank-you.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.main.thank-you.desktop`);
    }
    else if (pathnameLower === GLOBAL_CONFIG.Route.sommer.thankyou.toLowerCase()) {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.main.thank-you.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.main.thank-you.desktop`);
    }
    else {
      mobileImage = props.t(`Banners.${_CAMPAIGN}.main.mobile`);
      desktopImage = props.t(`Banners.${_CAMPAIGN}.main.desktop`);
    }

    return {
      mobileImage,
      desktopImage,
    };
  }

  BannerButtonRender(location, campaignStatus, isMicroGlobus) {
    let pathnameLower = location.pathname.toLowerCase();
    const { gameIsLive } = this.props.gameState;

    if(isReihePromotion()){
      return null;
    }

    if (pathnameLower === "/fancenter") {
      return (
        <div className="form-button form-button-main fancenter-banner-button">
          <NavLink
            to={GLOBAL_CONFIG.Route.fanCenter.codeEntry.user}
            exact
            className="participate-section"
            data-event="button-click"
            data-category="Click Action"
            data-action="Participate"
            data-label={timeStamp()}
            onClick={appGoogleTracking.processEventCTANavLink}
          >
            <button className="btn__container btn--secondary-lilac">
              Code einlösen
            </button>
          </NavLink>
        </div>
      );
    }

    if (pathnameLower === "/globus" || pathnameLower === "/globus/") {
      return (
        <div className="form-button form-button-main globus">
          <a
            href={`${this.getWeltmeisterDomain()}${
              GLOBAL_CONFIG.Route.home
            }?kip=globus`}
            exact
            className="participate-section"
            data-event="button-click"
            data-category="Click Action"
            data-action="Participate"
            data-label={timeStamp()}
            onClick={appGoogleTracking.processEventCTANavLink}
          >
            <button className="btn__container btn--secondary-lilac">
              JETZT MITMACHEN
            </button>
          </a>
        </div>
      );
    }
    if (
      (!!campaignStatus && campaignStatus != "main" && !isMicroGlobus) ||
      pathnameLower === "/cookie" ||
      pathnameLower === "/mitmachen" ||
      pathnameLower === "/account" ||
      pathnameLower === "/codeEntry" ||
      pathnameLower === "/dein-gewinn" ||
      pathnameLower === "/register" ||
      pathnameLower === "/logindetail" ||
      pathnameLower === "/accountinformationen" ||
      pathnameLower === "/lieferadressen" ||
      pathnameLower === "/resetpassword" ||
      pathnameLower === "/tickets/fairplay" ||
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.closing.toLowerCase() ||
      pathnameLower.indexOf("holding") > -1
    ) {
      return null;
    }

    if (!!campaignStatus && campaignStatus == "end" && !isMicroGlobus) {
      return null;
    }

    // FanCenter
    if (
      pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.giftChoice.toLowerCase() ||
      pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.codeEntry.user.toLowerCase() ||
      pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.codeEntry.companion.toLowerCase() ||
      pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.jersey.teamSelection.toLowerCase() ||
      pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.jersey.sizeSelection.toLowerCase() ||
      pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.ticket.teamSelection.toLowerCase() ||
      pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.ticket.confirmation.user.toLowerCase() ||
      pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.ticket.confirmation.companion.toLowerCase() ||
      pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.userDetails.jersey.toLowerCase() ||
      pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.userDetails.user.toLowerCase() ||
      pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.userDetails.companion.toLowerCase() ||
      pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.confirmation.jersey.toLowerCase() ||
      pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.confirmation.user.toLowerCase() ||
      pathnameLower ===
        GLOBAL_CONFIG.Route.fanCenter.confirmation.companion.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.endnetto.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.endkaufland.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.endEdeka.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.endRewe.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.endPenny.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.edeka.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.globus.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.endGlobus.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.globusTerms.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.rewe.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.penny.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.pennyMicrosite.main.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.pennyMicrosite.main.toLowerCase() + "/" ||
      pathnameLower === GLOBAL_CONFIG.Route.pennyMicrosite.participation.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.netto.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.fairplay.toLowerCase() ||
      pathnameLower === "/90min" + GLOBAL_CONFIG.Route.fairplay.toLowerCase() ||
      pathnameLower === "/weltmeister" + GLOBAL_CONFIG.Route.fairplay.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.globusMicroSite.confirmation.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.globusMicroSite.participation.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.globusMicroSite.end.toLowerCase() ||
      (isGlobusMicrosite() && campaignStatus === "end") ||
      pathnameLower === GLOBAL_CONFIG.Route.globusMicroSite.faq.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.kaufland.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.fanCenter.tickets.forMeOrFriend.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.fanCenter.tickets.confirmationFriend.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.fancenterTerms.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.pennyMicrosite.confirmation.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.pennyMicrosite.end.toLowerCase()
    ) {
      return null;
    } else if (
      pathnameLower === "/buzzer" + GLOBAL_CONFIG.Route.buzzer.closing.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.closing.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.kaufland.toLowerCase()
    ) {
      return null;
    } else if (this.isBuzzerPromotion && campaignStatus == "main" && gameIsLive === true) {
        if(
          pathnameLower === GLOBAL_CONFIG.Route.buzzer.product.toLowerCase() ||
          pathnameLower === GLOBAL_CONFIG.Route.buzzer.main.toLowerCase() ||
          pathnameLower === GLOBAL_CONFIG.Route.buzzer.prize.toLowerCase()
          )
          return null;
      }

    else if (this.isWeltmeisterPromotion && campaignStatus == "main") {
      return (
        <>
          <div
            className="form-button form-button-main weltmeister"
            onClick={() => {
              this.toggleModal();
            }}
          >
            <button className="btn__container btn--secondary-lilac">
              JETZT MITMACHEN
            </button>
          </div>
          <Q4PopUp
            modal={this.state.modal}
            toggleModal={() => {
              this.toggleModal();
            }}
          />
        </>
      );
    } else if (this.isTicketsPromotion && campaignStatus == "main") {
      if((pathnameLower === "/tickets/confirmation") || 
          pathnameLower === "/confirmation" || 
          pathnameLower === "/tickets/end" ||
          pathnameLower === "/end") {
        return null
      }
      if((pathnameLower === "/tickets/gewinne") || pathnameLower === "/gewinne") {
        return (
          <>
            <div className="form-button form-button-main weltmeister tickets">
              <button className="btn__container btn--secondary-lilac"
              data-event="begin_participation_top"
              data-category="Prize Page"
              data-action="Click on Join Now"
              data-label="GAME_PRIZE_OTHER"
              onClick={(e) => {
                this.modalTracking(e);
              }}
              >
                JETZT MITMACHEN
              </button>
            </div>  
            <TicketsPromoPopUp
              modal={this.state.modal}
              toggleModal={() => {
                this.toggleModal();
              }}
            />
        </>
        );
      }
      return (
        <>
          <div className="form-button form-button-main weltmeister tickets">
            <button className="btn__container btn--secondary-lilac"
            data-event="begin_participation_top"
            data-category="Start Page"
            data-action="Click on Join Now"
            data-label="GAME_SP_OTHER"
            onClick={(e) => {
              this.modalTracking(e);
            }}
            >
              JETZT MITMACHEN
            </button>
          </div>  
          <TicketsPromoPopUp
            modal={this.state.modal}
            toggleModal={() => {
              this.toggleModal();
            }}
          />
        </>
      );
    } else if (/* this.isBuzzerPromotion && campaignStatus == "main" */
    
    (this.isBuzzerPromotion && campaignStatus == "main" && gameIsLive === false)/*  ||
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.product.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.main.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.buzzer.prize.toLowerCase() */
    ) {
      return (
        <>
          <div className="form-button form-button-main weltmeister buzzer-game">
            <button className="btn__container btn--secondary-lilac"
            data-event="custom_event_click"
            data-category="click_action"
            data-action="participate"
            data-label="participate_start_page"
            onClick={(e) => {
              this.modalTracking(e);
            }}
            >
              JETZT MITMACHEN
            </button>
          </div>
          <Q4PopUpV1 
            modal={this.state.modal}
            toggleModal={() => {
                this.toggleModal();
            }} />
        </>
      );
    } else if (this.isTrikotPromotion && campaignStatus == "main") {
      return (
        <>
          <div className="form-button form-button-main weltmeister trikot">
            <button className="btn__container btn--secondary-lilac"
            data-event="custom_event_click"
            data-category="click_action"
            data-action="participate"
            data-label="participate_start_page"
            onClick={(e) => {
              this.modalTracking(e);
            }}
            ><span>JETZT</span>{" "}MITMACHEN</button>
          </div>
          <TrikotPromoPopUp
            modal={this.state.modal}
            toggleModal={() => {
              this.toggleModal();
            }}
          />
        </>
      );
    } else if (this.isq2Promotion && campaignStatus == "main") {
      return null;
    } else if (this.isDoppeltPromotion && campaignStatus == "main") {
      if((pathnameLower === "/doppeltgewinnen/end") || pathnameLower === "/end") {
        return null;
      }
      if((pathnameLower === "/doppeltgewinnen/gewinne") || pathnameLower === "/gewinne") {
        return (
          <>
            <div className="form-button form-button-main weltmeister trikot Doppelt">
              <button className="btn__container btn--secondary-lilac"
              data-event="begin_participation_top"
              data-category="Prizes Page"
              data-action="Click on Join Now"
              data-label="GAME_PRIZE_OTHER"
              onClick={(e) => {
                this.modalTracking(e);
              }}
              ><span>JETZT</span>{" "}MITMACHEN</button>
            </div>
            <PopUp
              modal={this.state.modal}
              toggleModal={() => {
                this.toggleModal();
              }}
            />
          </>
        );
      }
      if((pathnameLower === "/doppeltgewinnen/charity") || pathnameLower === "/charity") {
        return (
          <>
            <div className="form-button form-button-main weltmeister trikot Doppelt">
              <button className="btn__container btn--secondary-lilac"
              data-event="begin_participation_top"
              data-category="Charity Page"
              data-action="Click on Join Now"
              data-label="GAME_CHARITY_OTHER"
              onClick={(e) => {
                this.modalTracking(e);
              }}
              ><span>JETZT</span>{" "}MITMACHEN</button>
            </div>
            <PopUp
              modal={this.state.modal}
              toggleModal={() => {
                this.toggleModal();
              }}
            />
          </>
        );
      }
      return (
        <>
          <div className="form-button form-button-main weltmeister trikot Doppelt">
            <button className="btn__container btn--secondary-lilac"
            data-event="begin_participation_top"
            data-category="Start Page"
            data-action="Click on Join Now"
            data-label="GAME_SP_OTHER"
            onClick={(e) => {
              this.modalTracking(e);
            }}
            ><span>JETZT</span>{" "}MITMACHEN</button>
          </div>
          <PopUp
            modal={this.state.modal}
            toggleModal={() => {
              this.toggleModal();
            }}
          />
        </>
      );
    } else {
      return (
        <div className="form-button form-button-main">
          <NavLink
            to={
              isMicroGlobus
                ? GLOBAL_CONFIG.Route.globusMicroSite.participation
                : GLOBAL_CONFIG.Route.participation
            }
            exact
            className="participate-section"
            data-event="button-click"
            data-category="Click Action"
            data-action="Participate"
            data-label={timeStamp()}
            onClick={appGoogleTracking.processEventCTANavLink}
          >
            <button className="btn__container btn--secondary-lilac">
              JETZT MITMACHEN
            </button>
          </NavLink>
        </div>
      );
    }
  }

  HeadingBeforeBannerRender(location, campaign) {
    let pathnameLower = location.pathname.toLowerCase();
    if (

      (campaign === "end" &&
        pathnameLower === GLOBAL_CONFIG.Route.home.toLowerCase()) ||
      pathnameLower === GLOBAL_CONFIG.Route.intropage.toLowerCase() ||
      pathnameLower === GLOBAL_CONFIG.Route.registerDOI.toLowerCase()
    ) {
      return (
        <div className="overview-header__container">
          <div className="overview-header__img">
            <picture>
              <source
                srcSet="/resources/imagesBundesliga/intropage/overview-header_2.png"
                media="(min-width: 1024px)"
              />
              <source
                srcSet="/resources/imagesBundesliga/intropage/overview-header_2.png"
                media="(min-width: 820px)"
              />
              <source
                srcSet="/resources/imagesBundesliga/intropage/overview-header-sm.png"
                media="(min-width: 200px)"
              />
              <img
                className="banner__image"
                src="/resources/imagesBundesliga/intropage/overview_header.png"
                alt="Fc Milka banner"
              />
            </picture>
          </div>
          <div className="overview-header__content">
            <h2 className="overview-header__title">
              FC Milka & die Bundesliga
            </h2>
            <p className="overview-header__subtitle">
              Milka ist offizieller Partner der Bundesliga und der 2. Bundesliga
              sowie des Supercup. Auch für dich ein Volltreffer, denn es warten
              viele tolle Gewinnaktionen auf dich.
            </p>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  bannerState: state.banner,
  gameState: state.game
});

export default withTranslation()(connect(mapStateToProps, null)(Banner));
