import React, {useEffect, useState} from 'react';
import appGoogleTracking from '../../modules/googletracking';
import GLOBAL_CONFIG from '../../config/config'; 
import { NavLink } from 'react-router-dom';
import Aux from '../../hoc/Auxiliare';
import { isGlobusMicrosite, isPennyMicrosite, isWeltmeisterPromotion, is90minPromotion, isFanCenterMicrosite, isTicketsPromotion, isBuzzerPromotion, isTrikotPromotion, isDoppeltPromotion, isq2Promotion, isTeaser, isReihePromotion } from '../../modules/helpers';
import _LOCAL from "../../Models/LocalStorageKeys";
import displayTrikotNavigation from '../../containers/Campaign/Trikot/displayTrikotNavigation';
import DoppeltNavigation from '../../containers/Campaign/Doppelt/DoppeltNavigation';
import PromoPopup from '../../containers/Campaign/Sommer/component/Popup/PromoPopup';
/*
    Google tagging plan added on links using : appGoogleTracking.processEventCTA
*/

const addActiveOnNavigationLink = ()=>{
    const links = document.querySelectorAll(".header-menu-navigations a");
    links.forEach(link => {
        var location = window.location.href;
        if(link.href.toLowerCase() === location.toLowerCase()){
            link.classList.add("active");
        }
    });
}
const isMicroGlobus = () => {
    return isGlobusMicrosite();
}

const isQ2 = () =>{
    return isq2Promotion();
}

const isMicroFanCenter = () => {
    return isFanCenterMicrosite();
}

const isMicroPenny = () => {
    return isPennyMicrosite();
}

const isWeltmeister = () => {
    return isWeltmeisterPromotion();
}

const is90min = () => {
    return is90minPromotion();
}

const isTickets = () => {
    return isTicketsPromotion();
}

const isBuzzerGame = () => {
    return isBuzzerPromotion();
}
const currentCampaign = () => {
    let hostName = window.location.hostname,
        isProd = hostName.toLowerCase().indexOf("fcmilka") > -1 ? true : false;
    const currentCampaignDomaine = isProd ? "https://doppeltgewinnen.fcmilka.de" : "/doppeltgewinnen";
    return currentCampaignDomaine;
}


const isGlobus = () => {
    const pathnameLower = window.location.pathname.toLocaleLowerCase();
    return (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.globus.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingGlobus.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.endGlobus.toLowerCase()
    );
};
const isFancenter = () => {
    const pathnameLower = window.location.pathname.toLocaleLowerCase();
    return (
        pathnameLower === GLOBAL_CONFIG.Route.fanCenter.main.toLowerCase() ||
        pathnameLower === `${GLOBAL_CONFIG.Route.fanCenter.main.toLowerCase()}/` ||
        pathnameLower === GLOBAL_CONFIG.Route.fanCenter.tickets.favoriteClub.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.fanCenter.tickets.teamSelection.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.fanCenter.tickets.userDetails.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.fanCenter.tickets.confirmation.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.fanCenter.tickets.faq.faq.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.fanCenter.tickets.faq.faqjersey.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.fanCenter.ticket.errorTickets.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.fanCenter.jersey.teamSelection.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.fanCenter.jersey.sizeSelection.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.fanCenter.jersey.errorJersey.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.fanCenter.userDetails.jersey.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.fanCenter.confirmation.jersey.toLowerCase()
    );
};

const isRewe = () => {
    const pathnameLower = window.location.pathname.toLocaleLowerCase();
    return (
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.rewe.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.endRewe.toLowerCase() ||
        pathnameLower === GLOBAL_CONFIG.Route.buzzer.holdingRewe.toLowerCase()
    );
};

const displayGlobusNavigation = ()=> {
    let hostName = window.location.hostname,
        isProd = hostName.toLowerCase().indexOf("fcmilka") > -1 ? true : false;
    const baseDomaine = isProd ? "https://weltmeister.fcmilka.de" : "/weltmeister";
    return isGlobus() && (
        <ul>
            <li>
                <a href={`${GLOBAL_CONFIG.Route.buzzer.globus}`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Start" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">START</a>
            </li>
            <li>
                <a href={`${baseDomaine}${GLOBAL_CONFIG.Route.participation}`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Mitmachen" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">MITMACHEN</a>
            </li>
            <li>
                <a href={`${baseDomaine}${GLOBAL_CONFIG.Route.prize}`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Gewinne" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">GEWINNE</a>
            </li>
            <li>
                <a href={`${baseDomaine}${GLOBAL_CONFIG.Route.faq}`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="FAQ" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAQ</a>
            </li>
            <li>
                <NavLink to={GLOBAL_CONFIG.Route.fairplay} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Fairplay" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAIRPLAY</NavLink>
            </li>
            <li>
                <a href={`${baseDomaine}${GLOBAL_CONFIG.Route.product}`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="PRODUKTSEITE" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">PRODUKTSEITE</a>
            </li>
        </ul>
    );
};

const displayFanCenterNavigation = ()=> {
    let typeTickects = localStorage.getItem(_LOCAL.TicketType);
    let link='';
    if(typeTickects === 'CAT2' || typeTickects === 'VIP')link = 'tickets'
    else if(typeTickects === 'TrikotUnSigned' || typeTickects === 'TrikotSigned')link = 'trikots';
    return isFancenter() && (
        <ul>
            <li>
                <NavLink to={GLOBAL_CONFIG.Route.home} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Start" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">START</NavLink>
            </li>
            <li>
                <NavLink to={`${currentCampaign()}${GLOBAL_CONFIG.Route.prize}`} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Mitmachen" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">MITMACHEN</NavLink>
            </li>
            <li>
                <NavLink to={`${currentCampaign()}${GLOBAL_CONFIG.Route.prize}`}  exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Gewinne" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">GEWINNE</NavLink>
            </li>
            <li>
                <NavLink to={GLOBAL_CONFIG.Route.account} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="KONTO" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">KONTO</NavLink>
            </li>
            <li>
                {link === 'tickets' ?
                    <NavLink to={GLOBAL_CONFIG.Route.fanCenter.tickets.faq.faq} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="FAQ" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAQ</NavLink> :
                    <NavLink to={GLOBAL_CONFIG.Route.fanCenter.tickets.faq.faqjersey} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="FAQ" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAQ</NavLink>
                }
            </li>
            <li>
                <NavLink to={GLOBAL_CONFIG.Route.fairplay} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Fairplay" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAIRPLAY</NavLink>
            </li>
            <li>
                <NavLink to={`${currentCampaign()}${GLOBAL_CONFIG.Route.prize}`} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="PRODUKTSEITE" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">PRODUKTSEITE</NavLink>
            </li>
        </ul>
    );
};

const displayReweNavigation = ()=> {
    let hostName = window.location.hostname,
        isProd = hostName.toLowerCase().indexOf("fcmilka") > -1 ? true : false;
    const baseDomaine = isProd ? "https://90min.fcmilka.de" : "/90min";
    return isRewe() && (
            <ul>
                <li>
                    <a href={`${baseDomaine}${GLOBAL_CONFIG.Route.home}`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Start" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">START</a>
                </li>
                <li>
                    <a href={`${baseDomaine}${GLOBAL_CONFIG.Route.participation}`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Mitmachen" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">MITMACHEN</a>
                </li>
                <li>
                    <a href={`${baseDomaine}${GLOBAL_CONFIG.Route.prize}`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Gewinne" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">GEWINNE</a>
                </li>
                <li>
                    <a href={`${baseDomaine}${GLOBAL_CONFIG.Route.faq}`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="FAQ" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAQ</a>
                </li>
                <li>
                    <NavLink to={GLOBAL_CONFIG.Route.fairplay} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Fairplay" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAIRPLAY</NavLink>
                </li>
            </ul>
    );
};

const displayWeltmeisterNavigation = ()=> {
    let hostName = window.location.hostname,
        isProd = hostName.toLowerCase().indexOf("fcmilka") > -1 ? true : false;
    const baseDomaine = isProd ? "https://weltmeister.fcmilka.de" : "/weltmeister";
    return isWeltmeister() && (
            <ul>
                <li>
                    <a href={`${baseDomaine}${GLOBAL_CONFIG.Route.home}`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Start" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">START</a>
                </li>
                <li>
                    <a href={window.PROMOTION === "holding" ? `${baseDomaine}${GLOBAL_CONFIG.Route.participation}` : `${baseDomaine}${GLOBAL_CONFIG.Route.home}`+`#participate`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Mitmachen" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">MITMACHEN</a>
                </li>
                <li>
                    <a href={`${baseDomaine}${GLOBAL_CONFIG.Route.prize}`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Gewinne" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">GEWINNE</a>
                </li>
                <li>
                    <a href={`${baseDomaine}${GLOBAL_CONFIG.Route.faq}`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="FAQ" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAQ</a>
                </li>
                <li>
                    <a href={`https://fcmilka.de/fairplay`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Fairplay" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAIRPLAY</a>
                </li>
                <li>
                    <a href={`${baseDomaine}${GLOBAL_CONFIG.Route.product}`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="PRODUKTSEITE" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">PRODUKTSEITE</a>
                </li>
            </ul>
    );
};

const displayTicketsNavigation = ()=> {
    return isTickets() && (
        <ul>
            <li>
                
                <NavLink to={`${GLOBAL_CONFIG.Route.home}`} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="START" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">START</NavLink>
            </li>
            <li>
                <NavLink to={window.PROMOTION === "holding" ? `${GLOBAL_CONFIG.Route.participation}` : `${GLOBAL_CONFIG.Route.home}`+`?status=on`} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="MITMACHEN" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">MITMACHEN</NavLink>
            </li>
            <li>
                <NavLink to={`${GLOBAL_CONFIG.Route.prize}`} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="GEWINNE" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">GEWINNE</NavLink>
            </li>
            <li>
                <a href={'https://fcmilka.de/account'} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="KONTO" onClick={appGoogleTracking.processEventCTA} className="js-ham-close">KONTO</a>
            </li>
            <li>
                <NavLink to={`${GLOBAL_CONFIG.Route.faq}`} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="FAQ" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAQ</NavLink>
            </li>
            <li>
                <a href={`https://fcmilka.de/fairplay`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="FAIRPLAY" onClick={appGoogleTracking.processEventCTA} className="js-ham-close">FAIRPLAY</a>
            </li>
        </ul>
    );
};

const displayBuzzerNavigation = ()=> {
    let hostName = window.location.hostname,
        isProd = hostName.toLowerCase().indexOf("fcmilka") > -1 ? true : false;
    const baseDomaine = isProd ? "https://buzzer.fcmilka.de" : "/buzzer";
    return isBuzzerGame() && (
            <ul>
                <li>
                    <a href={`${baseDomaine}${GLOBAL_CONFIG.Route.home}`} data-event="burger_menu" data-category="Header" data-action="Select Menu" data-label="Start" onClick={appGoogleTracking.processEventCTA} className="js-ham-close">START</a>
                </li>
                <li>
                    <a href={window.PROMOTION === "holding" ? `${baseDomaine}${GLOBAL_CONFIG.Route.participation}` : `${baseDomaine}${GLOBAL_CONFIG.Route.home}`+`?status=on`} data-event="burger_menu" data-category="Header" data-action="Select Menu" data-label="Mitmachen" onClick={appGoogleTracking.processEventCTA} className="js-ham-close">MITMACHEN</a>
                </li>
                <li>
                    <a href={`${baseDomaine}${GLOBAL_CONFIG.Route.prize}`} data-event="burger_menu" data-category="Header" data-action="Select Menu" data-label="Gewinne" onClick={appGoogleTracking.processEventCTA} className="js-ham-close">GEWINNE</a>
                </li>
                <li>
                    <a href={'https://fcmilka.de/account'} data-event="burger_menu" data-category="Header" data-action="Select Menu" data-label="KONTO" onClick={appGoogleTracking.processEventCTA} className="js-ham-close">KONTO</a>
                </li>
                <li>
                    <a href={`${baseDomaine}${GLOBAL_CONFIG.Route.faq}`} data-event="burger_menu" data-category="Header" data-action="Select Menu" data-label="FAQ" onClick={appGoogleTracking.processEventCTA} className="js-ham-close">FAQ</a>
                </li>
                <li>
                    <a href={`https://fcmilka.de/fairplay`} data-event="burger_menu" data-category="Header" data-action="Select Menu" data-label="Fairplay" onClick={appGoogleTracking.processEventCTA} className="js-ham-close">FAIRPLAY</a>
                </li>
                <li>
                    <a href={`${baseDomaine}${GLOBAL_CONFIG.Route.product}`} data-event="burger_menu" data-category="Header" data-action="Select Menu" data-label="PRODUKTSEITE" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">PRODUKTSEITE</a>
                </li>
            </ul>
    );
};

const displayLinks = (props,handleModal) => {
    if(isReihePromotion()){
        return (<ul>
            <li>
                <NavLink to={GLOBAL_CONFIG.Route.home} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="START" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">START</NavLink>
            </li>
            <li>
                <a href={"https://reihe1.fcmilka.de"} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="MITMACHEN" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">MITMACHEN</a>
            </li>
            <li>
                <a href={"https://reihe1.fcmilka.de/"} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="GEWINNE" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">GEWINNE</a>
            </li>
            <li>
                <a href={'https://fcmilka.de/account'} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="KONTO" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">KONTO</a>
            </li>
            <li>
                <a href={GLOBAL_CONFIG.Route.reihe.faq} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="FAQ" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAQ</a>
            </li>
            <li>
                <a href={`https://fcmilka.de/fairplay`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Fairplay" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAIRPLAY</a>
            </li>
        </ul>)
    }
    
    if(isMicroGlobus()) {
        return (
            <ul>
                <li>
                    <NavLink to={GLOBAL_CONFIG.Route.home} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Start" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">START</NavLink>
                </li>
                <li>
                    <NavLink to={GLOBAL_CONFIG.Route.globusMicroSite.participation} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Mitmachen" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">MITMACHEN</NavLink>
                </li>
                <li>
                    <NavLink to={GLOBAL_CONFIG.Route.globusMicroSite.faq} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Faq" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAQ</NavLink>
                </li>
            </ul>
        );
    }
    if(isMicroFanCenter()) {
        return (
            <ul>
                <li>
                    <NavLink to={GLOBAL_CONFIG.Route.home} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Start" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">START</NavLink>
                </li>
                <li>
                    <NavLink to={GLOBAL_CONFIG.Route.participation} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Mitmachen" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">MITMACHEN</NavLink>
                </li>
                <li>
                    <NavLink to={GLOBAL_CONFIG.Route.prize} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Gewinne" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">GEWINNE</NavLink>
                </li>
                <li>
                    <NavLink to={GLOBAL_CONFIG.Route.account} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="KONTO" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">KONTO</NavLink>
                </li>
                <li>
                    <NavLink to={GLOBAL_CONFIG.Route.fanCenter.tickets.faq.faq} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="FAQ" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAQ</NavLink>
                </li>
                <li>
                    <NavLink to={GLOBAL_CONFIG.Route.fairplay} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Fairplay" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAIRPLAY</NavLink>
                </li>
                <li>
                    <NavLink to={GLOBAL_CONFIG.Route.product} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="PRODUKTSEITE" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">PRODUKTSEITE</NavLink>
                </li>
            </ul>
        );
    }
    if(isMicroPenny()) {
        return (
            <ul>
                <li>
                    <NavLink to={GLOBAL_CONFIG.Route.pennyMicrosite.main} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Start" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">START</NavLink>
                </li>
                <li>
                    <NavLink to={GLOBAL_CONFIG.Route.pennyMicrosite.participation} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Mitmachen" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">MITMACHEN</NavLink>
                </li>
                <li>
                    <NavLink to={GLOBAL_CONFIG.Route.pennyMicrosite.faq} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Faq" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAQ</NavLink>
                </li>
                <li>
                    <NavLink to={GLOBAL_CONFIG.Route.fairplay} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Fairplay" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAIRPLAY</NavLink>
                </li>
            </ul>
        );
    }
    if(isQ2()){
        return (
            <ul>
                <li>
                    <NavLink to={GLOBAL_CONFIG.Route.sommer.main} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="START" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">START</NavLink>
                </li>
                <li>
                    <a style={{cursor:'pointer'}} id='mit' exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Mitmachen" onClick={(event) => { appGoogleTracking.processEventCTANavLink(event); handleModal()}} className="js-ham-close">MITMACHEN</a>
                </li>
                <li>
                    <a href={'https://fcmilka.de/account'} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="KONTO" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">KONTO</a>
                </li>
                <li>
                    <NavLink to={GLOBAL_CONFIG.Route.sommer.faq} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Faq" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAQ</NavLink>
                </li>
                <li>
                <a href={`https://fcmilka.de/fairplay`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Fairplay" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAIRPLAY</a>
                </li>
            </ul>
        );
    }
    let hostName = window.location.hostname;
    const isProd = hostName.toLowerCase().indexOf("fcmilka") > -1 ? true : false,
        isProd90min = hostName.toLowerCase().indexOf("90min.fcmilka") > -1 ? true : false,
        isProdWeltmeister = hostName.toLowerCase().indexOf("weltmeister.fcmilka") > -1 ? true : false,
        isProdTickets = hostName.toLowerCase().indexOf("tickets.fcmilka") > -1 ? true : false,
        isProdBuzzer = hostName.toLowerCase().indexOf("buzzer.fcmilka") > -1 ? true : false,
        isProdTrikot = hostName.toLowerCase().indexOf("trikot.fcmilka") > -1 ? true : false,
        isProdGewinnen = hostName.toLowerCase().indexOf("doppeltgewinnen.fcmilka") > -1 ? true : false;

    const _CAMPAING = window.PROMOTION;
    if (_CAMPAING === "extended") {
        return (
            (isProd && !isProd90min) ? (
                <ul>
                    <li>
                        <NavLink to={GLOBAL_CONFIG.Route.fairplay} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Fairplay" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAIRPLAY</NavLink>
                    </li>
                    <li>
                        <a href={"https://90min.fcmilka.de/produkte"} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="FAQ" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">PRODUKTSEITE</a>
                    </li>
                </ul>
            ) : (
                <ul>
                    <li>
                    <NavLink to={GLOBAL_CONFIG.Route.fairplay} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Fairplay" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAIRPLAY</NavLink>
                </li>
                <li>
                    <NavLink to={GLOBAL_CONFIG.Route.product} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="PRODUKTSEITE" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">PRODUKTSEITE</NavLink>
                </li>
                </ul>
            )
        )
    }
    /* Menu to be display on fcmilka.de */
    const menuLinks = (
        <Aux>
            {
                (isProd && !isProdTickets) ? 
                    (
                        <ul>
                            <li>
                                <NavLink to={GLOBAL_CONFIG.Route.home} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="START" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">START</NavLink>
                            </li>
                            <li>
                                <a href={"https://reihe1.fcmilka.de/mitmachen"} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="MITMACHEN" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">MITMACHEN</a>
                            </li>
                            <li>
                                <a href={"https://reihe1.fcmilka.de/mitmachen"} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="GEWINNE" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">GEWINNE</a>
                            </li>
                            <li>
                                <a href={'https://fcmilka.de/account'} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="KONTO" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">KONTO</a>
                            </li>
                            <li>
                                <a href={"https://reihe1.fcmilka.de/faq"} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="FAQ" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAQ</a>
                            </li>
                            <li>
                            <a href={`https://fcmilka.de/fairplay`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Fairplay" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAIRPLAY</a>
                            </li>
                        </ul>
                    )
                :
                    (
                        <ul>
                            <li>
                                { isTicketsPromotion() ? <NavLink to={GLOBAL_CONFIG.Route.home} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Start" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">START</NavLink> : 
                                    <NavLink to={GLOBAL_CONFIG.Route.home} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Start" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">START</NavLink>}                                
                            </li>
                            <li>
                                {isTicketsPromotion() ? <NavLink to={GLOBAL_CONFIG.Route.participation} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Mitmachen" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">MITMACHEN</NavLink> :
                                    <NavLink to={GLOBAL_CONFIG.Route.participation} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Mitmachen" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">MITMACHEN</NavLink>}
                                
                            </li>
                            <li>
                                {isTicketsPromotion() ? <NavLink to={GLOBAL_CONFIG.Route.prize} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Gewinne" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">GEWINNE</NavLink> :
                                    <NavLink to={GLOBAL_CONFIG.Route.prize} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Gewinne" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">GEWINNE</NavLink>}
                            </li>
                            <li>
                                {isTicketsPromotion() ? <a href={'https://fcmilka.de/account'} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="KONTO" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">KONTO</a> :
                                    <NavLink to={GLOBAL_CONFIG.Route.account} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="KONTO" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">KONTO</NavLink>}
                            </li>
                            <li>
                                {isTicketsPromotion() ? <NavLink to={GLOBAL_CONFIG.Route.faq} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="FAQ" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAQ</NavLink> :
                                    <NavLink to={GLOBAL_CONFIG.Route.faq} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="FAQ" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAQ</NavLink>}
                            </li>
                            <li>
                                {isTicketsPromotion() ? <a href={`https://fcmilka.de/fairplay`} data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Fairplay" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAIRPLAY</a> :
                                    <NavLink to={GLOBAL_CONFIG.Route.fairplay} exact data-event="header_menu" data-category="Header" data-action="Select Menu" data-label="Fairplay" onClick={appGoogleTracking.processEventCTANavLink} className="js-ham-close">FAIRPLAY</NavLink>}
                                
                            </li>
                        </ul>
                    )
            }
        </Aux>
    );

    return displayReweNavigation() || displayWeltmeisterNavigation() || displayGlobusNavigation() || displayTicketsNavigation() || displayFanCenterNavigation() || displayBuzzerNavigation() || displayTrikotNavigation() || DoppeltNavigation() || menuLinks;
}

const Header = (props) => {
    const length = 36;
    const [position, setPosition] = useState(0);
    const [indice, setIndice] = useState(0);
    const numberOfElement = 3;
    const numberOfGroup = length / (numberOfElement * 2);
    const numberOfGroupIndice = 3;
    const logosPath = '/resources/bundesliga-logos/logos-2024/';

    const [openModal, setopenModal] = useState(false)

    function toggleModal() {
        setopenModal(!openModal)
    }
    function handleModal() {
        setopenModal(true);
        appGoogleTracking.dataLayerPush({
            'dataEvent': "join_now",
            'dataCategory': "Start Page",
            'dataAction': "Click on Join Now",
            'dataLabel': "GAME_SP_PART"
        });
    }

    const getLogos = (part) => {
        const startIndex = part === 'left' ? 0 : numberOfElement;

        let logoImages = [];
        let count = 0;
        const groupedImages = [];
        for(let i=startIndex; i<length; i++){
            const index = i + 1;
            logoImages.push({
                image: `${logosPath}logo-${index}.svg`
            });
            count++;
            if(count === numberOfElement){
                i = i + numberOfElement;
                count = 0;
                groupedImages.push(logoImages);
                logoImages = [];
            }
        }

        return (
            <div className='header-animated__logo-content'>
                { 
                    groupedImages.map((group, pos) => {
                        return (
                            <div key={[part, 'logos-grouped', `logos-grouped--${pos}`].join(' ')} className={['logos-grouped', `logos-grouped--${pos}`, pos === 0 ? 'active' : ''].join(' ')}>
                                {
                                    group.map((item, index) => (
                                        <img key={`logo-${pos}__${index}`} className={'logos-grouped__item'} src={item.image} alt='logo Bundesliga'/>
                                    ))
                                }
                            </div>
                        );
                    })
                }
            </div>
        );
    };

    const [leftContent] = useState(getLogos('left'));
    const [rightContent] = useState(getLogos('right'));

    
    useEffect(() => {
        let newPosition = position + 1;
        if(newPosition >= numberOfGroup){
            newPosition = 0;
        }
        setTimeout(() => {
            const previousActive = document.getElementsByClassName(`logos-grouped--${position}`);
            const newActive = document.getElementsByClassName(`logos-grouped--${newPosition}`);
            for(let i=0; previousActive && i<previousActive.length; i++){
                previousActive[i].classList.remove('active');
                previousActive[i].classList.add('not-active');
            }
            for(let i=0; newActive && i<newActive.length; i++){
                newActive[i].classList.add('active');
                newActive[i].classList.remove('not-active');
            }
            setPosition(newPosition);
        }, 5000);
    }, [position]);

    useEffect(() => {
        if (isReihePromotion() || isTeaser()){
            let newIndice = indice + 1;
            if (newIndice >= numberOfGroupIndice) {
                newIndice = 0;
            }
            setTimeout(() => {
                const previousActiveIndice = document.getElementsByClassName(`logocentral--${indice}`);
                previousActiveIndice[0].classList.remove('active');
                previousActiveIndice[0].classList.add('not-active');
                const newActiveIndice = document.getElementsByClassName(`logocentral--${newIndice}`);
                newActiveIndice[0].classList.add('active');
                newActiveIndice[0].classList.remove('not-active');
                setIndice(newIndice);
            }, 5000);
        }
    }, [indice]);


    useEffect(()=>{
        addActiveOnNavigationLink();
    }, []);

    useEffect(() => {
        // This effect will run on component mount
        const url = window.location.href;
        const mitElement = document.getElementById('mit');
        const path = new URL(url).pathname;
        console.log(path); // Output: "/sommer/mitmachen"
        const lastSegment = path.substring(path.lastIndexOf('/') + 1);
        console.log('lastSegment', lastSegment);
        /* if (lastSegment === 'mitmachen') {
            mitElement.classList.add('active');
        } */
    }, [window.location.href]);

    return (
        <Aux>
            <PromoPopup modal={openModal} toggleModal={() => toggleModal()}></PromoPopup>
        <header className='header-animated'> 
            <nav className="js-header header-md__container header-md--sticky header-md--opaque">
                <div className="header-logo">
                    <a href='https://fcmilka.de/' 
                        data-event={ isTickets() ? "go_to_FCMilka" : "go_to_milka"} 
                        data-category={ isTickets() ? "Header" : "Header"} 
                        data-action={ isTickets() ? "Go to Page" : "Go to Page"}  
                        data-label={ isTickets() ? "MAIN_SITE_ALLPAGE_NAVBAR" : "MILKA_ALLPAGE_NAVBAR"} 
                        onClick={appGoogleTracking.processEventCTANavLink}>
                    <picture className='milka-logo'>
                        <source srcSet="/resources/imagesBundesliga/weltmeister/header/fc-milka_logo.png" media="(min-width: 769px)" />
                        <img className="partner-logo" src="/resources/imagesBundesliga/buzzer_22/fc-milka-logo-sm.png" alt='FCMilka logo'/>
                    </picture>
                    </a>
                </div>
                {
                    isReihePromotion() ? 
                    (<div className='header-logo oreo'>
                        
                            <picture className='milka-logo'>
                                <source srcSet="/resources/imagesBundesliga/weltmeister/header/logo-oreo.png" media="(min-width: 769px)" />
                                <img className="partner-logo" src="/resources/imagesBundesliga/weltmeister/header/OREO-Shield_sm.png" alt='Oreo logo' />
                            </picture>
                        
                    </div>)
                    : null
                }
                <div className='header-animated__left'>
                    { leftContent }
                </div>

                {
                    isReihePromotion() || isTeaser() ? 
                            <div className="partner-logo__container">
                                <div className='logocentral--0 active fading'>
                                    <a href='https://www.bundesliga.com/de/bundesliga'
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        data-event="go_to_bundesliga"
                                        data-category="Header"
                                        data-action="Go to Page"
                                        data-label="ENG_ALLPAGE_NAVBAR"
                                        onClick={appGoogleTracking.processEventCTA}>
                                        <picture>
                                            <source srcSet="/resources/imagesBundesliga/header/bundesliga.svg" media="(min-width: 769px)" />
                                            <img className="partner-logo reihe-logo" src="/resources/imagesBundesliga/header/bundesliga.svg" alt='bl_bl2_logo' />
                                        </picture>
                                    </a>
                                </div>
                                <div className='logocentral--1 not-active fading'>
                                    <a href='https://www.bundesliga.com/de/bundesliga'
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        data-event="go_to_bundesliga"
                                        data-category="Header"
                                        data-action="Go to Page"
                                        data-label="ENG_ALLPAGE_NAVBAR"
                                        onClick={appGoogleTracking.processEventCTA}>
                                        <picture>
                                            <source srcSet="/resources/imagesBundesliga/header/bayern.svg" media="(min-width: 769px)" />
                                            <img className="partner-logo reihe-logo" src="/resources/imagesBundesliga/header/bayern.svg" alt='bl_bl2_logo' />
                                        </picture>
                                    </a>
                                </div>
                                <div className='logocentral--2 not-active fading'>
                                    <a href='https://www.bundesliga.com/de/bundesliga'
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        data-event="go_to_bundesliga"
                                        data-category="Header"
                                        data-action="Go to Page"
                                        data-label="ENG_ALLPAGE_NAVBAR"
                                        onClick={appGoogleTracking.processEventCTA}>
                                        <picture>
                                            <source srcSet="/resources/imagesBundesliga/header/bvb.svg" media="(min-width: 769px)" />
                                            <img className="partner-logo reihe-logo" src="/resources/imagesBundesliga/header/bvb.svg" alt='bl_bl2_logo' />
                                        </picture>
                                    </a>
                                </div>

                            </div>

                    :
                        <div className="partner-logo__container">
                            <a href='https://www.bundesliga.com/de/bundesliga'
                                target="_blank"
                                rel="noopener noreferrer"
                                data-event="go_to_bundesliga"
                                data-category="Header"
                                data-action="Go to Page"
                                data-label="ENG_ALLPAGE_NAVBAR"
                                onClick={appGoogleTracking.processEventCTA}>
                                <picture>
                                    <source srcSet="/resources/imagesBundesliga/header/bl_2bl_milka_logo.svg" media="(min-width: 769px)" />
                                    <img className="partner-logo" src="/resources/imagesBundesliga/header/bl_2bl_milka_logo.svg" alt='bl_bl2_logo' />
                                </picture>
                            </a>
                        </div>
                }
                
                                
                <div className='header-animated__right'>
                    { rightContent }
                </div>
                
                {
                    isWeltmeister() || isGlobus() || is90min() ? null : 
                    <div className="profile-logo__container">
                        <a href={'https://fcmilka.de/account'}  
                            data-event={ isTrikotPromotion() || isq2Promotion() || isTeaser() || isReihePromotion() ? "go_to_profile" : "user_profile"} 
                            data-category="Header"
                            data-action="Go to Page"
                            data-label="LOGIN_ALLPAGE_NAVBAR"
                            onClick={appGoogleTracking.processEventCTANavLink} 
                            className="js-ham-close">
                                <img  src="/resources/imagesBundesliga/header/profile-icon.png" alt="Profile Logo"/>
                        </a>
                    </div>
                }
                
                <div className="js-hamburger header__menu" id="hamburger-m">
                    <span className="menu-line"></span>
                    <span className="menu-line"></span> 
                    <span className="menu-line"></span>
                    <span className="menu-line"></span>
                </div>
                
            </nav>
        </header>
        <div className="header-menu js-ham-content">
            <div className="header-menu-navigations">
                <img className="js-ham-close header-menu-navigations__close" src="/resources/imagesBundesliga/buzzer_22/ham-close.png" alt="Hamburger Close"/>
                {displayLinks(props,handleModal)}
            </div>
        </div>
        </Aux>
    );
}

export default Header;