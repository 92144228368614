import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import TitleContent from "../../../components/Content/TitleContent/TitleContent";
import GLOBAL_CONFIG from "../../../config/config";
import appGoogleTracking from "../../../modules/googletracking";
import { mainLoaderToggle } from "../../../modules/helpers";
import { connect } from "react-redux";
import { setIsBannerShown } from "../../../store/banner";

class BuzzerTerms extends Component {
    componentDidMount() {
        const { t, setIsBannerShown } = this.props;
        setIsBannerShown(false);
        //Remove main loader
        mainLoaderToggle("hide");

        //Virtual page
        /*         appGoogleTracking.dataLayerPush({
                    customEvent: {
                        event: "virtual-page",
                        virtualUri: GLOBAL_CONFIG.Route.terms,
                        pageTitle: t("Meta.title"),
                    },
                }); */
    }

    componentWillUnmount() {
        //Show mainloader
        mainLoaderToggle("show");
    }

    displayFullVersion() {
        const { t } = this.props;
        return (
            <div className="buzzer-terms">
                <div className="terms-container">
                    {/* <TitleContent heading="Teilnahmebedingungen" /> */}
                    <div className="s-content-copy">
                        <div className="s-content-copy__item">
                            <p className="headings first">Impressum</p>
                            <p>
                                Mondelez Deutschland GmbH <br />
                                Konsul Smidt Str. 21 <br />
                                28217 Bremen
                            </p>
                            <p>
                                Telefon: 00800 83 00 00 36 (kostenfrei) <br />
                                Fax: <a href="fax: 0421 3770 8100"
                                    onClick={appGoogleTracking.processEventCTA}
                                    data-event="go_to_fax"
                                    data-category="Terms and Conditions Page"
                                    data-action="Click on Fax"
                                    data-label="ENG_TC_OTHER_">0421 3770 8100</a> <br />
                                E-Mail: <a href="mailto:Verbraucherservice@mdlz.com"
                                    onClick={appGoogleTracking.processEventCTA}
                                    data-event="go_to_email"
                                    data-category="Terms and Conditions Page"
                                    data-action="Click on Email"
                                    data-label="ENG_TC_OTHER_">Verbraucherservice@mdlz.com</a>
                            </p>
                            <p>
                                Sitz: Bremen, AG Bremen HRB 11373 <br />
                                Umsatzsteueridentifikationsnummer: DE 114397121 <br />
                                Geschäftsführer: Jan Kruise, Hartmut Wilkens, Elisabeth Hülsmann
                            </p>
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings">
                                Teilnahmebedingungen der Gewinnspielaktion „Reihe 1 ist deins“
                            </p>
                            <p>
                                I. Eine Teilnahme an dem Gewinnspiel „Reihe 1 ist deins“ ist ausschließlich nach
                                Maßgabe der nachfolgend aufgeführten Teilnahmebedingungen möglich. Mit der
                                Teilnahme erkennt der Teilnehmer diese Teilnahmebedingungen ausdrücklich an.
                                Bei einem Verstoß gegen diese Teilnahmebedingungen behält sich Mondelez
                                Deutschland das Recht vor, Personen vom Gewinnspiel auszuschließen und/ oder
                                Gewinne abzuerkennen und den Verstoß gegen Teilnahmebedingungen personalisiert
                                an den betreffenden Club zu melden.
                            </p>
                            <p>
                                Mit der Durchführung und Abwicklung des Gewinnspiels wurde die Baudek &
                                Schierhorn Shopper Marketing GmbH, Schellerdamm 16, 21079 Hamburg (Agentur)
                                sowie W-com GmbH, Bahnhofsallee 12, 61231 Bad Nauheim beauftragt.
                            </p>
                            <p>
                                Aus Gründen der besseren Lesbarkeit verwenden wir nachstehend einheitlich die
                                Bezeichnung Teilnehmer oder Gewinner. Es sind hiervon selbstverständlich alle
                                Geschlechter (m/w/d) umfasst.
                            </p>
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings sub">1. Veranstalter</p>
                            <p>Veranstalterin des Milka Gewinnspiels „Reihe 1 ist deins“ ist die</p>
                            <p>
                                Mondelez Deutschland GmbH <br />
                                Konsul Smidt Str. 21 <br />
                                28217 Bremen
                            </p>
                            <p>
                                (im Folgenden „Mondelez“ oder „Veranstalter“ genannt). Weitere Informationen
                                zu dem Veranstalter entnehmen Sie bitte den Impressums-Angaben oben.
                            </p>
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings sub">2. Teilnahmeberechtigung</p>
                            <p>
                                Die Teilnehmer an dieser Werbeaktion müssen natürliche Personen sein, einen
                                Wohnsitz in Deutschland haben, und mindestens 18 Jahre alt sein. Mitarbeiter
                                von Mondelez, der mit Mondelez verbundenen Unternehmen und Mitarbeiter von in
                                dieses Gewinnspiel involvierten Unternehmen sowie deren unmittelbare
                                Familienangehörige sind von der Teilnahme ausgeschlossen.
                            </p>
                            <p>
                                Pro gekauftem Produkt / Produktcode ist nur eine Teilnahme erlaubt.
                            </p>
                            <p>
                                Mehrfachteilnahmen sind erlaubt, jedoch kann pro Teilnehmer nur ein Sachpreis
                                (Stadion-Erlebnis oder Heimtrikot) gewonnen werden sowie nur ein Gewinn pro
                                Familie. Maximal 5 Teilnahmen pro Person/Tag erlaubt. Teilnahmen derselben
                                Person und Adresse mit einer anderen als der bei der ersten Teilnahme
                                angegebenen E-Mail-Adresse werden nicht berücksichtigt. Eine weitere Teilnahme
                                derselben natürlichen Person unter einer neuen/anderen E-Mail-Adresse als die
                                bei der ersten Teilnahme angegebene E-Mail-Adresse wird als Verstoß gegen diese
                                Teilnahmebedingungen angesehen. Sollte dem Teilnehmer aufgrund einer weiteren
                                Teilnahme bereits ein/mehrere Preise zuerkannt oder bereits übergeben worden
                                sein, kann der Veranstalter den Preis/die Preise aberkennen und auf Kosten der
                                Teilnehmer zurückverlangen.
                            </p>
                            <p>
                                Bitte den Original-Kaufbeleg zum Nachweis aufbewahren. Der Veranstalter
                                behält sich eine Überprüfung des Kaufbelegs nach seinem Ermessen vor. Nicht
                                teilnehmende Produkte auf dem Kaufbeleg unkenntlich machen.
                            </p>
                            <p>
                                Alle Einsendungen müssen direkt von der Person vorgenommen werden, die sich an
                                der Werbeaktion beteiligt. Masseneinsendungen durch gewerbliche Absender,
                                Verbrauchergruppen oder Dritte werden nicht akzeptiert. Unvollständige oder
                                unleserliche Einsendungen, Einsendungen durch oder über Dritte oder Syndikate,
                                Einsendungen durch Makros oder andere automatisierte Methoden (einschließlich
                                programmierbare Teilnahme-Systeme) sowie Einsendungen, die den Vorgaben dieser
                                Teilnahmebedingungen nicht vollumfänglich entsprechen, werden von der Teilnahme
                                ausgeschlossen und nicht berücksichtigt. Stellt sich heraus, dass ein
                                Teilnehmer einen oder mehrere Computer zur Umgehung dieser Bestimmungen nutzt,
                                beispielsweise durch den Einsatz von „Skripten“, die „Brute-Force“-Methode, die
                                Verschleierung seiner Identität durch Manipulation von IP-Adressen,
                                willkürliches Erraten von Codes oder sonstige automatisierte Mittel, die die
                                Anzahl seiner Einsendungen für diese Werbeaktion auf eine Weise erhöhen sollen,
                                die nicht mit dem Geist dieser Werbeaktion im Einklang steht, werden die
                                Einsendungen dieser Person von der Teilnahme ausgeschlossen und jeder
                                eventuelle Gewinn wird für nichtig erklärt und zurückgefordert. Dasselbe gilt
                                für Beiträge, die den Teilnahmevorgang manipulieren und/oder sonst in unfairer
                                und/oder unlauterer Weise versuchen, den Teilnahmevorgang zu beeinflussen
                            </p>
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings sub">3. Aufgabe & Durchführung; Gewinne</p>
                            <p className="headings sub small">3.1 Aufgabe & Durchführung</p>
                            <p>
                                Die Teilnahme erfolgt durch den Kauf von 1 Produkt von Milka und/oder OREO im
                                Zeitraum vom 29.07. – 31.10.2024 sowie durch Eingabe des Produktcodes (auf
                                der Produktverpackung), Angabe der Aktionsmarke, des Händlers/Kaufdatums
                                auf <a href="www.buzzer.fcmilka.de" target="_blank"
                                    onClick={appGoogleTracking.processEventCTA}
                                    data-event="go_to_buzzer"
                                    data-category="Terms and Conditions Page"
                                    data-action="Go to Page"
                                    data-label="BUZZER_TC_OTHER_">www.reihe1.fcmilka.de</a> bis zum 31.10.2024.
                            </p>
                            <p>
                                Mit der Teilnahme am Gewinnspiel erklärt sich der Teilnehmer zur
                                Gewinnbenachrichtigung per Mail einverstanden.
                            </p>
                            <p>
                                Ein Internetzugang, eine Online-Registrierung und eine gültige E-Mail-Adresse
                                sind erforderlich, um die zur Verfügung gestellten Gewinne abwickeln und
                                organisieren zu können und den Gewinnern ihre Gewinne auch übergeben zu
                                können. Die mit der Gewinnspielabwicklung beauftragte Agentur wird ggf. im
                                Bedarfsfall Gewinner kontaktieren.
                            </p>
                            <p>
                                Gewinnermittlung der Heimtrikots erfolgt während des Gewinnspielzeitraumes
                                per Zufallsgenerator.
                                Gewinnerziehung der Stadion-Erlebnisse unter allen Teilnehmern am Ende des
                                Gewinnspielzeitraumes.
                            </p>
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings sub">3.2 Gewinne</p>
                            <p>
                                Insgesamt gibt es folgende Gewinne:<br />
                                <strong>2x Stadion-Erlebnis</strong> für 11 Personen<br />
                                (11 Plätze in der 1. Reihe für je ein Spiel beim FC Bayern München und Borussia
                                Dortmund)<br />
                                inkl. An- und Abreise, einer Übernachtung, Verpflegung, attraktivem
                                Rahmenprogramm (Werte variieren je nach Club, Auswahl nach Verfügbarkeit)<br/>
                                <strong>1.000x 1 original Heimtrikot</strong> der Bundesliga/2. Bundesliga, Saison 2024/2025
                                (Werte variieren je nach Club, Auswahl nach Verfügbarkeit über Milka Fan-Center unter www.fcmilka.de)<br />
                                Kein Anspruch auf Clubauswahl und/oder Bundesliga oder 2. Bundesliga.
                            </p>
                            <p>
                                Die Gewinner der Heimtrikots erhalten voraussichtlich in 3 Gewinnerziehungen
                                (1. Gewinnerziehung voraussichtlich Anfang September 2024, 2. Gewinnerziehung
                                voraussichtlich Anfang Oktober 2024, 3. Gewinnerziehung am Ende des
                                Gewinnspieles voraussichtlich Anfang November 2024) einen Link zum Milka Fan-Center und können sich dort ein Heimtrikot der Bundesliga / 2. Bundesliga
                                (Werte variieren je nach Club, Auswahl nach Verfügbarkeit, kein Anspruch auf
                                eine bestimmte Clubauswahl) auswählen.<br />
                                Hierzu haben die Gewinner 25 Tage Zeit, <strong>ansonsten verfällt der Gewinn.</strong>
                            </p>
                            <p>
                                Bitte beachten: Bei den signierten Heimtrikots der Bundesliga handelt es sich
                                um Unikate, die von jedem Spieler persönlich unterschrieben werden. Für die
                                Unterschrift werden i.d.R. Stifte verwendet, die auch wasserfest sind.
                                Allerdings ist es so, dass bei nachträglich aufgetragener Farbe auf einer
                                Sporttextilie, wie es bei den Heimtrikots der Fall ist, keine Garantie dafür
                                gegeben werden kann, dass diese auch einer Wäsche standhält. Daher eignen
                                sich die Heimtrikots, die unterschrieben worden sind, nicht für eine Wäsche
                                und sind soweit die Unterschriften erhalten werden sollen als Sammelstück zu
                                behandeln und nicht als herkömmliches Sporttrikot. Der Veranstalter übernimmt
                                keine Gewährleistung für nach der Wäsche verlorengegangene / verschwundene
                                Unterschriften.
                            </p>
                            <p>
                                Der Begünstigte der Heimtrikots muss sich noch einmal explizit mit
                                vollständigem Namen, E-Mail-Adresse und Postadresse im FC Milka Fan-Center
                                anmelden.
                            </p>
                            <p>
                                Die Gewinner des Stadion-Erlebnisses werden von unserer Agentur kontaktiert.
                                Hierzu haben die Gewinner 10 Tage Zeit, ansonsten verfällt der Gewinn.
                                Die genaue Gewinnaussteuerung wird persönlich mit dem Gewinner abgestimmt.
                            </p>
                            <p>
                                <strong>
                                    Der Veranstalter ist nicht verpflichtet, den Gewinn zur Verfügung zu stellen
                                    und kann ihn durch einen anderen Gewinn mit gleichem oder höherem Wert
                                    ersetzen, sollte der Gewinn aus Gründen, die außerhalb der Verantwortung des
                                    Veranstalters liegen. Der Gewinner hat in diesem Fall auch keinen Anspruch
                                    auf eine Barauszahlung oder eine anderweitige Kompensation. Barauszahlung und
                                    Rechtsweg sind ausgeschlossen.
                                </strong>
                            </p>
                            <p>
                                Einsendungen, die als nicht mit diesen Bedingungen übereinstimmend betrachtet
                                werden, werden verworfen. Eine Teilnahme ist in diesen Fällen ausgeschlossen.

                                <ul className="disc">
                                    <li>
                                        Darüber hinaus gilt auch: In dem Fall, dass ein Gewinner entweder diese
                                        Teilnahmebedingungen nicht erfüllt,
                                    </li>
                                    <li>
                                        seine Kontaktdaten nicht vollständig oder unrichtig angibt
                                    </li>
                                    <li>
                                        den Preis wie hierin beschrieben aus irgendeinem, vom Gewinner zu
                                        vertretenden Grund, nicht in Anspruch nehmen kann oder möchte, hiervon
                                        gehen wir aus, wenn der Gewinner sich auf unsere Gewinnbenachrichtigung
                                        über die Teilnahme an einem Event-Gewinn nicht innerhalb von 10 Tagen
                                        zurückmeldet und die für die Organisation erforderlichen Daten und
                                        Dokumente innerhalb dieser Frist zur Verfügung stellt.
                                    </li>
                                    <li>
                                        oder den Heimtrikot-Gewinn nicht innerhalb von 25 Tagen nach Zustellung
                                        im FC Milka Fan-Center eingelöst wurde
                                    </li>
                                    <li>
                                        oder der Gewinn unter der von dem Gewinner angegebenen Adresse auch nach
                                        zweifachem Versuch binnen 6 Wochen nach dem ersten Zustellversuch nicht
                                        zustellbar ist,
                                    </li>
                                </ul>

                                verfällt der Gewinn und kann an einen anderen Teilnehmer übermittelt werden,
                                der unter denselben Bedingungen ermittelt wurde.
                            </p> 
                            <p>
                                Der Gewinn wird nicht in bar ausgezahlt und ist vom Umtausch ausgeschlossen.
                            </p>
                            <p>
                                Bitte beachte: Zum Zweck der öffentlichen Berichterstattung über die
                                Fußballspiele oder des jeweiligen Gewinn-Events können Bild- und
                                Bildtonaufnahmen von den Gewinnern sowie den Begleitpersonen von Mondelez, der
                                DFL, den mit ihnen nach § 15 f. AktG verbundenen Unternehmen, den Fußballclubs/
                                ihren jeweiligen Verbänden und sonst befugten Dritten (z.B. Presse, Rundfunk)
                                erstellt und veröffentlicht werden. Mehr Informationen dazu findest Du unten
                                unter II. Datenschutzhinweis.
                            </p>
                        </div>

                        <div className="s-content-copy__item">
                            <p className="headings sub">3.3 Zusätzlich verlost Mondelez weitere Gewinne in Abstimmung mit seinen Handelspartnern.</p>
                            <p>
                                Je nach Handelspartner variiert die Anzahl und die
                                Wertigkeit der Gewinne. Für teilnehmende Handelspartner gelten daher
                                zusätzlich zu den in diesen Teilnahmebedingungen zum Gewinnspiel „Reihe 1 ist 
                                deins“ und in den Unterlagen des jeweiligen Händlers ausgelobten Modalitäten 
                                zu der Promotion die folgenden Teilnahmebedingungen:
                            </p>
                            <p>
                                Die Verlosung der zusätzlichen Gewinne bei den Handelspartnern wird über eine
                                Anzeige in einem Werbemittel der Handelspartner angekündigt. Der jeweilige
                                Teilnahmezeitraum der Zusatzverlosung bestimmt sich nach der entsprechenden
                                Ankündigung des jeweiligen Händlers.    
                            </p>
                            <p>
                                Sofern im Teilnahmezeitraum der teilnehmenden Händler ein Produkt von Milka
                                und/oder OREO gekauft und der Produktcode eingegeben wurde, nimmt der
                                Teilnehmer automatisch an dem Gewinnspiel „Reihe 1 ist deins“ teil.
                            </p>
                            <p>
                                Die Ermittlung der Gewinner der Zusatzverlosungen bestimmt sich nach der
                                entsprechenden Ankündigung des jeweiligen Händlers sowie spätestens eine
                                Woche nach Ende der Promotion und unter der jeweiligen
                                Gewinnermittlungsmechanik, die in den Teilnahmebedingungen zur
                                Zusatzverlosung der jeweiligen Händler vorgesehen ist.
                            </p>
                            <p>
                                Die Gewinnbenachrichtigung erfolgt per E-Mail an die bei der Registrierung
                                (s. Punkt 3.1) angegebene E-Mail-Adresse innerhalb einer Woche nach der
                                Ziehung. Maximal ein Hauptgewinn pro Person. Der Teilnehmer erklärt sich zur
                                Gewinnbenachrichtigung von Mondelez einverstanden.
                            </p>
                            <p>
                                Die Gewinner der REWE-Zusatzverlosung „11x exklusives Familienerlebnis beim
                                BVB“ (11x 1 Familienerlebnis beim BVB für 4 Personen mit einem Platz als
                                Einlaufkind für ein Kind, inkl. An- und Abreise, eine Übernachtung für 4 
                                Personen und Verpflegung) werden am Ende der Aktion, am 02.09.2024, unter 
                                allen gültigen Teilnehmern ermittelt und per E-Mail informiert.
                            </p>
                            <p>
                                Die Teilnehmer erhalten nach Teilnahme eine E-Mail mit einer Anmeldung für die REWE-
                                Zusatzverlosung, die sie ausgefüllt und unterschrieben per E-Mail zurücksenden. Die 
                                Gewinner müssen für eine Teilnahme zunächst noch einmal, die bei der Teilnahme 
                                angegebenen Daten innerhalb einer Frist von 10 Tagen schriftlich bestätigen und ein 
                                Anmeldeformular ausfüllen. Sofern der Gewinner dies unterlässt, kann er den Event nicht 
                                in Anspruch nehmen. Sollte der Gewinner nicht unterzeichnen kann der Veranstalter den 
                                Gewinn ggf. nach eigenem Ermessen verfallen lassen oder an einen weiteren Gewinner, 
                                der unter denselben Bedingungen ermittelt wird, vergeben werden. Sofern der gezogene 
                                Gewinner die Teilnahmevoraussetzungen erfüllt, d.h. die bei der Teilnahme angegebenen 
                                Daten werden fristgemäß bestätigt und das Anmeldeformular liegt innerhalb der 
                                angegebenen Frist vor, wird sich die damit beauftragte Agentur bei dem Gewinner 
                                telefonisch melden, um weitere organisatorische Details zu dem Gewinn zu besprechen.
                            </p>
                            <p>
                                Die Gewinner der Zusatzverlosung „Gewinne deine Reise zur Königsklasse“ (je 2 x 2 
                                Tickets für ein Auswärtsspiel des FC Bayern München in der Königsklasse bei der Edeka 
                                Südbayern und je 2 x 2 Tickets für ein Auswärtsspiel der Borussia Dortmund in der 
                                Königsklasse bei der Edeka Hessenring, inkl. Flug, 2x Übernachtung und Verpflegung) 
                                werden am Ende der Aktion, am 16.09.2024, unter allen gültigen Teilnehmern ermittelt 
                                und per E-Mail von der vom Veranstalter mit der Ausführung des Events beauftragten 
                                Agentur kontaktiert. Sie müssen zunächst noch einmal die bei der Teilnahme 
                                angegebenen Daten innerhalb einer Frist von 10 Tagen schriftlich bestätigen und ein 
                                Anmeldeformular ausfüllen. Bei dem Anmeldeformular müssen die Namen der 
                                teilnehmenden Begleitpersonen der Gewinner mit eingetragen werden und jeder 
                                Teilnehmer muss dies unterschreiben. Sofern der Gewinner /die Teilnehmer dies 
                                unterlassen, können Sie den Gewinn nicht mehr in Anspruch nehmen und der 
                                Veranstalter kann den Gewinn nach eigenem Ermessen verfallen lassen oder an einen 
                                weiteren Teilnehmer, der unter denselben Bedingungen ermittelt wird, vergeben. Sofern 
                                der Gewinner die Teilnahmevoraussetzungen erfüllt, d.h. die bei der Teilnahme 
                                angegebenen Daten werden fristgemäß bestätigt, werden die Gewinner bei der 
                                Organisation der Veranstaltung durch das Agenturpersonal begleitet. 
                            </p>
                            <p>
                                Die Umsetzung wird während eines Auswärtsspiels in der Königsklasse am 5./6. 
                                November 2024, 26./27. November 2024, 10./11. Dezember 2024, 21./22. Januar 2025 
                                oder am 29. Januar 2025 erfolgen. Dies wird durch die für die Organisation 
                                verantwortliche Agentur D&K Sports Management GmbH (Gymelsdorfergasse 4/1, 2700 
                                Wiener Neustadt) in Abstimmung mit den Clubs nach Ziehung der Spielbegegnungen 
                                definiert. Der Gewinner hat seine Verfügbarkeit an allen genannten Terminen zu 
                                gewährleisten.
                            </p>

                            <p>
                                Die Gewinner der Edeka Zusatzverlosung im Weserpark (5 x 2 VIP-Tickets für ein 
                                Heimspiel des SV Werder der Saison 24/25, 10 x 1 original signiertes Heimtrikot des SV 
                                Werder Bremen der Saison 24/25, 1 x Meet & Greet mit einer Vereinslegende des SV 
                                Werder Bremen) werden am Ende der Aktion am 27.08.2024, unter allen gültigen 
                                Teilnehmern ermittelt und per E-Mail von der vom Veranstalter mit der Ausführung des 
                                Events beauftragten Agentur kontaktiert. 
                            </p>

                            <p>
                                Der Gewinner des Meet & Greet muss zunächst noch einmal die bei der Teilnahme 
                                angegebenen Daten innerhalb einer Frist von 10 Tagen schriftlich bestätigen und ein 
                                Anmeldeformular ausfüllen. Bei dem Anmeldeformular muss der Name der teilnehmenden 
                                Begleitperson des Gewinners mit eingetragen werden und jeder Teilnehmer muss dies 
                                unterschreiben. Sofern der Gewinner /die Teilnehmer dies unterlassen, können Sie den 
                                Gewinn nicht mehr in Anspruch nehmen und der Veranstalter kann den Gewinn nach eigenem 
                                Ermessen verfallen lassen oder an einen weiteren Teilnehmer, der unter denselben 
                                Bedingungen ermittelt wird, vergeben. Sofern der Gewinner die Teilnahmevoraussetzungen 
                                erfüllt, d.h. die bei der Teilnahme angegebenen Daten werden fristgemäß bestätigt, wird 
                                der Gewinner bei der Organisation der Veranstaltung durch das Agenturpersonal begleitet. 
                            </p>

                            <p>
                                Die persönliche Gewinnübergabe der genannten Gewinne wird im Edeka Center im Weserpark 
                                Bremen erfolgen. Der Termin der Gewinnübergabe wird den Gewinnern durch die beauftragte 
                                Agentur mitgeteilt. Hierfür wird zu einem späteren Zeitpunkt eine gesonderte 
                                Einwilligungserklärung der Teilnehmer zur Nutzung der Bild- und Bildtonaufnahmen und 
                                Fotos für die Nachberichterstattung durch Mondelez eingeholt. Es besteht kein Anspruch 
                                auf Veröffentlichung von Bild- und Bildtonaufnahmen, diese liegt im alleinigen Ermessen 
                                der beteiligten Unternehmen.  
                            </p>

                            <p>
                                Auch hier gilt: Der Veranstalter ist nicht verpflichtet, den Gewinn zur Verfügung zu 
                                stellen und kann ihn auch durch einen anderen, gleichwertigen Gewinn ersetzen. Der 
                                Gewinner hat in diesem Fall auch keinen Anspruch auf eine Barauszahlung oder eine 
                                anderweitige Kompensation.
                            </p>
                            <p>
                                Es gelten im Übrigen die allgemeinen Teilnahmebedingungen des Gewinnspiels „Reihe 1 
                                ist deins“ für die Zusatzverlosungen, sowie die jeweils auf den entsprechenden Websites 
                                und Anzeigen dargestellten Teilnahmebedingungen der Zusatzverlosungen.
                            </p>
                        </div>

                        <div className="s-content-copy__item">
                            <p className="headings sub">4. Gewährleistung, Haftung</p>
                            <p>
                                Für einen Mangel oder mehrere Mängel an einem Gewinn übernimmt Mondelez weder
                                eine Gewährleistung noch stehen dem Gewinner im Falle eines Mangels oder
                                mehrerer Mängel an dem Gewinn Umtausch- oder Rückgaberechte zu, es sei denn,
                                es liegt ein Mondelez Deutschland zurechenbarer Fall von Vorsatz oder
                                arglistiger Täuschung vor.
                            </p>
                            <p>
                                Der Veranstalter haftet auch nicht für Produkte oder Dienstleistungen
                                Dritter. Bei der Inanspruchnahme der Leistungen Dritter gelten deren
                                Allgemeine Geschäftsbedingungen.
                            </p>
                            <p>
                                Der Veranstalter haftet auch nicht im Falle einer Insolvenz des Dritten und
                                leistet keinen Ersatz im Falle einer in der Zukunft liegenden, derzeit nicht
                                absehbaren Eröffnung eines Insolvenzverfahrens über das Vermögen des Dritten.
                            </p>
                            <p>
                                Der Veranstalter übernimmt keine Verantwortung für verspätet eingehende,
                                unvollständige, inkorrekt eingesandte, schadhafte oder falsch zugestellte
                                Einsendungen, Ansprüche oder Mitteilungen, unabhängig davon, ob diese durch
                                Fehler, Unterlassung, Veränderung, Manipulation, Löschung, Diebstahl,
                                Zerstörung, Übertragungsunterbrechung, Kommunikationsausfall oder anderweitig
                                bedingt wurden.
                            </p>
                            <p>
                                Der Veranstalter haftet nicht für Schäden aufgrund von technischen Störungen,
                                für Verzögerungen oder Unterbrechungen von Übertragungen oder für Schäden,
                                die im Zusammenhang mit der Teilnahme an dem Gewinnspiel stehen, es sei denn
                                der Veranstalter handelt vorsätzlich oder grob fahrlässig. Hiervon unberührt
                                bleiben etwaige Ersatzansprüche aufgrund von Produkthaftung und der
                                Verletzung von Leben, Körper und Gesundheit sowie von wesentlichen
                                Vertragspflichten. Im Fall der Verletzung von wesentlichen Vertragspflichten,
                                d.h. solcher Pflichten, die die ordnungsgemäße Durchführung des Vertrages
                                erst ermöglichen und auf deren Erfüllung der Nutzer vertrauen darf, ist die
                                Haftung auf den vertragstypisch vorhersehbaren Schaden begrenzt.
                            </p>
                            <p>
                                Die Teilnehmer erkennen durch ihre Teilnahme an, dass die Beteiligung an dieser Werbeaktion auf alleiniges Risiko des Teilnehmers erfolgt. Der Veranstalter, seine Organmitglieder, Direktoren, Beschäftigten und Agenten geben, soweit rechtlich zulässig, keinerlei Garantien oder Zusicherungen über die Richtigkeit oder Vollständigkeit der Inhalte auf <a href="https://www.milka.com" target="_blank"
                                    onClick={appGoogleTracking.processEventCTA}
                                    data-event="go_to_milka"
                                    data-category="Terms and Conditions Page"
                                    data-action="Go to Page"
                                    data-label="MILKA_TC_OTHER_">www.milka.com</a> oder auf mit dieser Website verlinkten Seiten ab und übernehmen keine Haftung oder Verantwortung für:<br />
                                <ul>
                                    <li>
                                        a. Fehler, Irrtümer oder Ungenauigkeiten von Inhalten;
                                    </li>
                                    <li>b. Sachschäden jeglicher Art, die aus der Teilnahme an der Werbeaktion herrühren;</li>
                                    <li>c. Todesfälle oder Personenschäden, die sich als direkte Folge der Teilnahme an der Werbeaktion ergeben, sofern diese nicht durch zumindest Fahrlässigkeit von Mondelez International, seinen Organmitgliedern, Direktoren, Beschäftigten und Agenten verursacht werden;</li>
                                    <li>d. Unterbrechungen oder Abbrüche der Übertragung an oder von <a href="https://www.milka.com" target="_blank"
                                        onClick={appGoogleTracking.processEventCTA}
                                        data-event="go_to_milka"
                                        data-category="Terms and Conditions Page"
                                        data-action="Go to Page"
                                        data-label="MILKA_TC_OTHER_">www.milka.com;</a></li>
                                    <li>e. Softwarefehler, Viren usw., die an oder durch www.milka.com an einen Dritten übertragen werden, und/oder</li>
                                    <li>f. Verluste oder Schäden jeglicher Art, die sich aus der Nutzung geposteter, per E-Mail versandter, übertragener oder anderweitig über www.milka.com zur Verfügung gestellter Inhalte ergeben.</li>
                                </ul>
                            </p>
                            <p>
                                Gewinne können nicht gegen Bargeld oder sonstige Formen der Entschädigung
                                eingelöst werden, sofern nichts anderes angegeben wurde. Falls der Nutzer
                                einen Teil eines Gewinns aus irgendeinem Grund nicht innerhalb des vom
                                Veranstalter vorgegebenen Zeitrahmens einlöst, verfällt dieses Gewinnelement
                                für den Nutzer ersatzlos, und es wird keine Geldzahlung oder sonstige Form
                                von Entschädigung als Ersatz für dieses Element geliefert, soweit nichts
                                anderes vereinbart wurde.
                            </p>
                            <p>
                                Der Veranstalter ist nicht verpflichtet, den Gewinn zur Verfügung zu stellen
                                und kann ihn durch einen anderen Gewinn mit gleichem oder höherem Wert
                                ersetzen, sollte der Gewinn aus Gründen, die außerhalb der Verantwortung des
                                Veranstalters liegen. Der Gewinner hat in diesem Fall auch keinen Anspruch
                                auf eine Barauszahlung oder eine anderweitige Kompensation. Barauszahlung und
                                Rechtsweg sind ausgeschlossen.
                            </p>
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings sub">
                                5. Vorzeitige Beendigung des Gewinnspiels /Änderung der
                                Teilnahmebedingungen
                            </p>
                            <p>
                                Mondelez behält sich vor, dieses Gewinnspiel zu jedem Zeitpunkt ohne Vorankündigung und ohne Angabe von weiteren Gründen abzubrechen oder zu beenden, insbesondere bei Infizierung durch Computerviren, Netzwerkausfällen, Softwarefehlern, Manipulationen, unbefugten Eingriffen, Betrug, technischem Versagen oder Ursachen außerhalb der Kontrolle des Veranstalters, die die Verwaltung, Sicherheit, Fairness, Integrität oder ordnungsgemäße Durchführung dieser Werbeaktion stören oder beeinträchtigen.
                            </p>
                            <p>
                                Mondelez kann diese Bedingungen jederzeit und ohne weitere Benachrichtigungen ändern. .
                            </p>
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings sub">6. Sonstiges</p>
                            <p>
                                Mit erfolgreicher Registrierung gem. Ziffer 3.1 nimmt der Teilnehmer automatisch an dem Gewinnspiel teil und erklärt sich mit diesen Teilnahmebedingungen einverstanden. 
                            </p>
                            <p>
                                Sollten einzelne dieser Teilnahmebedingungen ungültig sein oder werden, bleibt die Gültigkeit der übrigen Teilnahmebedingungen hiervon unberührt. Eine unwirksame Bestimmung ist durch eine solche zu ersetzen, die rechtlich möglich ist und der unwirksamen inhaltlich am nächsten kommt. Entsprechendes gilt für eventuelle Regelungslücken.
                            </p>
                            <p>
                                Diese Teilnahmebedingungen unterliegen in Deutschland einschlägigem deutschem Recht, wobei alle Streitigkeiten bezüglich der Auslegung dieser Teilnahmebedingungen der ausschließlichen Zuständigkeit der deutschen Gerichte unterliegen.
                            </p>
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings sub">7. Angaben zu EU Streitschlichtungsverfahren</p>
                            <p>
                                Gemäß § 36 Verbraucherstreitbeilegungsgesetz (VSBG) weisen wir darauf hin, dass wir nicht verpflichtet und nicht bereit sind, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen. Sie können sich aber jederzeit an unseren Verbraucherservice wenden.
                            </p>
                            <p>

                                Informationen zur Online-Streitbeilegung:Die EU-Kommission stellt unter folgendem Link eine Internetplattform zur Online-Beilegung von Streitigkeiten (sog. „OS-Plattform“) bereit, die als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten betreffend vertragliche Verpflichtungen, die aus Online-Kaufverträgen erwachsen, dient: https://ec.europa.eu/consumers/odr/main/?event=main.home.howitworks
                            </p>
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings sub">8. Abstandserklärung</p>
                            <p>
                                Das Gewinnspiel steht in keiner Verbindung zu Facebook und Instagram und wird in keiner Weise von Facebook gesponsert, unterstützt oder organisiert. Für keinen der im Rahmen dieser Aktion veröffentlichten Inhalte ist Facebook oder Instagram verantwortlich.                            </p>
                            <p>
                                Der Empfänger der vom Teilnehmer bereitgestellten Informationen ist nicht Facebook oder Instagram, sondern Mondelez Deutschland. Mondelez Deutschland stellt Facebook oder Instagram von berechtigten Ansprüchen Dritter im Zusammenhang mit diesem Gewinnspiel frei. Sämtliche Fragen, Kommentare oder Beschwerden zur Aktion sind nicht an Facebook oder Instagram zu richten, sondern an Mondelez Deutschland unter verbraucherservice@mdlz.com                            </p>
                        </div>
                        <div className="s-content-copy__item">
                            <p className="headings">II. Datenschutzhinweise</p>
                            <p>
                                Verantwortliche Stelle für die Verarbeitung Ihrer personenbezogenen Daten im Rahmen dieses Gewinnspiels ist die Mondelez Deutschland GmbH, Konsul-Smidt-Str. 21, D-28217 Bremen (Mondelez Deutschland). Mit der Durchführung des Gewinnspiels sind die folgenden Agenturen beauftragt: Consultix GmbH, Wachtstr. 17-24 28195 Bremen Germany, Baudek & Schierhorn Shopper Marketing GmbH, Schellerdamm 16, 21079 Hamburg, W-com GmbH, Bahnhofsallee 12, 61231 Bad Nauheim, und die bürozwei GmbH & Co KG, Theodor-Heuss-Allee 21 28215 Bremen auf einem Server in Deutschland. Die Consultix GmbH, Wachtstr. 17-24 28195 Bremen Germany, Baudek & Schierhorn Shopper Marketing GmbH, Schellerdamm 16, 21079 Hamburg, W-com GmbH, Bahnhofsallee 12, 61231 Bad Nauheim und die bürozwei GmbH & Co KG, Theodor-Heuss-Allee 21 28215 Bremen unterliegen sämtlichen anwendbaren Datenschutzgesetzen</p>.
                            <p>Die genannten Agenturen sind im Rahmen einer Auftragsverarbeitung für uns tätig und an die Anforderungen entsprechender Verträge gebunden. </p>
                            <p>Eine Weitergabe Ihrer personenbezogenen Daten an in diesem Datenschutzhinweis nicht genannte Dritte erfolgt nicht.</p>
                            <p>Wir speichern personenbezogene Daten von Ihnen nach Maßgabe der rechtlichen Vorschriften und ausschließlich zum Zweck der Abwicklung dieses Gewinnspiels (Rechtsgrundlage: Art. 6 Abs. 1 Buchst. b) der Datenschutz-Grundverordnung EU 2016/679, DS-GVO).</p>
                            <p>Es werden die E-Mailadresse, der Name, die Telefonnummer und die Adressdaten der Teilnehmer, sowie das Geburtsdatum von den Gewinnern erfasst und ausschließlich zur Verifizierung der Teilnahme/ Gewinner gespeichert. </p>

                            <p>Zum Zweck der Nachverfolgung bei Verstößen gegen die Teilnahmebedingungen und gegen AGBs des betreffenden Clubs in Form einer missbräuchlichen Verwendung bzw. unzulässigen Weiterverkaufsangeboten von Tickets (s. 3.2.4) können die relevanten personenbezogenen Daten an den anfragenden Club weitergegeben werden. Die Daten werden im Einzelfall bei Nachweis entsprechender Verdachtsmomente verschlüsselt übermittelt. Die Rechtsgrundlage für die Weitergabe stellt das berechtigte Interesse der jeweiligen Unternehmen dar (Art. 6 Abs. 1 S. 1 Buchstabe f der DSGVO). </p>

                            <p>Zum Zweck der öffentlichen Berichterstattung über die Fußballspiele oder der jeweiligen Veranstaltung können Bild- und Bildtonaufnahmen von den Gewinnern sowie den Begleitpersonen von Mondelez, der DFL, den mit ihnen nach § 15 f. AktG verbundenen Unternehmen, den Fußballclubs/ ihren jeweiligen Verbänden und sonst befugten Dritten (z.B. Presse, Rundfunk) verarbeitet, verwertet und veröffentlicht werden. Sofern Mondelez  Bild- und Bildtonaufnahmen und Fotos der Veranstaltung für die Nachberichterstattung für rein interne Zwecke sowie zu Werbezwecken nutzen möchte, holt Mondelez hierzu eine gesonderte Einwilligungserklärung der Teilnehmer ein. Die Rechtsgrundlage für die benannten Veröffentlichungen/ Nutzungen stellt das berechtigte Interesse der jeweiligen Unternehmen dar (Art. 6 Abs. 1 S. 1 Buchstabe f der DSGVO). Bei weitergehenden Nutzungen, die nicht von einem berechtigten Interesse abgedeckt sind, behält sich Mondelez vor, bei den Gewinnern, ihren Begleitpersonen sowie den jeweiligen Erziehungsberechtigten eine entsprechende Einwilligung (Art. 6 Abs. 1 S. 1 Buchstabe a der DSGVO) anzufragen.</p>

                            <p>Sie können der Verarbeitung Ihrer personenbezogenen Daten jederzeit ohne Angabe von Gründen widersprechen. Hierzu genügt eine formlose E-Mail an verbraucherservice@mdlz.com. Im Falle eines Widerspruchs werden Ihre personenbezogenen Daten selbstverständlich gelöscht, und eine Teilnahme an dem Gewinnspiel ist nicht mehr möglich.  </p>
                            <p>Soweit keine gesetzlichen Aufbewahrungspflichten bestehen, erfolgt eine umgehende Löschung der Daten, nachdem das Gewinnspiel abgewickelt ist. Eine umgehende Löschung erfolgt bezüglich der Daten der Nichtgewinner. Gewinnerdaten sind aus gesetzlichen und buchhalterischen Gründen nach Maßgabe der §§ 257 HGB, 147 AO, 14b UStG aufzubewahren. Für diese Speicherung der Gewinnerdaten ist die Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit. c) DS-GVO, da die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der Mondelez Deutschland unterliegt.   </p>

                            <p>Ihre Rechte im Zusammenhang mit personenbezogenen Daten:<br />

                                Sie sind unter anderem berechtigt (i) Auskunft darüber zu erhalten, ob und welche personenbezogenen Daten wir über Sie gespeichert haben und Kopien dieser Daten zu erhalten, (ii) die Berichtigung, Ergänzung oder das Löschen Ihrer personenbezogenen Daten, die falsch sind oder nicht rechtskonform verarbeitet werden, zu verlangen, (iii) von uns zu verlangen, die Verarbeitung Ihrer personenbezogenen Daten einzuschränken, und (iv) der Verarbeitung Ihrer personenbezogenen Daten zu widersprechen, (v) Datenübertragbarkeit zu verlangen, (vi) ihre Einwilligung in die Datenverarbeitung jederzeit mit Wirkung für die Zukunft zu widerrufen (vii) in Deutschland bei der deutschen (für weitere Informationen, siehe <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank" onClick={appGoogleTracking.processEventCTA}
                                    data-event="go_to_bund"
                                    data-category="Terms and Conditions Page"
                                    data-action="Go to Page"
                                    data-label="ENG_TC_OTHER_">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a> Beschwerde zu erheben.</p>


                            <p>Unsere Kontaktdaten:Sollten Sie zu der Verarbeitung Ihrer personenbezogenen Daten Fragen oder Anliegen haben, wenden Sie sich bitte an uns:Mondelez Deutschland GmbH, Konsul Smidt Str. 21, 28217 Bremen, DeutschlandE-Mail: <a href="mailto:Verbraucherservice@mdlz.com" onClick={appGoogleTracking.processEventCTA}
                                data-event="go_to_email"
                                data-category="Terms and Conditions Page"
                                data-action="Click on Email"
                                data-label="ENG_TC_OTHER_">Verbraucherservice@mdlz.com</a> Unseren Datenschutzbeauftragten in Deutschland können Sie postalisch unter: Mondelez Deutschland Services GmbH & Co. KG, zu Händen des Datenschutzbeauftragten Herrn Uwe Struckmeier, Konsul Smidt Str. 21, 28217 Bremen, Deutschlandoder per E-Mail unter: <a href="mailto:Datenschutz@mdlz.com" onClick={appGoogleTracking.processEventCTA}
                                    data-event="go_to_email"
                                    data-category="Terms and Conditions Page"
                                    data-action="Click on Email"
                                    data-label="ENG_TC_OTHER_">Datenschutz@mdlz.com</a> erreichen.Bitte lesen Sie unsere im Übrigen gültigen ausführlichen Datenschutzhinweise für die Datenverarbeitung im Rahmen der Nutzung dieser Webseite unterDE:<a href="https://eu.mondelezinternational.com/privacy-notice?sc_lang=de-de&siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D." target="_blank" onClick={appGoogleTracking.processEventCTA}
                                        data-event="go_to_mondelez"
                                        data-category="Terms and Conditions Page"
                                        data-action="Go to Page"
                                        data-label="MILKA_TC_OTHER_">https://eu.mondelezinternational.com/privacy-notice?sc_lang=de-de&siteId=7GTws0jSEtgtqGQHH57lZw%3D%3D.</a></p>

                            <p>Die Webseiten unter www.facebook.com und www.instagram.com und die auf dieser Seite vorgehaltenen Dienste werden angeboten von der Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland. Es kann nicht ausgeschlossen werden, dass Facebook die hochgeladenen Daten selbstständig speichert und verarbeitet.</p>

                            <p>Wir haben keinen Einfluss auf den Umfang der Erhebung und die weitere Verwendung dieser Daten durch Facebook. Informationen des Drittanbieters zum Datenschutz können Sie der nachfolgenden Webseite von Facebook und Instagram entnehmen:</p>
                            <p>https://www.facebook.com/about/privacy</p>
                            <p>https://help.instagram.com/519522125107875</p>
                            <p>Es ist nicht ausgeschlossen, dass durch Facebook Ihre Daten auch in an die Muttergesellschaft der Facebook Ltd., die Facebook Inc. mit Sitz in den USA weitergeleitet werden. </p>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return this.displayFullVersion();
    }
}

const mapStateToProps = (state) => ({
    bannerState: state.banner,
});

const mapDispatchToProps = {
    setIsBannerShown,
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(BuzzerTerms));
