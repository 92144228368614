import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import { mainLoaderToggle } from '../../../modules/helpers'
import TitleLogo from '../../../components/Content/TitleLogo/TitleLogo'
import Footersocial from '../../../components/Layout/FooterContent/FooterSocial'
import GLOBAL_CONFIG from '../../../config/config'
import JerseyNavigation from '../ChooseJersey/JerseyNavigation'
import _TICKET from '../../../Models/TicketImages'
import _LOCAL from '../../../Models/LocalStorageKeys'
import _SCROLL_PAGE from '../../../modules/ScrollPage'
import _STATUS from '../../../Models/ParticipationSteps'
import _USER_TYPES from '../../../Models/UserTypes'
import _TICKET_TYPES from '../../../Models/TicketTypes'
import { withRouter } from 'react-router-dom'
import Aux from '../../../hoc/Auxiliare'
import FanCenterSCBanner from '../Banner/FanCenterSCBanner'
import SectionTextButton from '../../../components/Content/SectionTextButton/SectionTextButton'

class Confirmation extends Component {
  constructor() {
    super()

    this.state = {
      CovidInfo: false,
      WinnerName: 'Winner Name',
      MatchDate: 'Sa. 01.01.2021',
      MatchLocation: 'Match Location',
      HomeTeam: 'Home Team',
      AwayTeam: 'Away Team',
      UserType: '',
      Gift: _TICKET.STD,
      GiftCategory: localStorage.getItem(_LOCAL.GiftCategory),
      TicketChoice: '1',
      TypeTicket: "",
    }

    this.HeadingSection = this.HeadingSection.bind(this)
  }

  componentDidMount() {
    let UserDetails = JSON.parse(localStorage.getItem(_LOCAL.UserDetails))

    if (!!UserDetails) {
      this.setState({
        UserType: UserDetails.UserType,
        TicketChoice: UserDetails.TicketChoice,
        Gift: _TICKET[localStorage.getItem(_LOCAL.GiftCategory)] || _TICKET.STD,
      })

      if (!!UserDetails.Match) {
        this.setState({
          MatchDate: UserDetails.Match.date,
          MatchLocation: UserDetails.Match.location,
          HomeTeam: UserDetails.Match.home,
          AwayTeam: UserDetails.Match.away,
          WinnerName: UserDetails.Name,
        })
      }
    }

    _SCROLL_PAGE.ScrollToTop();
    mainLoaderToggle('hide')
  }

  componentWillMount() {
    this.setState({TypeTicket: localStorage.getItem(_LOCAL.TicketType) || _TICKET_TYPES.CAT2});
    if (localStorage.getItem(_LOCAL.UserDetails) === null) {
      // this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.codeEntry.user)
    }
  }

  componentWillUnmount() {
    setTimeout(() => {
      localStorage.clear()
    }, 15000);
    mainLoaderToggle('show')
  }

  render() {
    return (
      <Aux>
        <FanCenterSCBanner category={(this.state.TypeTicket === _TICKET_TYPES.CAT2 ? "-confirmation" : "-confirmation")}/>
        <div className="buzzer-jersey lottery" id="confirmation">
          {this.HeadingSection()}
          {this.FooterSection()}
          <Footersocial />
        </div>
      </Aux>
    )
  }

  HeadingSection() {
    const Gift = this.state.Gift ? this.state.Gift : _TICKET.CAT2
    const desktopImage = typeof Gift === 'string' ? Gift : Gift.desktop
    const mobileImage = typeof Gift === 'string' ? Gift : Gift.mobile
    let ticketImage = {
      desktop: desktopImage,
      mobile: mobileImage,
    }

    return (
      <div className="spaceTile-intropage">
        <div className="campaign-heading">
          <div className='section-heading'>
          <SectionTextButton/>

          </div>
        </div>
      </div>
    )
  }

  FooterSection() {
    return (
      <div className="s-content-end">
        <div className="end-element">
          <div className="end-element__item">
            <img src="/resources/imagesBundesliga/buzzer/end/fancenter-23-24/box-1.png" />
            <div className="end-element__copy">
              <h3 className="end-element__copy__title">fc milka mitglied werden</h3>
              <p>
                Jetzt kostenlos registrieren und viele Vorteile genießen. Sei
                dabei und melde dich an.
              </p>
              <a href="https://fcmilka.de/account" target="_blank">
                <button className="btn__container btn--secondary-lilac">
                  {' '}
                  REGISTRIEREN{' '}
                </button>
              </a>
            </div>
          </div>

          <div className="end-element__item">
            <img src="/resources/imagesBundesliga/buzzer/end/fancenter-23-24/box-2.png" />
            <div className="end-element__copy">
              <h3 className="end-element__copy__title">nervennahrung nötig?</h3>
              <p>
                Die Bundesliga ist spannend wie eh und je – versüße dir die
                aufregenden Spiele mit zarter Milka Schokolade.
              </p>
              <a href="https://www.milka.de/shop" target="_blank">
                <button className="btn__container btn--secondary-lilac">
                  {' '}
                  SCHOKI SHOPPEN{' '}
                </button>
              </a>
            </div>
          </div>

          <div className="end-element__item">
            <img src="/resources/imagesBundesliga/buzzer/end/fancenter-23-24/box-3.png" />
            <div className="end-element__copy">
              <h3 className="end-element__copy__title">neues von milka</h3>
              <p>
                Verpasse keine Neuigkeit mehr: ob tolle Aktionen, zarte
                Produktnews oder Infos zum Milka Engagement.
              </p>
              <a href="https://www.milka.de/neues" target="_blank">
                <button className="btn__container btn--secondary-lilac">
                  {' '}
                  NEUES ENTDECKEN{' '}
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  ScrollCovidInfo(e) {
    this.setState({
      CovidInfo: true,
    })

    _SCROLL_PAGE.ScrollSection(document.getElementById('Covid-Details'))
  }
}

export default withRouter(Confirmation)
