import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { mainLoaderToggle } from '../../../../modules/helpers';
import NavigationIntropage from '../../../../components/Content/NavigationIntropage/NavigationIntropage';
import TitleContent from '../../../../components/Content/TitleContent/TitleContent';
import TitleLogo from '../../../../components/Content/TitleLogo/TitleLogo';
import Footersocial from '../../../../components/Layout/FooterContent/FooterSocial';
import appGoogleTracking from '../../../../modules/googletracking';
import GLOBAL_CONFIG from '../../../../config/config';
import _STATUS from '../../../../Models/ParticipationSteps';
import _SCROLL_PAGE from '../../../../modules/ScrollPage';
import _LOCAL from '../../../../Models/LocalStorageKeys';
import SectionTextButton from '../../../../components/Content/SectionTextButton/SectionTextButton';
import FanCenterSCBanner from "../../Banner/FanCenterSCBanner";
import _TICKET_TYPES from "../../../../Models/TicketTypes";

class JerseyConfirmation extends Component {
    constructor() {
        super();
        this.state = {
            WinnerName: "[Winner Name]",
            TypeTicket: ""
        }
        this.setWinnerName = this.setWinnerName.bind(this);
    }

    componentDidMount() {
        mainLoaderToggle('hide');
        // ValidateForm.validateField('frm_insertCode'); 
        appGoogleTracking.dataLayerPush({
            'customEvent': {
                'event': 'virtual-page',
                'virtualUri': GLOBAL_CONFIG.Route.thankyouFanCenter,
                'pageTitle': 'confirmation page'
            }
        });
        _SCROLL_PAGE.ScrollToTop();

        this.setWinnerName().then(()=>{
            sessionStorage.clear();
        });
    }

    async setWinnerName(){
        let UserDetails = JSON.parse(localStorage.getItem(_LOCAL.UserDetails));
        if(UserDetails){
            this.setState({
                WinnerName : UserDetails.Name
            });
        }
    }

    componentWillUnmount() {
        this.setState({TypeTicket: localStorage.getItem(_LOCAL.TicketType) });
        // localStorage.clear()
        setTimeout(() => {
            localStorage.clear()
          }, 15000);
        mainLoaderToggle('show');
    }


    render() {
        return (
            <div className="fanCenter jersey-confirmation">
                  <FanCenterSCBanner category={(this.state.TypeTicket === _TICKET_TYPES.TRICKOTUNISIGNED ? "-jersey-confirmation" : "-jersey-confirmation")}/>
                <div className="account">

                    <div className="space-title-jersey" id="fancenter-confirmation">
                        {/* <TitleContent
                            heading={`"Deine Eingabe war erfolgreich, <br /> ${this.state.WinnerName}!`}
                            subheading={null}
                            paragraph="Dein ausgewähltes Trikot wird dir in Kürze zugeschickt. Wir wünschen dir viel Spaß damit!"
                        /> */}
                        <SectionTextButton isJersey={true}/>
                    </div>
{/*                     <picture>
                        <source srcSet="/resources/imagesBundesliga/fanCenter/gewinne.png" media="(min-width: 1024px)" />
                        <source srcSet="/resources/imagesBundesliga/fanCenter/gewinne.png" media="(min-width: 481px)" />
                        <source srcSet="/resources/imagesBundesliga/fanCenter/gewinne-sm.png" media="(min-width: 200px)" />
                        <img className="image-center image-space" src="/resources/imagesBundesliga/fanCenter/gewinne.png" alt="Milka product" />
                    </picture> */}
                </div>
{/*                 <div className="fancenter-thankyou">
                    <div className="breakline"> <hr></hr></div>
                </div>
                <div className="fancenter-new">
                    <p> Du freust dich und möchtest gerne Danke sagen? Dann besuch doch gleich den Milka Shop und überrasche deinen Freund oder deine Freundin mit einem tollen personalisierbaren Geschenk von Milka. </p>
                    <picture>
                        <source srcSet="/resources/imagesBundesliga/fanCenter/product.png" media="(min-width: 1024px)" />
                        <source srcSet="/resources/imagesBundesliga/fanCenter/product.png" media="(min-width: 481px)" />
                        <source srcSet="/resources/imagesBundesliga/fanCenter/product.png" media="(min-width: 200px)" />
                        <img className="image-center image-space" src="/resources/imagesBundesliga/fanCenter/product.png" alt="Milka product" />
                    </picture>
                    <div className="fan-center-button fan-center-btn-thankYou">
                        <a href="https://www.fcmilka.de/alle-aktionen" target="_blank" rel="noopener noreferrer" data-event="button-click" data-category="Click Action" data-action="See more product" data-label="end" onClick={appGoogleTracking.processEventCTA}>
                            <button className="btn__container btn--red">
                                ZUM MILKA SHOP
                            </button>
                        </a>
                    </div>
                </div> */}

                <div className="s-content-end">
                    <div className="end-element">
                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/buzzer/end/fancenter-23-24/box-1.png" />
                            <div className="end-element__copy">
                                    <h3 className='end-element__copy__title'>FC Milka Mitglied werden</h3>
                                    <p>Jetzt kostenlos registrieren und viele Vorteile genießen. Sei dabei und melde dich an.</p>
                                <a href="https://fcmilka.de/account" target="_blank">
                                    <button className="btn__container btn--secondary-lilac"> REGISTRIEREN </button>
                                </a>
                            </div>
                        </div>


                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/buzzer/end/fancenter-23-24/box-2.png" />
                            <div className="end-element__copy">
                                    <h3>Nervennahrung nötig?</h3>
                                    <p>
                                        Die Bundesliga ist spannend wie eh und je – versüße dir die aufregenden Spiele mit zarter Milka Schokolade.
                                    </p>
                                <a href="https://www.milka.de/shop" target="_blank">
                                    <button className="btn__container btn--secondary-lilac"> SCHOKI SHOPPEN </button>
                                </a>
                            </div>
                        </div>


                        <div className="end-element__item">
                            <img src="/resources/imagesBundesliga/buzzer/end/fancenter-23-24/box-3.png" />
                            <div className="end-element__copy">
                                    <h3>Neues von Milka</h3>
                                    <p>
                                        Verpasse keine Neuigkeit mehr: ob tolle Aktionen, zarte Produktnews oder Infos zum Milka Engagement.
                                    </p>
                                <a href="https://www.milka.de/neues" target="_blank">
                                    <button className="btn__container btn--secondary-lilac"> NEUES ENTDECKEN </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <Footersocial />
            </div>
        );
    }

}

export default withTranslation()(JerseyConfirmation);