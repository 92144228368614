import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { NavLink, withRouter } from "react-router-dom";
import parse from "html-react-parser";
import GLOBAL_CONFIG from "../../config/config";
import appGoogleTracking from '../../modules/googletracking';
import {
  servicePath,
  mainLoaderToggle,
  timeStamp,
  optionListConstruct,
  dateConfig,
} from "../../modules/helpers";
import TitleContent from "../../components/Content/TitleContent/TitleContent";
import DropdownClub from "../../components/Content/DropdownClub/DropdownClub";
import axios from "axios"; //Axios library to handle AJAX request                                              //Parse HTML data from string
import flatpickr from "flatpickr"; //Date Picker
import ValidateForm from "../../modules/validate"; //Form validation module
import "flatpickr/dist/flatpickr.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import InputPassword from "../InputPassword/InputPassword";
import Footersocial from '../../components/Layout/FooterContent/FooterSocial';
class Register extends Component {
  constructor(props) {
    super(props);

    // this.handleUnload = this.handleUnload.bind(this);       //Binding to check for Abondanment form for Google tracking

    this.state = {
      DateFormat: "d.m.Y",
      birthdayAge: 18, //User need to be at least 18 - use in flapickr
      loaderSubmit: false,
      loaderError: false,
      loaderGeneralError: false,
      promotionCampaign: window.PROMOTION_CAMPAIGN,
      emailTakenError: null,
      message: null,
      profilePicture: "profile_pic.png",
      TeamSelected: "",
      ErrorMessage: "",
      isMobile: false,
    };

    this.resize = this.resize.bind(this);
    this.IsFavouriteTeamValid = this.IsFavouriteTeamValid.bind(this);
    this.callApiRegister = this.callApiRegister.bind(this);
  }

  resize() {
    // set isMobile to true if the device is a mobile and false otherwise
    this.setState({ isMobile: window.innerWidth <= 768 });
  }

  componentDidMount() {
    const { t } = this.props;
    //Remove main loader
    mainLoaderToggle("hide");

    //Assign proper values to Datepicker for validation purposes
    //Initialize Datepicker : flatpickr
    //Active menu participation - mitmachen

    ValidateForm.validateField("frm_registration");
    let dobSelector = document.getElementById("dob");

    // console.log(dobSelector);
    // //Date picker for Date of birthday
    flatpickr(dobSelector, {
      dateFormat: this.state.DateFormat,
      maxDate: this.BirthdayDate(this.state.birthdayAge), //function to calculate date of birth from todays date
      disableMobile: true,
    });
    window.addEventListener("resize", this.resize);
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleUnload);

    //Show mainloader
    mainLoaderToggle("show");
  }

  //BirthdayDate return datetime for birthday date
  //Two way binding for input fields
  onChangeHandler = (event) => {
    let e = event.target,
      val = e.value;

    setTimeout(() => {
      //sumSelector = document.getElementById('js-sum-display');
      //Add sum of text
      /*if(e.classList.contains('valid')){
                sumSelector.innerHTML = Math.ceil(val) + '€';
            } else {
                sumSelector.innerHTML = sumSelector.getAttribute('data-text');
            }*/
    }, 300);
  };

  //BirthdayDate return datetime for birthday date
  BirthdayDate = (age) => {
    let date = new Date(),
      month = date.getMonth() + 1,
      day = date.getDate(),
      year = date.getFullYear() - age;

    return new Date(year, month, day);
  };

  //Close error message popup
  onClosePopupHandler = () => {
    this.setState({
      loaderError: false,
      loaderGeneralError: false,
      emailTakenError: false,
    });
  };

  IsFavouriteTeamValid() {
    const favoriteTeamError = document.getElementById("favorite-team-error");
    if (this.state.TeamSelected == "") {
      favoriteTeamError.style.display = "block";
      favoriteTeamError.classList.add("notvalid");
      return false;
    }
    favoriteTeamError.style.display = "none";
    favoriteTeamError.classList.remove("notvalid");
    return true;
  }

  //Form submit event
  onSubmitHandler = (event) => {
    event.preventDefault();
    let _this = this;
    //Validation of fields
    let validateFormInfo = ValidateForm.validateForm("frm_registration");

    if (validateFormInfo === true) {
      // this.processFormData();
      //Check if file is valid
      console.log("hello");
      if (window.FILE_STATUS === true) {
        console.log("2222");
        this.processFormData();
      } else {
        this.processFormData();
      }
    } else {
      if (document.getElementById("file_to_upload").value == "") {
        document
          .querySelector(".content-upload-receipt")
          .scrollIntoView({ behavior: "smooth", block: "start" });
      } else {
        //Focus the error field
        document.querySelector(".notvalid").focus();
      }
    }
  };
  onChangeFile = () => {
    console.log("image chnge");
    if (document.getElementById("file_to_upload").files[0] != null) {
      console.log("not null");
      document.getElementById("member").setAttribute("data-require", true);
    }
  };

  //Construct server date : YYY-MM-DD
  serverDate = (date) => {
    let setDate = date.split(".");
    return setDate[2] + "-" + setDate[1] + "-" + setDate[0];
  };

  handleBackgroundChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    // alert('ato');
    // console.log(reader.readAsDataURL(file))
    var image = document.getElementById("profileImage");
    image.src = URL.createObjectURL(e.target.files[0]);
    console.log(URL.createObjectURL(e.target.files[0]));
    if (document.getElementById("file_to_upload").files[0] != null) {
      console.log("not null");
      document.getElementById("member").setAttribute("data-require", true);
    }
    // this.setState({
    //     profilePicture:reader.readAsDataURL(file)
    // });
  };

  FavouriteClubSelection(team) {
    this.setState({
      TeamSelected: team,
      ErrorMessage: "",
    });
  }

  TeamSection(props) {
    const imagePath = "/resources/imagesBundesliga/team-picture/2024/";
    let footballClubContent = props.t(
      "Page.Participation.form.footballClub.footballClubListObject",
      { returnObjects: true }
    );
    let swiperSlides = [];
    let maxTeamToShow = this.state.isMobile ? 2 : 8;
    let count = 0;
    let teamsTemp = [];
    for (let i = 0; i < footballClubContent.length; i++) {
      teamsTemp.push(
        <div
          className={`team ${
            this.state.TeamSelected === footballClubContent[i].name
              ? "active"
              : ""
          }`}
          key={"team" + i}
          onClick={() => {
            appGoogleTracking.dataLayerPush({
              'dataEvent': 'team_select',
              'dataCategory': 'Take Part Page',
              'dataAction': 'Click on Team',
              'dataLabel':  footballClubContent[i].name 
            })
            const favoriteTeamError = document.getElementById(
              "favorite-team-error"
            );
            favoriteTeamError.style.display = "none";
            favoriteTeamError.classList.remove("notvalid");
            this.FavouriteClubSelection(footballClubContent[i].name);
          }}
        >
          <div className="team-logo">
            <img
              src={imagePath + footballClubContent[i].name + ".png"}
              alt={footballClubContent[i].name}
            />
          </div>
          <p>{footballClubContent[i].name}</p>
        </div>
      );
      count++;
      if (count === maxTeamToShow || i === footballClubContent.length - 1) {
        const teamsSlide = teamsTemp;
        swiperSlides.push(
          <SwiperSlide
            key={"team-slide-" + footballClubContent[i].name}
            tag="li"
            style={{ listStyle: "none" }}
          >
            <div className="chooseTeam--team-container">{teamsSlide}</div>
          </SwiperSlide>
        );
        teamsTemp = [];
        count = 0;
      }
    }

    return (
      <div className="chooseTeam buzzer-form team-container__form">
        <h2 className="heading">Verrate uns deinen Lieblingsclub*</h2>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          navigation
          pagination
          wrapperTag="ul"
          tag="section"
          onSlideNextTransitionStart={() => {
            appGoogleTracking.dataLayerPush({
              dataEvent: "info-click",
              dataCategory: "Informational Action",
              dataAction: "Carousel Click",
              dataLabel: "Right",
            });
          }}
          onSlidePrevTransitionStart={() => {
            appGoogleTracking.dataLayerPush({
              dataEvent: "info-click",
              dataCategory: "Informational Action",
              dataAction: "Carousel Click",
              dataLabel: "Left",
            });
          }}
        >
          {swiperSlides}
        </Swiper>
        <p id="favorite-team-error">Bitte wähle deinen Lieblingsclub.</p>
      </div>
    );
  }

  //Process form when Captcha is OK
  processFormData = () => {
    console.log("33333");
    //Open loader
    this.setState({
      loaderSubmit: true,
    });

    //Process form data
    let data = {
      Firstname: document.getElementById("firstname").value,
      Lastname: document.getElementById("lastname").value,
      Email: document.getElementById("email").value,
      Street1: document.getElementById("street_name").value,
      Streetnumber: document.getElementById("street_number").value,
      Zipcode: document.getElementById("zip_code").value,
      City: document.getElementById("city").value,
      Street2: document.getElementById("address").value,
      team1: this.state.TeamSelected,
      newsletter: document.getElementById("newsletter").checked ? 1 : 0,
      Password: document.getElementById("passwordRegister").value,
      ConfirmPassword: document.getElementById("confirmPassword").value,
      birthday: this.serverDate(document.getElementById("dob").value),

      // OptinNewsletter:       document.getElementById("newsletter").checked ? true : false,
      // Captcha:               token
    };
    if (
      document.getElementById("passwordRegister").value != document.getElementById("confirmPassword").value
    ) {
      this.setState({
        message: true,
      });
    } else {
      let fileInput = document.getElementById("file_to_upload");
      if (fileInput && fileInput.files.length > 0 ) {
        data.profilePicture = fileInput.files[0];
        let memberField = document.getElementById("member");
          data.member = memberField.checked ? 1 : 0;
      }
      this.callApiRegister(data);
    }
  };

  callApiRegister(data){
    let formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }

    //Get webservice path
    let getParticipationURL = servicePath({
      ...GLOBAL_CONFIG.ServiceSettings,
      status: "register",
    });

    //Ajax call via axios
    axios({
      method: "post",
      url: getParticipationURL,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (response.data.success === true) {
            //Set user participation
            // sessionStorage.setItem(GLOBAL_CONFIG.Session.userstatus, 'participate');
            // let userStatus = response.data.data.IsLogin? 'win' : 'lose';

            let loginstatus;
            //Set user as Winner or Loser
            sessionStorage.setItem(
              GLOBAL_CONFIG.Session.loginstatus,
              loginstatus
            ); //win or lose

            // //SET Participation ID
            // window.PARTICIPATION_ID = response.data.ParticipationId;

            // Datalayer push for Success participation
            // this.triggerDatalayers({
            //     status: 'success',
            //     participationID: response.data.ParticipationId
            // });

            appGoogleTracking.dataLayerPush({
              'dataEvent': 'create_account',
              'dataCategory': 'Take Part Page',
              'dataAction': 'Click on Create Account',
              'dataLabel': 'ENG_TPP_PART'
            })
            //Go to Thank you page
            this.props.history.push({
              pathname: GLOBAL_CONFIG.Route.confirmationAccount,
            });
          } else {
            let errorStatus = response.data.message;

            if (errorStatus === "Email is already in use") {
              console.log("EMAIL ALREADY IN USE");

              this.setState({
                loaderSubmit: false,
                loaderError: true,
                emailTakenError: true,
              });

              // this.triggerDatalayers({
              //     status: 'error',
              //     type: 'participate'
              // });
            } else {
              if (errorStatus === "INVALID_RECAPTCHA_RESPONSE") {
                console.log("Captcha error message");

                //Datalayer push for Error
                // this.triggerDatalayers({
                //     status: 'error',
                //     type: 'general'
                // });
              } else if (errorStatus === "AN_ERROR_HAS_OCCURED") {
                console.log("An error has occured");
              }

              this.setState({
                loaderSubmit: false,
                loaderGeneralError: true,
              });
            }

            // this.recaptcha.reset();     //Reset captcha when error occurs - deploy
          }
        } else {
          //Show error messages
          console.log("Error 404 or 500");

          //Close Loader
          this.setState({
            loaderSubmit: false,
            loaderGeneralError: true,
          });

          // this.recaptcha.reset();     //Reset captcha when error occurs
        }
      })
      .catch((response) => {
        // this.props.history.push({pathname: GLOBAL_CONFIG.Route.confirmationAccount});
        //handle error
        console.log(response);

        // //Close Loader
        this.setState({
          loaderSubmit: false,
          loaderGeneralError: true,
        });
        console.log("catch");
        // this.recaptcha.reset();     //Reset captcha when error occurs
      });
  }

  render() {
    //Loader rendering for form submit and error handling
    let submitLoader = null,
      errorLoader = null,
      errorLoaderGeneral = null,
      siteKey = null,
      inputElement = null,
      emailTakenError = null,
      message = null,
      profilePicture = null;
    //paraElement = null;

    const { t } = this.props;
    let footballClubContent = optionListConstruct(
      t("Page.Participation.form.footballClub.footballClubListName", {
        returnObjects: true,
      })
    );
    if (this.state.message === true) {
      message = (
        <div className="form-container">
          <span className="errorMsg-Register">
            Passwörter stimmen nicht überein!
          </span>
        </div>
      );
    }

    if (this.state.loaderGeneralError === true) {
      errorLoaderGeneral = (
        <div className="js-overlay overlay overlay--black">
          <div className="overlay__container overlay__content">
            <div
              className="overlay__close"
              onClick={this.onClosePopupHandler}
            ></div>
            <p id="js-error-msg">
              Ein Fehler ist aufgetreten. Bitte versuchen <br />
              Sie es später noch einmal.
            </p>
          </div>
        </div>
      );
    }
    if (this.state.emailTakenError === true) {
      emailTakenError = (
        <div className="js-overlay overlay overlay--black">
          <div className="overlay__container overlay__content">
            <div
              className="overlay__close"
              onClick={this.onClosePopupHandler}
            ></div>
            <p id="js-error-msg">
              E-Mail wird bereits verwendet. <br />
              Versuche es mit einer anderen E-Mail oder logge dich ein.
            </p>
          </div>
        </div>
      );
    }
    return (
      <div className="register">
        <div className="space-title">
          <TitleContent
            heading="Jetzt deinen FC Milka Account anlegen"
            paragraph="Genieße alle Vorteile des FC Milka Accounts."
          />
        </div>
        <div className="register">
          <div className="s-content-partication">
            <hr className="title-seperator"></hr>
            <form
              /* className="bg-lilac" */ id="frm_registration"
              name="frm_registration"
              method="post"
              action="/"
              onSubmit={this.onSubmitHandler}
              noValidate
            >
              <div className="spaceForm">
                <p className="title-data">Deine Daten</p>
                <div className="form-line row">
                  <div className="form-container grid-6-m grid-m-6-m grid-s-2">
                    <div className="input-container">
                      <div className="form-input__container">
                        <label
                          className="form-input__label"
                          htmlFor="firstname"
                        >
                          Vorname*
                        </label>
                        <input
                          className="form-input__input js-event-type-in js-event-ab"
                          type="text"
                          name="firstname"
                          id="firstname"
                          placeholder="Vorname*"
                          data-require="true"
                          data-type="regex"
                          data-error-target="#error-firstname"
                          data-regex-pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðß ,.'-]+$"
                          data-required-message="Firstname is required."
                          data-pattern-message="Firstname not valid."
                          data-event-fieldname="Firstname"
                          data-event="form-click"
                          data-category="Form Action"
                          data-action="Firstname"
                          data-label="Type In"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-line row">
                  <div className="form-container grid-6-m grid-m-6-m grid-s-2">
                    <div className="input-container">
                      <div className="form-input__container">
                        <label className="form-input__label" htmlFor="lastname">
                          Nachname*
                        </label>
                        <input
                          className="form-input__input js-event-type-in js-event-ab"
                          type="text"
                          name="lastname"
                          id="lastname"
                          placeholder="Nachname*"
                          data-require="true"
                          data-type="regex"
                          data-error-target="#error-lastname"
                          data-regex-pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðß ,.'-]+$"
                          data-required-message="Lastname is required."
                          data-pattern-message="Lastname not valid."
                          data-event-fieldname="Lastname"
                          data-event="form-click"
                          data-category="Form Action"
                          data-action="Lastname"
                          data-label="Type In"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-line row">
                  <div className="form-container grid-6-m grid-m-6-m grid-s-2 js-datepicker-dob">
                    <div className="input-container">
                      <div className="form-input__container active js-datepicker-dob">
                        <label className="form-input__label" htmlFor="dob">
                          Geburtsdatum*
                        </label>
                        <input
                          className="form-input__input js-event-type-in js-event-ab"
                          type="text"
                          name="dob"
                          id="dob"
                          placeholder="TT.MM.JJJJ"
                          autoComplete="off"
                          readOnly
                          data-require="true"
                          data-type="regex"
                          data-error-target="#error-dob"
                          data-regex-pattern="^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$"
                          data-required-message="Birthday is required."
                          data-pattern-message="Birthday is not valid."
                          data-event-fieldname="Birthday"
                          data-event="form-click"
                          data-category="Form Action"
                          data-action="Birthday"
                          data-label="Type In"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-line row">
                  <div className="form-container grid-6-m grid-m-6-m grid-s-2">
                    <div className="input-container">
                      <div className="streetLbl">
                        <div className="form-input__container ">
                          <label
                            className="form-input__label"
                            htmlFor="street_name"
                          >
                            Straße*
                          </label>
                          <input
                            className="form-input__input js-event-type-in js-event-ab"
                            type="text"
                            name="street_name"
                            id="street_name"
                            placeholder="Straße*"
                            data-require="true"
                            data-type="text"
                            data-error-target="#error-street-number"
                            data-required-message="Street number is required."
                            data-event-fieldname="Street number"
                            data-event="form-click"
                            data-category="Form Action"
                            data-action="Street number"
                            data-label="Type In"
                          />
                        </div>
                      </div>
                      <div className="numberLbl">
                        <div className="form-input__container">
                          <label
                            className="form-input__label"
                            htmlFor="street_number"
                          >
                            Hausnummer*
                          </label>
                          <input
                            className="form-input__input js-event-type-in js-event-ab"
                            type="text"
                            name="street_number"
                            id="street_number"
                            placeholder="Hausnummer*"
                            data-require="true"
                            data-type="text"
                            data-error-target="#error-house-number"
                            data-required-message="House number is required."
                            data-event-fieldname="House number"
                            data-event="form-click"
                            data-category="Form Action"
                            data-action="House number"
                            data-label="Type In"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-line row">
                  <div className="form-container grid-6-m grid-m-6-m grid-s-2">
                    <div className="input-container">
                      <div className="form-input__container">
                        <label className="form-input__label" htmlFor="adress">
                          Adresszusatz*
                        </label>
                        <input
                          className="form-input__input js-event-type-in js-event-ab"
                          type="text"
                          name="address"
                          id="address"
                          placeholder="Adresszusatz (optional)"
                          data-require="false"
                          data-type="text"
                          data-error-target="#error-address"
                          data-required-message="Address is required."
                          data-event-fieldname="Address"
                          data-event="form-click"
                          data-category="Form Action"
                          data-action="Address"
                          data-label="Type In"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-line row">
                  <div className="form-container grid-6-m grid-m-6-m grid-s-2">
                    <div className="input-container">
                      <div className="zipLbl">
                        <div className="form-input__container">
                          <label
                            className="form-input__label"
                            htmlFor="zip_code"
                          >
                            PLZ*
                          </label>
                          <input
                            className="form-input__input js-event-type-in js-event-ab js-zip-code"
                            type="text"
                            name="zip_code"
                            id="zip_code"
                            placeholder="PLZ*"
                            maxLength="5"
                            data-require="true"
                            data-type="regex"
                            data-error-target="#error-zip-code"
                            data-regex-pattern="^[0-9]{5}$"
                            data-required-message="Zip code is required."
                            data-pattern-message="Zip code not valid."
                            data-event-fieldname="Zip code"
                            data-event="form-click"
                            data-category="Form Action"
                            data-action="Zip code"
                            data-label="Type In"
                          />
                        </div>
                      </div>
                      <div className="cityLbl">
                        <div className="form-input__container">
                          <label className="form-input__label" htmlFor="city">
                            Stadt*
                          </label>
                          <input
                            className="form-input__input js-event-type-in js-event-ab"
                            type="text"
                            name="city"
                            id="city"
                            placeholder="Stadt*"
                            data-require="true"
                            data-type="text"
                            data-error-target="#error-city"
                            data-required-message="City is required."
                            data-event-fieldname="City"
                            data-event="form-click"
                            data-category="Form Action"
                            data-action="City"
                            data-label="Type In"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-line row">
                  <div className="form-container grid-6-m grid-m-6-m grid-s-2">
                    <div className="input-container space_hr">
                      <div className="form-input__container dropdown-input__container active">
                        <input
                          className="form-input__input"
                          type="text"
                          name="country"
                          id="country"
                          placeholder="Deutschland"
                          value="Deutschland"
                          data-require="false"
                          disabled
                        />
                        <label className="form-input__label" htmlFor="country">
                          Land*
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="form-line row">
                  <div className="form-container grid-6-m grid-m-6-m grid-s-2">
                    <div className="input-container">
                      <div className="form-input__container">
                        <label className="form-input__label" htmlFor="email">
                          {" "}
                          E-Mail-Adresse*
                        </label>
                        <input
                          className="form-input__input js-event-type-in js-event-ab"
                          type="text"
                          name="email"
                          id="email"
                          placeholder="E-Mail-Adresse*"
                          data-require="true"
                          data-type="regex"
                          data-error-target="#error-emailname"
                          data-regex-pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,17}$"
                          data-required-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail."
                          data-pattern-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail."
                          data-event-fieldname="Email"
                          data-event="form-click"
                          data-category="Form Action"
                          data-action="Email"
                          data-label="Type In"
                          data-confirmation-target="#confirmEmail"
                          data-confirmation-main="true"
                          data-confirmation-message="Füllen Sie die E-Mail-Bestätigung aus."
                        />
                        <span
                          id="error-emailname"
                          className="form-input__error active"
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-line row">
                  <div className="form-container grid-6-m grid-m-6-m grid-s-2">
                    <div className="input-container">
                      <div className="form-input__container">
                        <label
                          className="form-input__label"
                          htmlFor="confirmEmail"
                        >
                          {" "}
                          E-Mail-Adresse wiederholen*
                        </label>
                        <input
                          className="form-input__input js-event-type-in js-event-ab"
                          type="text"
                          name="confirmEmail"
                          id="confirmEmail"
                          placeholder="E-Mail wiederholen*"
                          data-require="true"
                          data-type="regex"
                          data-error-target="#error-emailname"
                          data-regex-pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,17}$"
                          data-required-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail."
                          data-pattern-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail."
                          data-confirmation-target="#email"
                          data-confirmation-message="Bestätigen Sie Ihre E-Mail."
                          data-event-fieldname="Email"
                          data-event="form-click"
                          data-category="Form Action"
                          data-action="Email"
                          data-label="Type In"
                        />
                        <span
                          id="error-emailname"
                          className="form-input__error active"
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-line row">
                  <div className="form-container grid-6-m grid-m-6-m grid-s-2">
                    <div className="input-container">
                      <div className="form-input__container half-width">
                        <label
                          className="form-input__label"
                          htmlFor="passwordRegister"
                        >
                          Passwort*
                        </label>
                        <InputPassword
                          className="form-input__input js-event-type-in js-event-ab"
                          name="passwordRegister"
                          id="passwordRegister"
                          placeholder="Passwort (mind. 8 Zeichen)*"
                          data-require="true"
                          data-type="regex"
                          data-error-target="#error-password"
                          data-regex-pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$"
                          data-required-message="Passwort wird benötigt"
                          data-pattern-message="Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben und eine Ziffer enthalten."
                          data-event-fieldname="PasswordRegister"
                          data-event="form-click"
                          data-category="Form Action"
                          data-action="PasswordRegister"
                          data-label="Type In"
                          data-confirmation-target="#confirmPassword"
                          data-confirmation-main="true"
                          data-confirmation-message="Vervollständigen Sie die Passwortbestätigung"
                        />
                        <span
                          id="error-password"
                          className="form-input__error active"
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-line row">
                  <div className="form-container grid-6-m grid-m-6-m grid-s-2">
                    <div className="input-container">
                      <div className="form-input__container half-width">
                        <label
                          className="form-input__label"
                          htmlFor="confirmPassword"
                        >
                          Passwort wiederholen*
                        </label>
                        <InputPassword
                          className="form-input__input js-event-type-in js-event-ab"
                          name="confirmPassword"
                          id="confirmPassword"
                          placeholder="Passwort wiederholen*"
                          data-require="true"
                          data-type="regex"
                          data-error-target="#error-passwordConfirm"
                          data-regex-pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$"
                          data-required-message="Passwort wird benötigt"
                          data-pattern-message="Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben und eine Ziffer enthalten."
                          data-event-fieldname="Password"
                          data-event="form-click"
                          data-category="Form Action"
                          data-action="Password"
                          data-label="Type In"
                          data-confirmation-target="#passwordRegister"
                          data-confirmation-message="Passwort stimmt nicht überein"
                        />
                        <span
                          id="error-passwordConfirm"
                          className="form-input__error active"
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
                {message}
              </div>
              <div className="account spaceTeamButtom">
                <div className="content-upload-receipt">
                  {this.TeamSection(this.props)}
                  {/* <div className="form-container team-selection">
                    <div className="choose_team dropdown_space">
                      <p>
                        <span className="heading-choose_team">
                          *Pflichtfelder
                        </span>
                      </p>
                      <DropdownClub
                        defaultImg={
                          "/resources/imagesBundesliga/team-picture/team-default.png"
                        }
                        divClass={
                          "form-input__container dropdown-input__container form-input__dropdown full-width btn-bottom"
                        }
                        class="team-picture"
                        id="team1"
                        title="Wähle deinen Lieblingsverein*"
                        image="{t('Page.Participation.form.footballClub.images.image2')}"
                        defaultValue={t(
                          "Page.Participation.form.footballClub.footballClubFieldText"
                        )}
                        team="team1"
                        content={parse(footballClubContent)}
                      />
                      <div className="notice-regardlessTeam">
                        <p id="text-underTeam" className="text-regardlessTeam">
                          Die Gewinnvergabe erfolgt unabhängig von deiner
                          Clubauswahl.
                        </p>
                      </div>
                    </div>
                  </div> */}

                  <div className="form-container profile">
                    <div className="choose_team">
                      <p>
                        <span className="heading-choose_team">
                          Lade dein Profilbild hoch
                        </span>
                      </p>
                      <p className="img-type">(jpg oder png, max. 3 MB)</p>
                      <div className="profile_picture">
                        <img
                          id="profileImage"
                          src={
                            "/resources/imagesBundesliga/fcAccount/" +
                            this.state.profilePicture
                          }
                          alt="profile"
                        ></img>
                        {/* {profilePicture} */}
                      </div>
                      <div className="checkbox_register">
                        <div className="form-container">
                          <label
                            className="btn__container  custom-file-btn pictureUpload"
                            htmlFor="file_to_upload"
                          >
                            <span className="desktop-only">Bild hochladen</span>
                            <input
                              type="file"
                              id="file_to_upload"
                              onChange={this.handleBackgroundChange}
                              name="file_to_upload"
                              data-type="file"
                              data-error-target="#error-file"
                              data-oversize-error="Ungültige Dateigröße. Bitte Dateigröße Vorgabe beachten."
                              data-format-error="Ungültiges Dateiformat. Bitte nur gültiges Format hochladen."

                              // data-event=""
                              // data-event=""
                              // data-event=""
                              // data-event=""
                              onClick={(e) => this.googleTag('upload_image', 'Take Part Page', 'Click on upload image','GAME_TPP_FORM')}
                            />
                          </label>
                        </div>
                        <div className="form-container results">
                          <span className="form-input__error js-file-error"></span>
                          <span className="form-input__success js-file-success"></span>
                        </div>
                        <div className="form-container">
                          <div className="input-container zero_width">
                            <label className="custom-inputs" htmlFor="member">
                              <input
                                type="checkbox"
                                id="member"
                                name="member"
                                className="js-event-type-in js-event-ab"
                                data-require="false"
                                data-type="checkbox"
                                data-error-target="#error-member"
                                data-required-message="Bitte bestätige deine Rechte an dem hochgeladenen Bild."
                                data-event-fieldname="member"
                                data-event="form-click"
                                data-category="Form Action"
                                data-action="member"
                                data-label="Type In"
                              onChange={() => this.googleTag('checkbox_photo_disclaimer', 'Take Part Page', 'Click on Checkbox', 'GAME_TPP_FORM')}
                              />
                              <span className="text-content style-introvogue">
                                Hiermit erkläre ich in Bezug auf das Bild, dass
                                ich der Fotograf oder Inhaber des
                                vollumfänglichen Nutzungsrechts bin und das Bild
                                im Rahmen dieser Aktion genutzt werden darf.
                              </span>
                              <span
                                id="error-member"
                                className="form-input__error active"
                              ></span>
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="heading__image">
                          <img src="/resources/imagesBundesliga/fcAccount/logos.png"></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="title-seperator"></hr>
              <div className="s-content-partication"></div>
              <div className="s-content-partication s-content-newsletter">
                <div className="form-container">
                  <div className="input-container_newsletter">
                    <label className="custom-inputs" htmlFor="declaration">
                      <span className="text-content">
                        Hiermit erkläre ich die{" "}
                        <a
                          href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => this.googleTag('go_to_privacy_policy', 'Take Part Page', 'Click in Privacy Policy','ENG_TPP_FORM')}
                        >
                          Datenschutzerklärung
                        </a>{" "}
                        gelesen zu haben und diese zu akzeptieren.*
                      </span>
                      <input
                        type="checkbox"
                        id="declaration"
                        name="declaration"
                        className="js-event-type-in js-event-ab"
                        data-require="true"
                        data-event-fieldname="Declaration"
                        data-event="form-click"
                        data-type="checkbox"
                        data-error-target="#error-terms"
                        data-required-message="Bitte akzeptiere die Teilnahmebedingungen, um fortzufahren."
                        data-category="Form Action"
                        data-action="Declaration"
                        data-label="Type In"
                        onChange={() => this.googleTag('data_protection', 'Take Part Page', 'Click on Data Protection', 'ENG_TPP_PART')}
                      />
                      <span className="checkmark"></span>
                      <span
                        id="error-terms"
                        className="error-declaration form-input__error active"
                      ></span>
                    </label>
                  </div>
                </div>
                <div className="form-container">
                  <div className="input-container_newsletter">
                    <label className="custom-inputs" htmlFor="newsletter">
                      <span className="text-content">
                      Ich möchte den regelmäßigen Newsletter mit zukünftigen Neuigkeiten, Gewinnspielen und Angeboten von Milka per E-Mail erhalten. Damit die Kommunikation noch besser auf meine Bedürfnisse abgestimmt werden kann, bin ich neben der Anmeldung für den Newsletter auch damit einverstanden, dass meine Daten inklusive meiner verschlüsselten E-Mail-Adresse für Folgendes verwendet werden: Bildung von Profilen, Analyse-Zwecke, Verknüpfung und Anreicherung mit Browserdaten sowie auf mich abgestimmte Werbung. Zu diesen Zwecken dürfen meine Daten von Milka auch an weitere Tochtermarken von Mondelez International (u.a. Oreo, Philadelphia, TUC, Miracel Whip u.a.), Partner, Dienstleister oder Plattformen Dritter weitergegeben werden, welche die Daten ausschließlich für Mondelez International und nach deren Weisung verarbeiten.<br/><br/>
                      Wir schätzen Dein Vertrauen, wenn Du Deine persönlichen Daten mit uns teilst. Wir behandeln Deine Daten stets fair und respektvoll, beschränkt auf den oben genannten Zweck. Wenn Du mehr darüber erfahren möchten, wie wir mit Deinen Daten umgehen, lies bitte unsere <a
                          href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >Datenschutzerklärung</a>.
                      </span>
                      <input
                        type="checkbox"
                        id="newsletter"
                        name="newsletter"
                        className="js-event-type-in js-event-ab"
                        data-require="false"
                        data-event-fieldname="Newsletter"
                        data-event="form-click"
                        data-category="Form Action"
                        data-action="Newsletter"
                        data-label="Type In"
                        onChange={() => this.googleTag('checkbox_subscribe_newsletter', 'Take Part Page', 'Click on Checkbox', 'ENG_TPP_PART')}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                {/* <div className="form-container centered">
                                <ReCaptcha
                                    ref={ref => this.recaptcha = ref}
                                    hl={GLOBAL_CONFIG.Captcha.language}
                                    badge="inline"
                                    sitekey={siteKey}
                                    onResolved={this.onResolved}
                                />
                            </div> */}
                {/* <hr className="black-border"></hr> */}
                <div className="form-button">
                  <button className="btn__container btn--red" type="submit" >
                    ACCOUNT ERSTELLEN
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {submitLoader}
        {errorLoaderGeneral}
        {emailTakenError}
        <Footersocial />
      </div>
    );
  }
  googleTag = (event, category, action, label) => {
    appGoogleTracking.dataLayerPush({
      'dataEvent': event,
      'dataCategory': category,
      'dataAction': action,
      'dataLabel': label
    })
  }


}
export default withTranslation()(withRouter(Register));
