import React from 'react';
import { NavLink } from 'react-router-dom';
import GLOBAL_CONFIG from '../../config/config';
import appGoogleTracking from '../../modules/googletracking';
import { isBuzzerPromotion, isTrikotPromotion, isDoppeltPromotion, isTicketsPromotion, isq2Promotion, isReihePromotion } from '../../modules/helpers';
import ValidateForm from '../../modules/validate';
import AccountServices from '../../Service/Account';
import AccountModels from '../../Models/Account';

class LoginForm extends React.Component {
    constructor() {
        super();

        this.state = {
            ErrorMessage: ""
        };
        this.isBuzzerPromotion = isBuzzerPromotion();
        this.isTicketsPromotion = isTicketsPromotion();
        this.isDoppeltPromotion = isDoppeltPromotion();
        this.isQ2Promotion= isq2Promotion();
    }

    tagHandler() {
        if (this.isDoppeltPromotion) {
            appGoogleTracking.dataLayerPush({
                'dataEvent': 'forgot_password',
                'dataCategory': 'Take Part Page',
                'dataAction': 'Click on Forgot Password',
                'dataLabel': 'LOGIN_TPP_PART'
            })
        }
        if (this.isTicketsPromotion) {
            appGoogleTracking.dataLayerPush({
                'dataEvent': 'forgot_password',
                'dataCategory': 'Take Part Page',
                'dataAction': 'Click on Forgot Password',
                'dataLabel': 'LOGIN_TPP_PART'
            })
        }
        if (this.isQ2Promotion) {
            appGoogleTracking.dataLayerPush({
                'dataEvent': 'forgot_password',
                'dataCategory': 'Participation Page',
                'dataAction': 'Click on Forgot Password',
                'dataLabel': 'LOGIN_PART_PART'
            })
        }

        if (isReihePromotion()) {
            appGoogleTracking.dataLayerPush({
                'dataEvent': 'forgot_password',
                'dataCategory': 'Take Part Page',
                'dataAction': 'Click on Forgot Password',
                'dataLabel': 'LOGIN_TPP_PART'
            })
        }
    }

    render() {
        return (
            <form id="frm_login"
                ref={form => this._loginForm = form}
                name="frm_login"
                method="post"
                action="/"
                onSubmit={this.loginHandler.bind(this)}
                noValidate>
                <div className="form-container">
                    <h2>Du bist FC Milka Mitglied?</h2>
                    <h3>{this.props.subtitle ? `${this.props.subtitle}`  : `Dann einfach hier einloggen` }</h3>
                    <div className="input-container">
                        <div className="form-input__container half-width">
                            <label className="form-input__label" htmlFor="emailLogin"> E-Mail-Adresse*</label>
                            <input className="form-input__input js-event-type-in js-event-ab"
                                ref={input => this._username = input}
                                type="email"
                                name="emailLogin"
                                id="emailLogin"
                                placeholder="E-Mail-Adresse"
                                data-require="true"
                                data-type="regex"
                                data-error-target="#error-emailname"
                                data-regex-pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,17}$"
                                data-required-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail."
                                data-pattern-message="Huch – da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail."
                                data-event-fieldname="Email"
                                data-event="form-click"
                                data-category="Form Action"
                                data-action="Email"
                                data-label="Type In"
                                onChange={this.RemoveInvalid.bind(this)}
                            />
                            <span id="error-emailname" className="form-input__error active"></span>
                        </div>
                    </div>
                    <div className="input-container">
                        <div className="form-input__container half-width" >
                            <label className="form-input__label" htmlFor="password">Passwort*</label>
                            <input className="form-input__input js-event-type-in js-event-ab"
                                ref={input => this._password = input}
                                type="password"
                                name="password"
                                id="password"
                                placeholder="Passwort"
                                data-require="true"
                                data-type="regex"
                                data-error-target="#error-passwordLogin"
                                data-regex-pattern=""
                                data-required-message="Passwort erforderlich"
                                data-pattern-message=""
                                data-event-fieldname="PasswordRegister"
                                data-event="form-click"
                                data-category="Form Action"
                                data-action="PasswordRegister"
                                data-label="Type In"
                                onChange={this.RemoveInvalid.bind(this)}
                            />
                            <span id="error-passwordLogin" className="form-input__error active"></span>
                        </div>
                    </div>

                    <div className="link-container">
                        <NavLink to={GLOBAL_CONFIG.Route.passwordChangeEmail} exact
                            onClick={
                                () => this.tagHandler()
                            }
                        >Passwort vergessen</NavLink>
                    </div>

                    {
                        this.state.ErrorMessage.length > 0 ?
                            <p className="buzzer-error">{this.state.ErrorMessage}</p>
                            : null
                    }

                    <button className="btn__container btn--secondary-lilac" type="submit" /* data-event="custom_event_click" data-category="click_action" data-action="login" data-label="login_take_part" onClick={appGoogleTracking.processEventCTANavLink} */>
                        EINLOGGEN
                    </button>
                    {/*loginError*/}
                </div>

            </form>
        )
    }

    loginHandler(event) {
        event.preventDefault();
        const { SuccessHandler, ErrorHandler, ServiceFailedHandler, PreRequest } = this.props;
        const pswd = this._password.value,
            usrnm = this._username.value,
            validationResp = ValidateForm.validateForm('frm_login');

        if (validationResp
            && pswd.length > 0
            && usrnm.length > 0) {
            PreRequest();
            AccountServices.Login({
                Username: usrnm,
                Password: pswd
            })
                .then(response => {
                    let { success, token, favclub, message } = response.data;

                    if (success) {
                        // Keep LoginToken in STORAGE
                        sessionStorage.setItem(GLOBAL_CONFIG.Session.tokenstatus, token);
                        document.cookie = GLOBAL_CONFIG.Session.tokenstatus + '=' + token + ';domain=' + GLOBAL_CONFIG.DomainName;

                        let consumerID = token.substring(0, 6);
                        if(this.isTrikotPromotion) {
                            appGoogleTracking.dataLayerPush({
                                'dataEvent': 'trikot_login',
                                'dataCategory': 'Take Part Page',
                                'dataAction': 'Click on Login',
                                'dataLabel': 'LOGIN_TPP_PART'
                            });
                        } if(this.isQ2Promotion){
                            appGoogleTracking.dataLayerPush({
                                'dataEvent': 'user_login',
                                'dataCategory': 'Participation Page',
                                'dataAction': 'Click on Login',
                                'dataLabel': 'LOGIN_PART_PART'
                            });
                        } else {
                            appGoogleTracking.dataLayerPush({
                                'dataEvent': 'user_login',
                                'dataCategory': 'Take Part Page',
                                'dataAction': 'Click on Login',
                                'dataLabel': 'LOGIN_TPP_PART'
                            });
                        }
                        

                        /**
                         * Cann Success Handler found in participation
                         */
                        SuccessHandler({
                            LoginToken: token,
                            FavouriteClub: favclub
                        })
                    } else {
                        var _message = ErrorHandler(message, {
                            emailRef: this._username,
                            passRef: this._password
                        });
                        switch (_message) {
                            case AccountModels.ErrorStatus._INVALID_DATA:
                                _message = "Benutzername oder Passwort ungültig"
                                this.setState({
                                    ErrorMessage: _message
                                })
                                break;
                            default:
                                this.setState({
                                    ErrorMessage: _message
                                })
                                break;
                        }


                        if (typeof ServiceFailedHandler === "function") {
                            ServiceFailedHandler();
                        }
                    }
                })
                .catch(error => {
                    console.error("Error Occured")
                })
        }
    }

    RemoveInvalid(event) {
        const currentDom = event.target;

        currentDom.classList.remove("notvalid")
    }
}

export default LoginForm;