import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import GLOBAL_CONFIG from "../../../config/config";
import appGoogleTracking from "../../../modules/googletracking";
import { mainLoaderToggle, timeStamp } from "../../../modules/helpers";
import Footersocial from "../../../components/Layout/FooterContent/FooterSocial";
import SliderOverview from "../../../components/Content/SliderOverview/SliderOverview";
class BuzzerIntroPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isProdWelmeister: false,
      isProd: false,
      baseNamePath: null,
      topPosition: 407,
      prevScreenWidth: window.innerWidth
    }

    this.topSection = this.topSection.bind(this);
    /* this.handleResize = this.handleResize.bind(this); */
  }

  componentDidMount() {
    mainLoaderToggle("hide");
    let hostName = window.location.hostname,
      isProdWelmeister = hostName.toLowerCase().indexOf("weltmeister.fcmilka") > -1 ? true : false,
      isProd = hostName.toLowerCase().indexOf("fcmilka") > -1 ? true : false,
      baseNamePath = "";

    if (isProdWelmeister)
    {
      baseNamePath = "";
    } else if (isProd)
    {
      baseNamePath = "https://weltmeister.fcmilka.de";
    } else
    {
      baseNamePath = "/weltmeister";
    }

    this.setState({
      isProdWelmeister: isProdWelmeister,
      isProd: isProd,
      baseNamePath: baseNamePath
    });
    /* window.addEventListener('resize', this.handleResize); */
  }

  componentWillUnmount() {
    mainLoaderToggle("show");
    /* window.removeEventListener('resize', this.handleResize); */
  }

  /*   handleResize() {
      const { topPosition, prevScreenWidth} = this.state;
      const windowWidth = window.innerWidth;
      let newTop = topPosition;
  
      if (windowWidth > 1005) {
        if (windowWidth > prevScreenWidth) {
          newTop += 2; // Increase by 2px if width increases
        } else if (windowWidth < prevScreenWidth) {
          newTop -= 2; // Decrease by 2px if width decreases
        }
    
        this.setState({
          topPosition: newTop,
          prevScreenWidth: windowWidth
        });
      }
    } */

  render() {
    return (
      <div className="all-action-page">
        {this.promoSection(this.props)}
        {this.midSection(this.props)}
        {this.footballSection()}
        {this.footerSection(this.props)}
        <Footersocial />
      </div>
    );
  }

  getTicketsDomain() {
    let hostName = window.location.hostname,
      isProd = hostName.toLowerCase().indexOf("fcmilka") > -1 ? true : false;
    const baseDomaine = isProd ? "https://tickets.fcmilka.de" : "/tickets";
    return baseDomaine;
  }

  getWeltmeisterDomain() {
    let hostName = window.location.hostname,
      isProd = hostName.toLowerCase().indexOf("fcmilka") > -1 ? true : false;
    const baseDomaine = isProd ? "https://weltmeister.fcmilka.de" : "/weltmeister";
    return baseDomaine;
  }

  getAnnouncementDomain() {
    let hostName = window.location.hostname,
      isProd = hostName.toLowerCase().indexOf("fcmilka") > -1 ? true : false;
    const baseDomaine = isProd ? "https://buzzer.fcmilka.de/" : "/buzzer";
    return baseDomaine;
  }

  get90minDomain() {
    let hostName = window.location.hostname,
      isProd = hostName.toLowerCase().indexOf("fcmilka") > -1 ? true : false;
    const baseDomaine = isProd ? "https://90min.fcmilka.de" : "/90min";
    return baseDomaine;
  }

  promoSection(props) {
    /*     const { topPosition } = this.state;
        let style = {
          top: window.innerWidth < 481 ? '65vh' : `${topPosition}px`
        }; */

    return (
      <>
        {/*         <div className="campaign-heading">
          <div className="title-container">
            <h2>
              Unsere aktuelle Aktion für dich
            </h2>
          </div>
        </div> */}
        <a href="https://www.milka.de/newsletter" target="_blank"
          data-event="subscribe_newsletter"
          data-category="Overview Page"
          data-action="Click on Newsletter Bar"
          data-label="ENG_OP_OTHER"
          onClick={appGoogleTracking.processEventCTANavLink}
        >
          <div id="news-sticky" class="newsletter-sticky">

            <img
              className="newsletter-img"
              src="/resources/imagesBundesliga/newsletter/Icon-Newsletter.png"
              alt="Milka logo"
            />
            <h2>
              Jetzt Newsletter abonnieren
            </h2>

          </div>
        </a>
        <div class="promo-content">
          {/* <div className="promo__wrapper">
            <div class="promo-title">
              <h2>
                Unsere aktuelle Aktion
              </h2>
            </div>
            <div className="promo__container">
              <div className="promo__picture">
                <picture>
                  <source
                    srcSet="/resources/imagesBundesliga/90_min/all-action/tickets-teaser-2.png"
                    media="(min-width: 481px)"
                  />
                  <source
                    srcSet="/resources/imagesBundesliga/90_min/all-action/tickets-teaser-2.png"
                    media="(min-width: 200px)"
                  />
                  <img
                    className="member__image"
                    src="/resources/imagesBundesliga/90_min/all-action/tickets-teaser-2.png"
                    alt="teaser"
                  />
                </picture>
              </div>
              <div className="win-price__content">
                <button className="btn__container btn--secondary-dark">

                  <a
                    href="http://sommer.fcmilka.de/"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-event="join_now"
                    data-category="Overview Page"
                    data-action="Click on Join Now"
                    data-label="GAME_OP_PART"
                    onClick={appGoogleTracking.processEventCTA}
                  >Jetzt mitmachen</a>
                </button>
              </div>
            </div>
          </div> */}

          <div className="promo__wrapper">
            <div class="promo-title">          
              <h2>
                Unsere aktuelle aktion
              </h2>
            </div>
            <div className="promo__container">
              <div className="promo__picture">
                <picture>
                  <source
                    srcSet="/resources/imagesBundesliga/90_min/all-action/tickets-teaser-reihe.png"
                    media="(min-width: 800px)"
                  />
                  <source
                    srcSet="/resources/imagesBundesliga/90_min/all-action/tickets-teaser-reihe.png"
                    media="(min-width: 481px)"
                  />
                  <source
                    srcSet="/resources/imagesBundesliga/90_min/all-action/tickets-teaser-reihe.png"
                    media="(min-width: 200px)"
                  />
                  <img
                    className="member__image"
                    src="/resources/imagesBundesliga/90_min/all-action/tickets-teaser-reihe.png"
                    alt="Milka logo"
                  />
                </picture>
              </div>
              <div className="win-price__content">
                {/* <h1 className="teaser-text" style={{paddingTop:'30px'}}>AKTION STARTET AM</h1>
                <h1 className="teaser-date">29.07.2024</h1> */}
                <button className="btn__container btn--secondary-dark" style={{marginBottom:'40px'}}>

                  <a
                    href="http://reihe1.fcmilka.de/"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-event="join_now"
                    data-category="Overview Page"
                    data-action="Click on Join Now"
                    data-label="GAME_OP_PART"
                    onClick={appGoogleTracking.processEventCTA}
                  >jetzt teilnehmen</a>
                </button>
              </div>
            </div>
          </div>

          
        </div>

      </>
    )
  }

  topSection(props) {
    return (
      <div className="win-price__wrapper">
        <div className="win-price__container">
          <div className="win-price__picture">
            <picture>
              <source
                srcSet="/resources/imagesBundesliga/90_min/all-action/weltmeister-promo.png"
                media="(min-width: 800px)"
              />
              <source
                srcSet="/resources/imagesBundesliga/90_min/all-action/weltmeister-promo-sm.png"
                media="(min-width: 481px)"
              />
              <source
                srcSet="/resources/imagesBundesliga/90_min/all-action/weltmeister-promo-sm.png"
                media="(min-width: 200px)"
              />
              <img
                className="member__image member__image-secondary"
                src="/resources/imagesBundesliga/90_min/all-action/weltmeister-promo.png"
                alt="Milka logo"
              />
            </picture>
          </div>
          <div className="win-price__content">
            <h2>Du & der Weltmeister</h2>
            <p>Gewinne ein Treffen mit deinem Weltmeister: Lukas Podolski oder Rudi Völler? Du entscheidest!</p>
            {
              this.state.isProdWelmeister ?
                <NavLink
                  to={GLOBAL_CONFIG.Route.participation}
                  exact
                  data-event="button-click"
                  data-category="Click Action"
                  data-action="Participate"
                  onClick={appGoogleTracking.processEventCTANavLink}
                >
                  <button className="btn__container btn--secondary-dark">
                    JETZT MITMACHEN
                  </button>
                </NavLink>
                :
                <a
                  href={this.state.baseNamePath}
                  data-event="button-click"
                  data-category="Click Action"
                  data-action="Participate"
                  onClick={appGoogleTracking.processEventCTANavLink}
                >
                  <button className="btn__container btn--secondary-dark">
                    JETZT MITMACHEN
                  </button>
                </a>
            }

          </div>
        </div>
      </div>
    );
  }

  footballSection = () => {
    return (
      <div className="football-section">
        <div className="football-section__card">
          <div className="football-section__card__text">
            <div className="football-section__card__text__wrapper">
              <h3 className="football-section__card__text__title">
                Lande Deinen Volltreffer
              </h3>
              <p className="football-section__card__text__description">
                Stelle deine Torgefahr beim 11-Meter-Schießen unter Beweis! Du hast 5 Chancen - wie viele Bäller versenkst du?
              </p>
              <a href="https://fcmilka.de/game/" target="_blank" rel="noopener noreferrer">
                <button className="btn__container btn--secondary-dark btn--hover">
                  JETZT SPIELEN
                </button>
              </a>
            </div>
          </div>
          <div className="football-section__card__image">
            <picture>
              <source
                srcSet="/resources/imagesBundesliga/intropage/football-section-image.png"
                media="(min-width: 992px)"
              />
              <img src="/resources/imagesBundesliga/intropage/football-section-image-sm.png" alt="Die Milka Fußballedition" />
            </picture>
          </div>
        </div>
      </div>
    );
  };

  midSection(props) {
    return (
      <div className="milka-bg milka-member">
        <div className="member-item__wrapper">
          <div className="member__item">
            <picture>
              <source
                srcSet="/resources/imagesBundesliga/intropage/FCMilka-Logo-2.png"
                media="(min-width: 800px)"
              />
              <source
                srcSet="/resources/imagesBundesliga/intropage/FCMilka-Logo-sm.png"
                media="(min-width: 481px)"
              />
              <source
                srcSet="/resources/imagesBundesliga/intropage/FCMilka-Logo-sm.png"
                media="(min-width: 200px)"
              />
              <img
                className="member__image"
                src="/resources/imagesBundesliga/intropage/FCMilka-Logo-2.png"
                alt="Milka logo"
              />
            </picture>
            <h2>
              Sei dabei – werde FC
              <br />
              Milka Mitglied
            </h2>
            <p>
              Sei dabei – werde FC Milka Mitglied
            </p>
            <NavLink
              to={GLOBAL_CONFIG.Route.account}
              exact
              data-event="register_now"
              data-category="Overview Page"
              data-action="Click on Register Now"
              data-label="ACC_OP_OTHER"
              onClick={appGoogleTracking.processEventCTANavLink}
            >
              <button className="btn__container btn--secondary-dark btn--hover">
                JETZT REGISTRIEREN
              </button>
            </NavLink>
          </div>
        </div>

        <div className="seperator"></div>
        <div className="member-item__wrapper">
          <div className="member__item">
            <picture>
              <source
                srcSet="/resources/imagesBundesliga/90_min/all-action/fancenter-price-4.png"
                media="(min-width: 800px)"
              />
              <source
                srcSet="/resources/imagesBundesliga/90_min/all-action/fancenter-price-4.png"
                media="(min-width: 481px)"
              />
              <source
                srcSet="/resources/imagesBundesliga/90_min/all-action/fancenter-price-4.png"
                media="(min-width: 200px)"
              />
              <img
                className="member__image member__image-secondary"
                src="/resources/imagesBundesliga/90_min/all-action/fancenter-price-4.png"
                alt="Milka logo"
              />
            </picture>
            <h2>Fan-center</h2>
            <NavLink
              to={GLOBAL_CONFIG.Route.fanCenter.main}
              exact
              data-event="go_to_fan_center"
              data-category="Overview Page"
              data-action="Click on Fan Center"
              data-label="GAME_OP_PART"
              onClick={appGoogleTracking.processEventCTANavLink}
            >
              <button className="btn__container btn--secondary-dark btn--hover">
                Hol dir deinen Gewinn
              </button>
            </NavLink>
          </div>
        </div>
      </div>
    );
  }

  footerSection(props) {
    return (
      <>
        <div className="overview-fairplay-banner">
          <div className="image__container">
            <NavLink
              to={GLOBAL_CONFIG.Route.fairplay}
              exact
              className="participate-section"
              data-event="go_to_fair_play"
              data-category="Overview Page"
              data-action="Click on Image"
              data-label='OTHER_OP_OTHER'
              onClick={appGoogleTracking.processEventCTANavLink}
            >
              <div className="image__img">
                <picture>
                  <source
                    srcSet="/resources/imagesBundesliga/90_min/all-action-4.png"
                    media="(min-width: 1024px)"
                  />
                  <source
                    srcSet="/resources/imagesBundesliga/90_min/all-action-4.png"
                    media="(min-width: 481px)"
                  />
                  <source
                    srcSet="/resources/imagesBundesliga/90_min/all-action-4.png"
                    media="(min-width: 200px)"
                  />
                  <img
                    className="banner__image"
                    src="/resources/imagesBundesliga/90_min/all-action-4.png"
                    alt="Milka Christmas"
                  />
                </picture>
              </div>
            </NavLink>
          </div>
          <button className="btn__container btn--secondary-dark">
            <NavLink
              to={GLOBAL_CONFIG.Route.fairplay}
              exact
              data-event="button-click"
              data-category="Click Action"
              data-action="Participate"
              onClick={appGoogleTracking.processEventCTANavLink}
            >MEHR ERFAHREN</NavLink>
          </button>
        </div>
        <SliderOverview />
      </>
    );
  }
}
export default withTranslation()(BuzzerIntroPage);
