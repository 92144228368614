import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import flatpickr from "flatpickr";
import { withRouter } from 'react-router-dom';

import TitleLogo from "../../../../components/Content/TitleLogo/TitleLogo";
import Loader from "../../../../components/Overlays/Loader";
import GLOBAL_CONFIG from "../../../../config/config";
import Salutations from "../../../../Models/Salutation";
import { mainLoaderToggle, ServerDate } from "../../../../modules/helpers";
import ValidateForm from "../../../../modules/validate";
import JerseyNavigation from "../JerseyNavigation";
import JerseyStepIndicitor from "../StepIndicator";
import Footersocial from '../../../../components/Layout/FooterContent/FooterSocial';
import _SCROLL_PAGE from "../../../../modules/ScrollPage";
import Checkbox from "../../../../components/Controls/Checkbox";
import _GIFT from "../../../../Models/GiftTypes";
import _LOCAL from "../../../../Models/LocalStorageKeys";
import FanCenterServices from "../../../../Service/FanCenter";
import appGoogleTracking from "../../../../modules/googletracking";
import _ERROR_MESSAGES from "../../../../Models/ErrorMessages";
import CardValidation from "../../../../components/Content/CardValidation/CardValidation";
import FanCenterSCBanner from "../../Banner/FanCenterSCBanner";
import _TICKET_TYPES from "../../../../Models/TicketTypes";
import Button from "../../../../components/Content/Button/Button";


class JerseyUserDetails extends React.Component {
    constructor() {
        super();

        this.StepList = [
            {
                display: "lieblingsclub",
                isInProgress: false,
                isComplete: true,
            },
            {
                display: "trikotauswahl",
                isInProgress: false,
                isComplete: true,
            },
            {
                display: "daten-check",
                isInProgress: true,
                isComplete: false,
            }
        ];
        var limit = new Date();
        // limit.setFullYear(limit.getFullYear() - 18);
        this.maxDOB = limit;

        this.state = {
            SimpleLoader: false,
            ErrorMessage: "",
            UserDetails: {},
            IsUserDataAlreadyReady : false,
            ProfileUser : null,
              TypeTicket: ""
        }

        this.ProcessError = this.ProcessError.bind(this);
        this.ProcessForm = this.ProcessForm.bind(this);   
    }

    getProfilUser(profileList){
        const user = {};
            profileList.map((item) => {
                user[item.name] = item.value;
            });
        return user;
    }

    switchSalutation(salutation){
        let slt = "";
        if (salutation === 1) {
            slt = "Herr"
        }else if (salutation === 2) {
            slt = "Frau"
        }else if (salutation === 10) {
            slt = "Divers"
        }else {
            slt = "default"
        }
        return slt;
    }

    CheckboxTag = (ref, dataLayerOption,dataLayerOptionSigned) => {
        if (ref.checked) {
            if(localStorage.getItem(_LOCAL.TicketType) === _TICKET_TYPES.TRICKOTUNISIGNED) {
                appGoogleTracking.dataLayerPush(dataLayerOption);
            } else {
                appGoogleTracking.dataLayerPush(dataLayerOptionSigned);
            }
        }
    } 

    TrikotButtonTag = (dataLayerOption, dataLayerOptionSigned) => {
        if(localStorage.getItem(_LOCAL.TicketType) === _TICKET_TYPES.TRICKOTUNISIGNED) {
            appGoogleTracking.dataLayerPush(dataLayerOption);
        }
        else {
            appGoogleTracking.dataLayerPush(dataLayerOptionSigned);
        }
    }

    componentDidMount() {
         if(localStorage.getItem(_LOCAL.UserDetails) === null) {
            // this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.codeEntry.user)
        }
        mainLoaderToggle('hide');
        
        let userDetails = JSON.parse(localStorage.getItem(_LOCAL.UserDetails));

        if(!!userDetails) {
            this.setState({
                UserDetails: userDetails
            });
            if(userDetails.ProfileUser){
                this.setState({
                    ProfileUser : this.getProfilUser(userDetails.ProfileUser),
                    IsUserDataAlreadyReady : true
                });
            }
        }   else {
            userDetails = {};
        }

        _SCROLL_PAGE.ScrollToTop();
        // this.FlatpikerDateInit(this._DETAILS_dob, {max: this.maxDOB});
    }


    componentWillUnmount() {
           this.setState({TypeTicket: localStorage.getItem(_LOCAL.TicketType) || _TICKET_TYPES.CAT2});
        mainLoaderToggle('show');
    }

    render() {
        return(
            <div>
                 
                    <FanCenterSCBanner category={(this.state.TypeTicket === _TICKET_TYPES.TRICKOTUNISIGNED ? "-daten-check-jersey" : "-daten-check-jersey")}/>
               
                <div className="buzzer-jersey user-details-wrapper">
                    {this.HeadingSection()}
                    
                    <form id="frm_user_details_ticket"
                        ref = { form => this._FORM_ADDRESS = form }
                        name="frm_user_details_ticket" 
                        method="post" 
                        action="/"
                        // onSubmit={this.SubmitHandler.bind(this)}
                         >

                    {/* {this.useDetailsTable()} */}
                    { this.UserDeatailsSection() }

                    <ReCAPTCHA
                        ref={ref => this._CAPTCHA = ref}
                        hl={GLOBAL_CONFIG.Captcha.language}
                        size="invisible"
                        sitekey={GLOBAL_CONFIG.Captcha.publish}
                        onChange={this.OnCaptchaResolved.bind(this)}
                        onExpired={this.OnCaptchaExpired.bind(this)}
                        onErrored={this.OnCaptchaError.bind(this)}
                    />

                    </form>

                    {
                        this.state.SimpleLoader ? <Loader /> : null
                    }

                    <Footersocial />
                </div>
            </div>
        );
    }
    goBack() {
        this.props.history.push(
      GLOBAL_CONFIG.Route.fanCenter.jersey.sizeSelection 
    );
    }

    HeadingSection() {
        return (
            <div className="spaceTile-intropage">
                <div className="campaign-heading">
                    <JerseyStepIndicitor Steps={this.StepList} /> 
                    <div className="contenu" style={{ marginTop: '5%' }}>
                        <div className="zuruck-button" >
                        <Button
                            text={'<  Zurück'}
                                icon={'none'}
                                fill={false}
                            onclick={() => this.goBack()} 
                        />
                        </div>
                      
                        {localStorage.getItem(_LOCAL.TicketType) === _TICKET_TYPES.TRICKOTUNISIGNED ?
                            <h3 className="gobold-bold paragraph-heading">an diese adresse schicken wir <br/>dein original heimtrikot</h3> :
                            <h3 className="gobold-bold paragraph-heading">an diese adresse schicken wir dein <br/>signiertes original heimtrikot</h3>
                        }
                    </div>
                    <p className="lilac good-headline-pro" style={{ width: '100%' }}>
                        Nicht vergessen: Lies dir bitte vorher unsere Datenschutzhinweise aufmerksam durch, damit du weißt, wie wir mit deinen personenbezogenen Daten umgehen.
                    </p>
                </div>
                 
            </div>
        );
    }

    useDetailsTable(){
        return this.state.IsUserDataAlreadyReady && (
            <div className={`user-details-table`}>
                <div className="user-details-table__content">
                    <div className="my-info">
                        <div className="my-info__content">
                            <h4 className="my-info__title">
                                Meine Informationen
                            </h4>
                            <p>
                                {this.state.ProfileUser["Email"]}
                            </p>
                            <p>
                                {this.state.ProfileUser["MobilePrivate"]} {this.state.ProfileUser["MobilePrivate"] && this.state.ProfileUser["PhonePrivate"] && ("/")} {this.state.ProfileUser["PhonePrivate"]}
                            </p>
                        </div>
                    </div>
                    <div className="shipping-address">
                        <div className="shipping-address__content">
                            <h4 className="shipping-address__title">
                                Lieferadresse
                            </h4>
                            <p>
                            {this.state.ProfileUser["Firstname"]} {this.state.ProfileUser["Lastname"]}
                            </p>
                            <p>
                                {this.state.ProfileUser["Street1"]} {this.state.ProfileUser["Streetnumber"]}
                            </p>
                            <p>
                                {this.state.ProfileUser["Zipcode"]} {this.state.ProfileUser["City"]}
                            </p>
                            <p>
                                Deutschland
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    UserDeatailsSection() {
        return(
            <div className="buzzer-form">
                <div className={`form-container trickots`}   id="userDetails">
                    <div id="salutation" className="input-container formulaire">
                        <div className="form-input__container form-input__dropdown">
                            <select ref={ select => this._DETAILS_salutation = select} className="form-input__input js-event-type-in js-event-ab" 
                                type="text" name="salutation" 
                                id="salutation" 
                                placeholder="Arende*" 
                                data-require="true" 
                                data-type="ddl" 
                                data-error-target="#error-salutation" 
                                data-required-message="Salutation is required." 
                                data-event-fieldname="Salutation" 
                                data-event="custom_event_click" 
                                data-category="Form Action" 
                                data-action="Salutation" 
                                data-label={(localStorage.getItem(_LOCAL.TicketType) === _TICKET_TYPES.TRICKOTUNISIGNED ? "salutation_original_heimtrikots" : "salutation_signierte_trikots")}
                                onChange={(event)=>{
                                    if(event.target.value) {
                                        appGoogleTracking.processEventCTANavLink(event)
                                    }
                                }}>
                                {
                                    Object.keys(Salutations.dropdown).map( key => {
                                        var i = 0;
                                        let selected = this.switchSalutation(JSON.parse(localStorage.getItem(_LOCAL.UserDetails))?.salutation) === key ? true : false;
                                        if(selected){
                                            return <option value={ key } key={ `${i++}-${key}`} selected>{ Salutations.dropdown[key] }</option>
                                        }else{
                                            return <option value={ key } key={ `${i++}-${key}`} >{ Salutations.dropdown[key] }</option>
                                        }
                                    })
                                }
                            </select>
                            <label className="form-input__label" htmlFor="salutation">{ Salutations.dropdown["default"] }</label>
                        </div>
                    </div>

                    <div id="firstName" className="input-container formulaire">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="firstname">Vorname*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="text" 
                                name="firstname" 
                                id="firstname" 
                                placeholder="Vorname*" 
                                readOnly="true"
                                data-require="true" 
                                data-type="regex" 
                                data-error-target="#error-firstname" 
                                data-regex-pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðß ,.'-]+$" 
                                data-required-message="Firstname is required." 
                                data-pattern-message="Firstname not valid." 
                                data-event-fieldname="Firstname" 
                                data-event="custom_event_click" 
                                data-category="Form Action" 
                                data-action="first_name" 
                                data-label={(localStorage.getItem(_LOCAL.TicketType) === _TICKET_TYPES.TRICKOTUNISIGNED ? "first_name_original_heimtrikot" : "first_name_signierte_trikots")}
                                value={JSON.parse(localStorage.getItem(_LOCAL.UserDetails))?.firstname}
                                ref={ input => this._DETAILS_firstName = input }
                                onClick={appGoogleTracking.processEventCTANavLink}
                                />
                        </div>
                    </div>

                    <div id="lastName" className="input-container formulaire">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="lastname">Nachname*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="text" 
                                name="lastname" 
                                id="lastname" 
                                placeholder="Nachname*" 
                                readOnly="true"
                                data-require="true" 
                                data-type="regex" 
                                data-error-target="#error-lastname" 
                                data-regex-pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðß ,.'-]+$" 
                                data-required-message="Lastname is required." 
                                data-pattern-message="Lastname not valid." 
                                data-event-fieldname="Lastname" 
                                data-event="custom_event_click" 
                                data-category="form_action" 
                                data-action="last_name"
                                data-label={(localStorage.getItem(_LOCAL.TicketType) === _TICKET_TYPES.TRICKOTUNISIGNED ? "last_name_original_heimtrikots" : "last_name_signierte_trikots")}
                                value={JSON.parse(localStorage.getItem(_LOCAL.UserDetails))?.name}
                                ref={ input => this._DETAILS_lastName = input } 
                                onClick={appGoogleTracking.processEventCTANavLink}
                                />
                        </div>
                    </div>

                    <div id="street" className="input-container formulaire">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="street_name">Straße*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="text" 
                                name="street_name" 
                                id="street_name" 
                                placeholder="Straße*" 
                                data-require="true" 
                                data-type="text" 
                                data-error-target="#error-street-number" 
                                data-required-message="Street number is required." 
                                data-event-fieldname="Street number" 
                                data-event="custom_event_click" 
                                data-category="form_action" 
                                data-action="street" 
                                data-label={(localStorage.getItem(_LOCAL.TicketType) === _TICKET_TYPES.TRICKOTUNISIGNED ? "street_original_heimtrikots" : "street_signierte_trikots")}
                                ref={ input => this._DETAILS_street_name = input } 
                                onClick={appGoogleTracking.processEventCTANavLink}
                                />
                        </div>
                    </div>

                    <div id="houseNumber" className="input-container formulaire">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="street_number">Hausnummer*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="text" 
                                name="street_number" 
                                id="street_number" 
                                placeholder="Hausnummer*" 
                                data-require="true" 
                                data-type="text" 
                                data-error-target="#error-house-number" 
                                data-required-message="House number is required." 
                                data-event-fieldname="House number" 
                                data-event="custom_event_click" 
                                data-category="Form Action" 
                                data-action="house_number"
                                data-label={(localStorage.getItem(_LOCAL.TicketType) === _TICKET_TYPES.TRICKOTUNISIGNED ? "house_number_original_heimtrikots" : "house_number_signierte_trikots")}
                                ref={ input => this._DETAILS_street_number = input } 
                                onClick={appGoogleTracking.processEventCTANavLink}
                                />
                        </div>
                    </div>

                    <div id="address-container" className="input-container formulaire">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="adress">Adresszusatz</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="text" 
                                name="address" 
                                id="address" 
                                placeholder="Adresszusatz (optional)" 
                                data-require="false" 
                                data-event-fieldname="Address" 
                                data-event="custom_event_click" 
                                data-category="form_action" 
                                data-action="Address" 
                                data-label={(localStorage.getItem(_LOCAL.TicketType) === _TICKET_TYPES.TRICKOTUNISIGNED ? "address_original_heimtrikots" : "address_signierte_trikots")}
                                ref={ input => this._DETAILS_address = input }
                                onClick={appGoogleTracking.processEventCTANavLink}
                                />
                        </div>
                    </div>

                    <div id="zipCode" className="input-container formulaire">
                        <div className="form-input__container space-right">
                            <label className="form-input__label" htmlFor="zip_code">PLZ*</label>
                            <input className="form-input__input js-event-type-in js-event-ab js-zip-code" 
                                type="text" 
                                name="zip_code" 
                                id="zip_code" 
                                placeholder="PLZ*" 
                                maxLength="5" 
                                data-require="true" 
                                data-type="regex" 
                                data-error-target="#error-zip-code" 
                                data-regex-pattern="^[0-9]{5}$" 
                                data-required-message="Zip code is required." 
                                data-pattern-message="Zip code not valid." 
                                data-event-fieldname="Zip code" 
                                data-event="custom_event_click" 
                                data-category="form_action" 
                                data-action="postcode" 
                                data-label={(localStorage.getItem(_LOCAL.TicketType) === _TICKET_TYPES.TRICKOTUNISIGNED ? "postcode_original_heimtrikots" : "postcode_signierte_trikots")}
                                ref={ input => this._DETAILS_zipCode = input }
                                onClick={appGoogleTracking.processEventCTANavLink}
                                />
                        </div>
                    </div>

                    <div id="city-container" className="input-container formulaire">
                        <div className="form-input__container">
                            <label className="form-input__label" htmlFor="city">Stadt*</label>
                            <input className="form-input__input js-event-type-in js-event-ab" 
                                type="text" 
                                name="city" 
                                id="city" 
                                placeholder="Stadt*" 
                                data-require="true" 
                                data-type="text" 
                                data-error-target="#error-city" 
                                data-required-message="City is required." 
                                data-event-fieldname="City" 
                                data-event="custom_event_click" 
                                data-category="form_action" 
                                data-action="town"
                                data-label={(localStorage.getItem(_LOCAL.TicketType) === _TICKET_TYPES.TRICKOTUNISIGNED ? "town_original_heimtrikots" : "town_signierte_trikots")}
                                ref={ input => this._DETAILS_city = input }
                                onClick={appGoogleTracking.processEventCTANavLink}
                                />
                        </div>
                    </div>
                    
                    <br/>
                    
                        {localStorage.getItem(_LOCAL.TicketType) === _TICKET_TYPES.TRICKOTUNISIGNED ?
                            <p className="good-headline-pro" style={{ 'white-space':'normal' }}>Diese Daten benötigen wir um dir dein Original Heimtrikot zuschicken zu können.</p> :
                            <p className="good-headline-pro" style={{ 'white-space':'normal'}}>Diese Daten benötigen wir um dir dein signiertes Original Heimtrikot zuschicken zu können.</p>
                        }
                </div>

                <Checkbox id="terms"
                    required={true}
                    errorMessage={_ERROR_MESSAGES.Terms}
                    text='Ich habe die <a class="js-event-cta" href="https://privacy.mondelezinternational.com/eu/de-DE/privacy-notice/" target="_blank" rel="noopener noreferrer">Datenschutzbestimmungen</a> und <a class="js-event-cta" href="https://fcmilka.de/fancenter/teilnahmebedingungen" target="_blank" rel="noopener noreferrer">Teilnahmebedingungen</a> gelesen und akzeptiert.*'
                    onChangeHandler={(event, _ref) => {
                        this.CheckboxChangeHandler(event, _ref);
                        this.CheckboxTag(_ref, {
                            dataEvent: "custom_event_click",
                            dataCategory: "form_action",
                            dataAction: "accept_conditions",
                            dataLabel: "terms_and_conditions_original_heimtrikots",
                        },{
                            dataEvent: "custom_event_click",
                            dataCategory: "form_action",
                            dataAction: "accept_conditions",
                            dataLabel: "terms_and_conditions_signierte_trikots",
                        })
                    }}
                />

                <div className="under-form">
                    <p className="email-label good-headline-pro">Die Bestätigung schicken wir an deine E-Mailadresse</p>
                    <p className="email-value">{JSON.parse(localStorage.getItem(_LOCAL.UserDetails))?.email}</p>
                </div>
                <div className="card__validation display-desktop">
                    <CardValidation buttonText="Jetzt Gewinn einlösen" onClick={this.SubmitHandler.bind(this)}/>
                </div>
                <div className="card__validation display-mobile">
                    <CardValidation buttonText="Gewinn einlösen" onClick={this.SubmitHandler.bind(this)}/>
                </div>      

                {
                    this.state.ErrorMessage.length > 0 ?
                    <p className="buzzer-error">{this.state.ErrorMessage}</p>
                    : null
                }
            </div>
        );
    }

    OnCaptchaResolved (token) {
        this.setState({
            SimpleLoader: true
        });
        this.ProcessForm(token);
    }

    ProcessForm(CaptchaToken) {
        let uid = this.state.UserDetails?.external_guid,
            jerseySelected = JSON.parse(localStorage.getItem(_LOCAL.SelectedTrikot)),
            participant = {
                street: this._DETAILS_street_name.value,
                houseNumber: this._DETAILS_street_number.value,
                zipcode: this._DETAILS_zipCode.value,
                city: this._DETAILS_city.value,
                address2 : !!this._DETAILS_address.value ? this._DETAILS_address.value : "additionalinfo"
            };
            
        const form = document.getElementById("frm_user_details_ticket");
        const termsInput = form.querySelector("#terms");
        const privacyPolicy = ValidateForm.validateProcess(termsInput);

        if(!!this.state.UserDetails.email) {
            let _data = {uid: uid, itemID: jerseySelected.toString(),privacyPolicy: privacyPolicy, participant: participant};

            FanCenterServices.OrderJersey(_data)
             .then((response) => {
                 let {message, details} = response.data;
     
                 if(message === "OK"){
                     localStorage.removeItem(_LOCAL.FavoriteClub);
                     localStorage.removeItem(_LOCAL.jerseySelected);
                     this.props.history.push(GLOBAL_CONFIG.Route.fanCenter.confirmation.jersey);
                 }
                 else{
                     console.log('Error on place order: ', message);
                 }
             })
             .catch(() => {
                 console.log('Error on placeholder');
             }).finally(() => {
                console.log('Completed');
                 
                if(!!this._CAPTCHA) {
                    this._CAPTCHA.reset();
                }

                if(!!this.__FORM_ADDRESS) {
                    this._FORM_ADDRESS.reset();
                }

                this.setState({
                    SimpleLoader: false
                })
             })
        }
    }

    ProcessError(ErrorMessage) {
        if(!!ErrorMessage) {
            this.setState({
                ErrorMessage
            })
        } else {
            this.setState({
                ErrorMessage: "SERVER ERROR"
            })
        }
    }

    OnCaptchaExpired () {
        this._CAPTCHA.reset();
    }

    OnCaptchaError () {
        this._CAPTCHA.reset();
    }

    FlatpikerDateInit( _REF, {max}, _def = null ) {
        flatpickr(_REF, {
            dateFormat: "d.m.Y",
            maxDate: max,
            disableMobile: true,
            defaultDate: _def
        });
    }

    CheckboxChangeHandler(event, _ref) {
        if(_ref.hasAttribute("data-require") && !_ref.checked) {
            ValidateForm.error_message('error', _ref, _ref.getAttribute("data-error-target"), _ref.getAttribute('data-required-message'), _ref.getAttribute("id"));
            _ref.parentNode.classList.add("notvalid")
        }

        if(_ref.checked) {
            _ref.parentNode.classList.remove("notvalid")
        }

        this.setState({
            [_ref.getAttribute("id")]: _ref.checked
        })
    }

    SubmitHandler(event) {
        event.preventDefault();
        
        if(this.state.IsUserDataAlreadyReady){
            console.log('atooo');
            const form = document.getElementById("frm_user_details_ticket");
            const termsInput = form.querySelector("#terms");
            const isValid = ValidateForm.validateProcess(termsInput);
            if(isValid){
                this._CAPTCHA.execute();
                //this.ProcessForm(null);
            }
        } else {
            var isValid = ValidateForm.validateForm('frm_user_details_ticket');
            
            if(isValid ) {
                this._CAPTCHA.execute();
                this.TrikotButtonTag({
                    dataEvent: "custom_event_click",
                    dataCategory: "form_action",
                    dataAction: "form_submission",
                    dataLabel: "form_submission_original_heimtrikots",
                },{
                    dataEvent: "custom_event_click",
                    dataCategory: "form_action",
                    dataAction: "form_submission",
                    dataLabel: "form_submission_signierte_trikots",
                });
            } else {
                /**
                 * Display Error
                 */
            }
        }       
    }
}

export default withRouter(JerseyUserDetails);